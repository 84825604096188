<template>
    <div :style=" this.$store.state.SupportHistorical.searchData && this.$store.state.SupportHistorical.searchData !== [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            :extratable="{id:'result_client_data'}"
            :data="this.$store.state.SupportHistorical.searchData"
            :header="header"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'incidence_view', params: {id:itemProp.id,view:true}}">
                    <GhAction
                        :dataction="{
                            id: 'view_support_pending_requests_groups',
                            text: $t('see'),
                            icon: require('../../../../assets/gh_new_dessign/view.svg')
                        }"
                    />
                </router-link>
            </template>
        </GhTableCommon>
    </div>
</template>
<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from 'fe-gh-action-lib'
    import {mapState} from "vuex";
    export default {
        name: "result_historical",
        computed:{
            ...mapState(['SupportHistorical'])
        },
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                header: [
                  {text: 'identifier', field: 'ticket_reference', contentAlt: 'user', title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 90px; width: 150px;'},
                  {text: 'user', field: 'ticket_created_by', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                  {text: 'company', field: 'company', sorting: true, typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                  {text: 'description', field: 'description', sorting: true,typeSort: 'string', style: 'max-width: 200px;width:36%;'},
                  {text: 'assign_to', field: 'group_name', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                  {text: 'priority', field: 'priority_name', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                  {text: 'fe', field: 'entry_date', sorting: true,typeSort: 'string', style: ' min-width: 40px; max-width: 90px; width:7%;'},
                  {text: 'ua', field: 'updated_date', sorting: true,typeSort: 'string', style: 'min-width: 40px; max-width: 90px; width:7%;'},
                  {text: '', field: 'actions', style: ''}
                ],
            }
        },
    }
</script>
