var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex + _vm.refreshIndex},[(label.show)?_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'contracted_programs_' + label.id,
                title: _vm.$t(label['name']),
                label: ''
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}),_c(_vm.components.popup_assign,{tag:"component",attrs:{"refresher":_vm.refreshAssign}}),_c(_vm.components.popup_weighting,{tag:"component",attrs:{"refresher":_vm.refreshWeighting,"dataWeighting":_vm.dataWeighting,"maxlength":_vm.maxlength,"show":false}}),_c('div',{key:'REFRESH_GETTER_IMPORT' + _vm.refreshImport},[_c('GhDataSelector',{ref:"RefSelectorImport",attrs:{"datablock":{
                id: 'agrupator_import',
                class: 'T15_b',
                subtitle: _vm.$t('select_import') + ':',
                text: _vm.$t('import'),
                label: _vm.$t('import'),
                type: 'radio',
                columns: 3,
                required: false,
                style: 'margin-left:20px; display: none;',
                popupStyle: 'min-width: 900px;width: 55%;'
            },"datainput":{
                id: 'agrupator_import',
                name: 'agrupator[import]'
            },"feed":_vm.getterImportAgrupators,"selected_input":[]},on:{"accept":function($event){return _vm.doAction('import')}}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }