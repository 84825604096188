var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-left":"12px","padding-right":"12px"}},[_c('form',{attrs:{"id":"to_register"}},[_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_company',title: _vm.$t('company'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refCompanyName",attrs:{"datalabel":{
                                text: _vm.$t('company_name') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_name',
                                name: 'register[company][name]',
                                style: 'width: 20%',
                                value: _vm.persistance.company.name,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyName')}}}),_c('div',{staticClass:"no-min-width"},[_c('GhDataSelector',{staticStyle:{"width":"29.65%"},attrs:{"datablock":{
                                    id: 'social_reason_register',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_social_reason') + ':',
                                    text: _vm.$t('social_reason'),
                                    label: _vm.$t('social_reason'),
                                    type: 'radio',
                                    columns: 3,
                                    required: false,
                                    style: 'margin-left:20px;',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                },"datainput":{
                                    id: 'value_social_Reason',
                                    name: 'register[company][social_reason][]'
                                },"feed":_vm.getFeeds.feed_social_reason,"selected_input":[]}})],1),_c('GhInputTextField',{ref:"refCompanyCif",attrs:{"datalabel":{
                                text: _vm.$t('cif') + '*',
                                style: 'text-align: right; margin-left: 20px;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_cif',
                                name: 'register[company][cif]',
                                style: 'width: 100px;',
                                value: _vm.persistance.company.cif,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyCif')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refCompanyFullAddress",attrs:{"datalabel":{
                                text: _vm.$t('full_address'),
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'company_direction',
                                name: 'register[company][direction]',
                                style: 'width: 20%',
                                value: _vm.persistance.company.full_address,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyFullAddress')}}}),_c('GhInputTextField',{ref:"refLocality",attrs:{"datalabel":{
                                text: _vm.$t('locality'),
                                style: 'width: 150px; text-align: right; margin-left: 20px;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'register_company_locality',
                                name: 'register[company][locality]',
                                style: 'width:20%;',
                                value: _vm.persistance.company.locality,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refLocality')}}}),_c('GhInputTextField',{ref:"refCompanyCp",attrs:{"datalabel":{
                                text: _vm.$t('cp'),
                                style:'text-align: right; margin-left: 28px;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'company_cp',
                                name: 'register[company][cp]',
                                style: 'width: 50px;',
                                value: _vm.persistance.company.cp,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyCp')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{staticStyle:{"width":"28.9%"},attrs:{"id":"identifier_country"}},[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                                        id: 'country',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_country') + ':',
                                        text: _vm.$t('country'),
                                        label: _vm.$t('country'),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_country',
                                        name: 'register[company][country][]'
                                    },"feed":_vm.getFeeds.feed_country,"selected_input":[]},on:{"accept":_vm.searchProvince}})],1)]),_c('div',[_c('div',{staticStyle:{"width":"23.2%"},attrs:{"id":"identifier_province"}},[_c('GhDataSelector',{key:'refresh_province_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                                        id: 'province',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_province') + ':',
                                        text: _vm.$t('province'),
                                        label: _vm.$t('province'),
                                        type: 'radio',
                                        columns: 3,
                                        required: false,
                                        style: 'margin-left:20px;',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_province',
                                        name: 'register[company][province][]'
                                    },"feed":_vm.getterProvinceByCountry !== undefined ? _vm.getterProvinceByCountry : [],"selected_input":[]},on:{"accept":_vm.searchLocality}})],1)])]),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refCompanyEmail",attrs:{"datalabel":{
                                text: _vm.$t('email'),
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'company_email',
                                name: 'register[company][email]',
                                style: 'width: 20%',
                                value: _vm.persistance.company.email,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyEmail')}}}),_c('GhInputTextField',{ref:"refCompanyTelephone",attrs:{"datalabel":{
                                text: _vm.$t('telephone'),
                                style: 'width: 150px; text-align: right; margin-left: 20px;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'company_telephone',
                                name: 'register[company][telephone]',
                                style: 'width: 20%;',
                                value: _vm.persistance.company.telephone,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyTelephone')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'activity',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_activity_sector') + ':',
                                text: _vm.$t('activity_sector')+'*',
                                label: _vm.$t('activity_sector'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            },"datainput":{
                                id: 'value_activity',
                                name: 'register[company][activity][]'
                            },"feed":_vm.getFeeds.feed_activity,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'num_employee',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_number_employees') + ':',
                                text: _vm.$t('number_employees')+'*',
                                label: _vm.$t('number_employees'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left: -5px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            },"datainput":{
                                id: 'value_num_employee',
                                name: 'register[company][num_employee][]'
                            },"feed":_vm.getFeeds.feed_num_employers,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{ref:"refCompanyMoreInformation",attrs:{"datalabel":{
                                text: _vm.$t('more_information'),
                                class: 'T15_b',
                                styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                            },"datainput":{
                                id: 'more_information',
                                name: 'register[company][more_information]',
                                required: false,
                                value: _vm.persistance.company.more_information
                            }},on:{"blur":function($event){return _vm.setPersistance('refCompanyMoreInformation')}}})],1)])]},proxy:true}],null,false,2875158347)}),_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_contact',title: _vm.$t('contact_person'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refContactName",attrs:{"datalabel":{
                                text: _vm.$t('name') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_name',
                                name: 'register[contact][name]',
                                style: 'width: 20%',
                                value: _vm.persistance.contact.name,
                                type: 'text'
                            }},on:{"blur":function($event){return _vm.setPersistance('refContactName')}}}),_c('GhInputTextField',{ref:"refContactSurnames",attrs:{"datalabel":{
                                text: _vm.$t('surnames') + '*',
                                style:'width: 150px; text-align: right;margin-left: 20px;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_surnames',
                                name: 'register[contact][surnames]',
                                style: 'width: 20%',
                                value: _vm.persistance.contact.surnames,
                                type: 'text',
                            }},on:{"blur":function($event){return _vm.setPersistance('refContactSurnames')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refContactEmail",attrs:{"datalabel":{
                                text: _vm.$t('email') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_email',
                                name: 'register[contact][email]',
                                style: 'width: 20%',
                                value: _vm.persistance.contact.email,
                                type: 'text',
                            }},on:{"blur":function($event){return _vm.setPersistance('refContactEmail')}}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'position',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_position') + ':',
                                text: _vm.$t('position'),
                                label: _vm.$t('position'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left: 20px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"datainput":{
                                id: 'value_num_employee',
                                name: 'register[contact][position][]'
                            },"feed":_vm.getFeeds.feed_positions,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:"refContactTelephone",attrs:{"datalabel":{
                                text: _vm.$t('contact_telephone'),
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: false,
                                id: 'contact_telephone',
                                name: 'register[contact][telephone]',
                                style: 'width: 20%',
                                value: _vm.persistance.contact.telephone,
                                type: 'text',
                            }},on:{"blur":function($event){return _vm.setPersistance('refContactTelephone')}}})],1)])]},proxy:true}],null,false,1393750538)}),_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_treatment_data',title: _vm.$t('data_treatment'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text:'',style:'',class:'',id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('politic_privacity') + '*',type:'checkbox',id:'',value:'all',name:'register[politic_privacity]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                id: 'action_politic_privacity',
                                text: _vm.$t('politic_privacity')
                            }},on:{"click":function($event){return _vm.downloadPDF('politic_privacity')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text: '',style:'',class:'',id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('terms_conditions') + '*', type:'checkbox',id:'',value:'all',name:'register[terms_conditions]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                id: 'action_terms_conditions',
                                text: _vm.$t('terms_conditions')
                            }},on:{"click":function($event){return _vm.downloadPDF('terms_conditions')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text: '', style:'', class:'', id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('legal_advise') + '*', type:'checkbox',id:'', value: 1, name:'register[legal_advise]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                                id: 'action_legal_advise',
                                text: _vm.$t('legal_advise')
                            }},on:{"click":function($event){return _vm.downloadPDF('legal_advise')}}})],1)])]},proxy:true}],null,false,3783678087)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_send',text: _vm.$t('send_request'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.sendRegister();}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('go_out'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.back();}}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }