var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_assigned',
        title: _vm.$t('assignment'),
        type: 'info',
        style: 'width: 600px; min-width:600px;',
    },"bodyConfiguration":{
        id: '',
        class: '',
        text: _vm.title,
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important;'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto","margin-top":"20px"}},_vm._l((_vm.data),function(item,key){return _c('div',{key:'LIST_PROGRAM_' + key,style:(key % 2 ? 'background-color: #fff;' : 'background-color: #d9edfc;')},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"padding":"2px 20px","display":"block","margin-top":"unset"}},[_vm._v(_vm._s(item))])])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close_popup_assigned',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_assigned');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }