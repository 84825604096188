<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex + refreshIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'contracted_programs_' + label.id,
                    title: $t(label['name']),
                    label: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>

        <component v-bind:is="components.popup_assign" :refresher="refreshAssign"/>
        <component v-bind:is="components.popup_weighting" :refresher="refreshWeighting" :dataWeighting="dataWeighting" :maxlength="maxlength" :show="false"/>

        <div :key="'REFRESH_GETTER_IMPORT' + refreshImport">
            <GhDataSelector
                :datablock="{
                    id: 'agrupator_import',
                    class: 'T15_b',
                    subtitle: $t('select_import') + ':',
                    text: $t('import'),
                    label: $t('import'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px; display: none;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                }"
                :datainput="{
                    id: 'agrupator_import',
                    name: 'agrupator[import]'
                }"
                :feed="getterImportAgrupators"
                :selected_input="[]"
                ref="RefSelectorImport"
                @accept="doAction('import')"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import contracted_programs from "@/view/itinerary/client/contracted_programs/contracted_programs/contracted_programs.vue";
    import popup_assign from '@/view/shared/popup/popup_assign.vue';
    import popup_weighting from '@/view/shared/popup/popup_weighting.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'list_contracted_programs',
        components: {
            GhNoDropSelector,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterImportAgrupators'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'contracted_programs', component: contracted_programs, result: '', show: true , second_text: ''},
                ],
                components: {
                    popup_assign: popup_assign,
                    popup_weighting: popup_weighting
                },
                actualCard: null,
                refreshImport: 0,
                refreshAssign: 0,
                refreshWeighting: 0,
                refreshIndex: 0,
                dataWeighting: [],
                maxlength: 100,
                haveData: false
            }
        },
        async beforeMount(){
            this.haveData = true;
        },
        methods: {
            async doAction(type){
                switch (type){
                    case 'import':
                        var text = '';
                        if(Object.keys(this.$refs['RefSelectorImport'].checkdata).length !== 0){
                            await this.$store.dispatch('setImportAgrupators', {
                                agrupator_id_origin: this.actualCard,
                                agrupator_id_destiny: Object.keys(this.$refs['RefSelectorImport'].checkdata)[0]
                            }, {root: true}).then(async (response) => {
                                text = response ? this.$t('import_was_successful') : this.$t('the_import_could_not_be_completed');

                                if(response){
                                    await this.$store.dispatch('getContractedPrograms', '', {root: true});
                                    this.$nextTick(() => {
                                        this.refreshIndex++;
                                    });
                                }
                            });
                        }else {
                            text = this.$t('the_import_could_not_be_completed');
                        }

                        this.$toast(text, {
                            position: "bottom-right",
                            timeout: 4000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false
                        });
                        break;
                }
            }
        }
    }
</script>

<style>
    [id^="contracted_programs_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }

    #agrupator_import_data_select_SUMMARY {
        display: none;
    }
</style>