import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import { API_URL } from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        data: [],
        flagContractedPrograms: false,
        weightingElementAgrupator: [],
        flagWeightingElementAgrupator: false,
        infoAgrupator: [],
        flagInfoAgrupator: false,
        importAgrupator: [],
        flagImportAgrupator: false
    },
    mutations: {
        loadContractedProgramsData(state, payload){
            state.flagContractedPrograms = !state.flagContractedPrograms;
            state.data = payload;
        },
        loadWeightingElementAgrupator(state, payload){
            state.flagWeightingElementAgrupator = !state.flagWeightingElementAgrupator;
            state.weightingElementAgrupator = payload;
        },
        loadInfoAgrupator(state, payload){
            state.flagInfoAgrupator = !state.flagInfoAgrupator;
            state.infoAgrupator = payload;
        },
        loadImportAgrupators(state, payload){
            state.flagImportAgrupator = !state.flagImportAgrupator;
            state.importAgrupator = payload;
        }
    },
    getters: {
        getterContractedPrograms(state) {
            const flagTreeItinerary = state.flagContractedPrograms; // eslint-disable-line no-unused-vars
            return state.data;
        },
        getterWeightingElementAgrupator(state) {
            const flagTreeItinerary = state.flagWeightingElementAgrupator; // eslint-disable-line no-unused-vars
            return state.weightingElementAgrupator;
        },
        getterInfoAgrupator(state) {
            const flagTreeItinerary = state.flagInfoAgrupator; // eslint-disable-line no-unused-vars
            return state.infoAgrupator;
        },
        getterImportAgrupators(state) {
            const flagImportAgrupator = state.flagImportAgrupator; // eslint-disable-line no-unused-vars
            return state.importAgrupator;
        },
    },
    actions: {
        async getContractedPrograms(state){
            let requestLogin = API_URL + "programs/contracted";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadContractedProgramsData", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getWeightingByAgrupatorAndCompany(state,item){
            await axios.get(
                API_URL + item.from + "/assign/itinerary/weighting-element/" + item.agrupator_id + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadWeightingElementAgrupator", response.data.data);
                        state.commit("loadInfoAgrupator", item);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getImportAgrupators(state,item){
            await axios.get(
                API_URL + "programs/import/list/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadImportAgrupators", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setImportAgrupators(state,item){
            let request = API_URL + "programs/import/" + item.agrupator_id_origin + "/" + item.agrupator_id_destiny;
            let formData = new FormData();

            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            );

        },
        async setWeightingByAgrupatorAndCompany(state, item){
            let request = API_URL + item.from + "/assign/itinerary/weighting-element/" + item.agrupator_id + "/" + item.id
            let formData = new FormData();
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        Global.closePopUp('popup_weighting');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};