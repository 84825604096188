<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: Login.perm_support,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: 'consensus',
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="gh-content-global-questionnaire">
            <div class="question-body">
                <div class="gh-statement-content T15">
                    <table>
                        <thead>
                            <tr v-for="(head, keyHeader) in header" :key="'HEADER_' + keyHeader">
                                <td v-for="(innerHeader, keyInnerHeader) in head" :colspan="innerHeader.colspan !== undefined ? innerHeader.colspan : 1" :style="innerHeader.style !== undefined ? innerHeader.style : ''" :class="innerHeader.class" :key="'INNER_HEADER_' + keyHeader + keyInnerHeader" style="height: 32px;">
                                    {{innerHeader.text}}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, itemIndex) in getterConsensusJointMap.content" :key="'ROW_BODY_' + itemIndex">
                                <template v-for="(head, keyHeader) in header[2]">
                                    <template v-if="head.flag === 'user'">
                                        <template v-if="haveRelation">
                                            <td
                                                :key="'ROW_COL_BODY_' + itemIndex + keyHeader + 'MINE_1'"
                                                :class="item.parent ? 'T-subtitle-second' : 'T-text'"
                                                :style="(item.parent ? 'border-bottom: 2px solid #C7C7C7; background-color: #E1F1FD;' : 'border-bottom: 1px solid #C7C7C7;') + (item[head.field].mine_style !== undefined && item[head.field].mine_style !== 0 ? 'background-color: ' + (item[head.field].mine_style === 1 ? '#EB2E34 !important;' : '#BFFFFF !important;') : '')"

                                                style="height: 32px; text-align: center; border-right: 1px solid #C7C7C7;"
                                            >
                                                {{item[head.field].mine}}
                                            </td>
                                            <td
                                                :key="'ROW_COL_BODY_' + itemIndex + keyHeader + 'COMPARISON_1'"
                                                :class="item.parent ? 'T-subtitle-second' : 'T-text'"
                                                :style="item.parent ? 'border-bottom: 2px solid #C7C7C7; background-color: #E1F1FD;' : 'border-bottom: 1px solid #C7C7C7;'"
                                                style="height: 32px; text-align: center; color: #C7C7C7; border-right: 1px solid #C7C7C7;"
                                            >
                                                {{item[head.field].comparison}}
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td
                                                :key="'ROW_COL_BODY_' + itemIndex + keyHeader + 'MINE_1'"
                                                :class="item.parent ? 'T-subtitle-second' : 'T-text'"
                                                :style="(item.parent ? 'border-bottom: 2px solid #C7C7C7; background-color: #E1F1FD;' : 'border-bottom: 1px solid #C7C7C7;') + (item[head.field].mine_style !== undefined && item[head.field].mine_style !== 0 ? 'background-color: ' + (item[head.field].mine_style === 1 ? '#EB2E34 !important;' : '#BFFFFF !important;') : '')"
                                                style="height: 32px; text-align: center; border-right: 1px solid #C7C7C7;"
                                            >
                                                {{item[head.field].mine}}
                                            </td>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <td
                                            :key="'ROW_COL_BODY_' + itemIndex + keyHeader"
                                            :colspan="head.field === 'final' ? 2 : 1"
                                            :class="item.parent ? 'T-subtitle-second' : 'T-text'"
                                            :style="(head.field === 'final' ? 'text-align: center;' : '') + (item.parent ? 'border-bottom: 2px solid #C7C7C7; background-color: #E1F1FD;' : 'border-bottom: 1px solid #C7C7C7;') + (head.field === 'attributes' ? 'padding-left: 10px; border-right: 2px solid #C7C7C7;' : '') + (head.field === 'final' ? 'border-left: 2px solid #C7C7C7;' : '')"
                                            style="height: 32px;"
                                        >
                                            <template v-if="head.field === 'final' && !item.parent && !disabled">
                                                <div class="centered">
                                                    <GhInputTextField
                                                        :datalabel="{
                                                            text: '',
                                                            id: 'label_punctuation_' + item.id,
                                                            class: '',
                                                            style:'margin-left: 35px;',
                                                        }"
                                                        :datainput="{
                                                            id: 'input_punctuation_' + item.id,
                                                            name: 'data[punctuation][' + item.id + ']',
                                                            type: 'int',
                                                            limitNumMax: 100,
                                                            style: 'width: 50px;',
                                                            value: item.final
                                                        }"
                                                        :ref="'RefPunctuation' + item.id"
                                                        @focusout="doPunctuation()"
                                                    />
                                                </div>
                                            </template>
                                            <template v-else>
                                                {{item[head.field]}}
                                            </template>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr v-for="(item, itemIndex) in getterConsensusJointMap.footer.footer" :key="'ROW_FOOTER_' + itemIndex">
                                <template v-for="(head, keyHeader) in header[2]">
                                    <template v-if="head.flag === 'user'">
                                        <template v-if="haveRelation">
                                            <td
                                                :key="'ROW_COL_FOOTER_' + itemIndex + keyHeader + 'MINE_1'"
                                                class="T-subtitle-second"
                                                style="height: 32px; text-align: center; border-bottom: 2px solid #C7C7C7; border-right: 1px solid #C7C7C7; background-color: #F3FDF0;"
                                            >
                                                {{item[head.field].mine}}
                                            </td>
                                            <td
                                                :key="'ROW_COL_FOOTER_' + itemIndex + keyHeader + 'COMPARISON_1'"
                                                class="T-subtitle-second"
                                                style="height: 32px; text-align: center; color: #C7C7C7; border-right: 1px solid #C7C7C7; border-bottom: 2px solid #C7C7C7; background-color: #F3FDF0;"
                                            >
                                                {{item[head.field].comparison}}
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td
                                                :key="'ROW_COL_FOOTER_' + itemIndex + keyHeader + 'MINE_1'"
                                                class="T-subtitle-second"
                                                style="height: 32px; text-align: center; border-right: 1px solid #C7C7C7; border-bottom: 2px solid #C7C7C7; background-color: #F3FDF0;"
                                            >
                                                {{item[head.field].mine}}
                                            </td>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <td
                                            :key="'ROW_COL_BODY_' + itemIndex + keyHeader"
                                            :colspan="head.field === 'final' ? 2 : 1"
                                            class="T-subtitle-second"
                                            :style="(head.field === 'final' ? 'text-align: center;' : '')  + (head.field === 'attributes' ? 'padding-left: 10px; border-right: 2px solid #C7C7C7;' : '') + (head.field === 'final' ? 'border-left: 2px solid #C7C7C7;' : '')"
                                            style="height: 32px; border-bottom: 2px solid #C7C7C7; background-color: #F3FDF0;"
                                        >
                                            {{item[head.field]}}
                                        </td>
                                    </template>
                                </template>
                            </tr>
                            <tr v-for="(item, itemIndex) in getterConsensusJointMap.footer.relation" :key="'ROW_RELATION_' + itemIndex">
                                <td :colspan="countUsers + 3" class="T-subtitle-second" style="padding-left: 10px; height: 32px; color: #C7C7C7;">{{item}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="centered" style="margin-top: 15px; padding-bottom: 15px;">
                    <GhButton
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_back_joint_map',
                            text: $t('close')
                        }"
                        :title="$t('close')"
                        @click="Global.windowClose()"
                    />
                    <template v-if="!disabled">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_save_joint_map',
                                text: $t('save')
                            }"
                            :title="$t('save')"
                            @click="doSave('save')"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_finish_joint_map',
                                text: $t('finish'),
                                class: 'T19 container-md_button_content button-questionnaire-primary'
                            }"
                            :title="$t('finish')"
                            @click="doSave('finish')"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters, mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'template_consensus',
        components: {
            GhButton,
            GhLocation,
            GhInputTextField
        },
        computed: {
            ...mapState(['Login']),
            ...mapGetters(['getterConsensusJointMap'])
        },
        data(){
            return {
                header: [],
                haveRelation: false,
                disabled: false,
                title: '',
                countUsers: 0,
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConsensusHeader', {
                id: this.$root._route.params.actual_id,
                type: this.$root._route.params.type,
                extra_id: this.$root._route.params.extra_id
            }, {root: true}).then((response) => {
                if(response !== undefined){
                    this.title = response.name;

                    this.countUsers = Object.keys(response.users).length;

                    if(response.have_relation){
                        this.haveRelation = true;
                        this.countUsers = this.countUsers * 2;
                    }

                    this.disabled = response.disabled;

                    this.header.push(
                        [
                            {text: this.$t('consensus_scoring_attributes'), class: 'T-subtitle-second', style: 'border-bottom: 2px solid #C7C7C7; border-right: 2px solid #C7C7C7; padding-left: 10px;', colspan: this.countUsers + 1},
                            {text: this.$t('max'), class: 'T-subtitle-second', style: 'border-bottom: 2px solid #C7C7C7; border-right: 2px solid #C7C7C7; text-align: center;'},
                            {text: this.$t('level'), class: 'T-subtitle-second', style: 'border-bottom: 2px solid #C7C7C7; text-align: center;'}
                        ]
                    );

                    this.header.push(
                        [
                            {text: response.name, class: 'T-subtitle-second', style: 'color: #1B82C5; border-bottom: 2px solid #C7C7C7; border-right: 2px solid #C7C7C7; padding-left: 10px;', colspan: this.countUsers + 1},
                            {text: response.max, class: 'T-subtitle-second', style: 'border-bottom: 2px solid #C7C7C7; border-right: 2px solid #C7C7C7; text-align: center;'},
                            {text: response.level, class: 'T-subtitle-second', style: 'border-bottom: 2px solid #C7C7C7; text-align: center;'}
                        ]
                    );

                    this.header.push(
                        [
                            {text: this.$t('attributes'), field: 'attributes', style: 'border-bottom: 2px solid #C7C7C7; border-right: 2px solid #C7C7C7; min-width: 100px; max-width: 100px; width: 40%; padding-left: 10px;', class: 'T-subtitle-second'},
                        ]
                    );

                    Object.entries(response.users).forEach((i, k) => {
                        this.header[2].push({text: i[1], field: i[0], flag: 'user', style: 'border-bottom: 2px solid #C7C7C7; text-align: center; border-right: ' + (k === Object.keys(response.users).length - 1 ? 2 : 1) + 'px solid #C7C7C7; min-width: 100px; max-width: 100px;', class: 'T-subtitle-second', colspan: response.have_relation ? 2 : 1});
                    });

                    this.header[2].push({text: this.$t('final'), field: 'final', style: 'border-bottom: 2px solid #C7C7C7; min-width: 100px; max-width: 100px; text-align: center;', class: 'T-subtitle-second', colspan: 2})
                }
            });

            await this.$store.dispatch('getConsensusJointMap', {
                id: this.$root._route.params.actual_id,
                type: this.$root._route.params.type,
                extra_id: this.$root._route.params.extra_id
            }, {root: true});

            this.header[1][2].text = this.getterConsensusJointMap.level;

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            async doPunctuation(){
                var formData = new FormData();
                this.getterConsensusJointMap.content.forEach((i) => {
                    if(this.$refs['RefPunctuation' + i.id] !== undefined && this.$refs['RefPunctuation' + i.id][0].datainput.value !== undefined){
                        if(this.$refs['RefPunctuation' + i.id][0].datainput.value === ''){
                            this.$refs['RefPunctuation' + i.id][0].datainput.value = 10;
                        }

                        var num = parseInt(this.$refs['RefPunctuation' + i.id][0].datainput.value, 10);

                        if(num < 10){
                            this.$refs['RefPunctuation' + i.id][0].datainput.value = 10;
                            num = 10;
                        }

                        if (num % 5 !== 0) {
                            num = Math.floor(num / 5) * 5;
                            this.$refs['RefPunctuation' + i.id][0].datainput.value = num;
                        }

                        formData.append('data[punctuation][' + i.id + ']', this.$refs['RefPunctuation' + i.id][0].datainput.value);
                    }
                });

                await this.$store.dispatch('setCalcConsensusPunctuation', {
                    id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id,
                    formData: [...formData]
                }, {root: true}).then((response) => {
                    if(response !== undefined && response.status !== undefined && response.status === 200){
                        response.data.data.forEach((i) => {
                            if(i.id === 'level'){
                                this.header[1][2].text = i.punctuation;
                            }
                        });
                    }
                });
            },
            async doSave(type){
                var formData = new FormData();
                this.getterConsensusJointMap.content.forEach((i) => {
                    if(this.$refs['RefPunctuation' + i.id] !== undefined && this.$refs['RefPunctuation' + i.id][0].datainput.value !== undefined) {
                        formData.append('data[punctuation][' + i.id + ']', this.$refs['RefPunctuation' + i.id][0].datainput.value);
                    }
                });

                await this.$store.dispatch('setConsensus', {
                    id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id,
                    type_save: type,
                    formData: [...formData]
                }, {root: true}).then((response) => {
                    if(type === 'finish'){
                        window.opener.UpdatedSelect({
                            'data': response
                        });

                        this.Global.windowClose();
                    }else{
                        this.$toast(this.$t('changes_have_been_saved_correctly'), {
                            position: "bottom-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false
                        });
                    }
                });
            }
        }
    }
</script>

<style>
    [id^="input_punctuation_"] {
        text-align: center;
    }
</style>