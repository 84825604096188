<template>
    <div v-if="haveData">
        <GhTreeContentBlock
            style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;"
            :dataBlock="{
                id: 'pending',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
                extraTableClass: 'class-table-exercise'
            }"
            :data="BaseCreationExerciseTable.records_data_pending !== undefined && BaseCreationExerciseTable.records_data_pending.data !== undefined && BaseCreationExerciseTable.records_data_pending.data.length === 0 ? [] : BaseCreationExerciseTable.records_data_pending.data"
            :header="header"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'pending'}}"><a class="table-cell_link T13"> <i>{{itemProp[labelProp]}}</i> </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="BaseCreationExerciseTable.records_data_pending.actions.modify">
                    <router-link :to="{name: 'config_exercise_id', params: {id: itemProp.id,type: 'pending'}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_config_exercises',
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg')
                            }"
                        />
                    </router-link>
                </template>
                <template v-if="BaseCreationExerciseTable.records_data_pending.actions.view">
                    <GhAction
                        :dataction="{
                            id: 'see_config_exercises',
                            text: $t('see'),
                            icon: require('../../../../assets/gh_new_dessign/view.svg')
                        }"
                        @click="$store.dispatch('setQuestionnaireImpartition', {
                            impartition: false
                        }, {root: true});
                        Global.windowOpen(Global.openSecondWindow('preview_exercise', {id:itemProp.id,type:'exercise'}),'preview_exercise')"
                    />
                </template>
            </template>
        </GhTreeContentBlock>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTreeContentBlock from "@/components/GhTreeContentBlock.vue";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_pending",
        components: {
            GhTreeContentBlock,
            GhAction

        },
        computed: {
            ...mapState(['BaseCreationExerciseTable'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 150px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getExercisesPending', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>