import BusinessList from "@/view/business/list_business";

import BusinessFloat from "@/view/business/template/windowed.vue";

const routes = [
    {
        path: '/business',
        name: 'company',
        component: BusinessList,
        props: {showMenu: true},
    },
    {
        path: '/business/edit/:windowed',
        name: 'edit_business',
        component: BusinessFloat,
        props: {windowed: null, showMenu: false, background: true, otherTitle: 'evaluator'},
    }
];

export default routes;