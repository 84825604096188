var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'REF' + _vm.refreshView},[(_vm.haveData)?_c('GhCEQuestionnaireConstructor',{ref:"RefQuestionnaire",attrs:{"block":{
                svg: {
                    add: require('@/assets/gh_new_dessign/add_plus.svg'),
                    noImg: require('@/assets/gh_new_dessign/no-image2.svg'),
                    arrowRight: require('@/assets/questionnaire/arrow-right.svg'),
                    arrowDown: require('@/assets/questionnaire/arrow-down.svg'),
                    arrowUp: require('@/assets/questionnaire/arrow-up.svg'),
                    close: '',
                    levels: {
                        '1': require('@/assets/questionnaire/level1.svg'),
                        '2': require('@/assets/questionnaire/level2.svg'),
                        '3': require('@/assets/questionnaire/level3.svg'),
                        '4': require('@/assets/questionnaire/level4.svg'),
                        '5': require('@/assets/questionnaire/level5.svg')
                    },
                    material: require('@/assets/questionnaire/book.svg'),
                    download: require('@/assets/gh_new_dessign/descargar.svg'),
                    delete: require('@/assets/gh_new_dessign/trash.svg')
                },
                pointer: {
                    colors: [
                        '#C7C7C7',
                        '#F58449',
                        '#005392',
                        '#3DA106'
                    ],
                    title: [
                        'for_start',
                        'in_progress',
                        'finish'
                    ]
                },
                evidence: {
                    dispatch: 'easyUpload',
                    accept: '.pdf, .xls, .xlsx, .doc, .docx, .odt',
                    remove: true,
                    removeText: '',
                    removeSvg: require('@/assets/questionnaire/trash.svg'),
                    lupaSvg: require('@/assets/gh_new_dessign/lupa.svg'),
                }
            },"actualIndex":_vm.actualIndex,"firstQuest":_vm.getterFirstQuestionary,"dataIndex":_vm.getterQuestionnaireIndex,"dataBody":_vm.getterQuestionnaireBodyContent,"dataMaterials":_vm.getterQuestionnaireMaterial,"nextQuestionnary":_vm.getterNextQuestionnaire,"feedIndex":_vm.getterQuestionnaireIndexFeed,"finishedQuestionnaireInitial":_vm.getterFinishedQuestionnaireInitial,"allowImpartitionFinish":_vm.getterAllowImpartitionFinish},on:{"setQuestion":_vm.handleSetQuestion,"getMaterial":_vm.handeGetMaterial,"setSave":_vm.handeSetSave,"setFinish":_vm.handeSetFinish,"watcherFinish":_vm.handleSetQuestion,"resetNextQuestionnaire":_vm.resetNextQuestion,"checkParent":_vm.handleCheckParent},scopedSlots:_vm._u([{key:"slotFinishedQuestionnaireInitial",fn:function(){return [_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"min-width":"400px","max-width":"600px","width":"50%"}},[_c('div',{staticStyle:{"display":"flex","margin-top":"5px"}},_vm._l((_vm.getterChartResult.legend),function(i,k){return _c('div',{key:'LEGEND_' + k},[_c('div',{staticStyle:{"cursor":"pointer","display":"flex","margin-left":"20px"},style:(i.show ? '' : 'opacity: 0.4;'),on:{"click":function($event){return _vm.showHideChart(k, i.show)}}},[_c('div',{staticStyle:{"width":"25px","height":"5px","margin-top":"7px","margin-right":"10px"},style:('background-color:' + i.color)}),_c('div',{staticClass:"T-subtitle-second"},[_vm._v(_vm._s(i.name))])])])}),0),_c('div',{key:'CHART_' + _vm.refreshChart},[_c('GhChartRadar',{attrs:{"dataBlock":{
                                    id: '',
                                    class: '',
                                    style: ''
                                },"chartConfig":{
                                    margin: {
                                        top: 110,
                                        right: 100,
                                        bottom: 10,
                                        left: 100
                                    },
                                    color: _vm.getterChartResult.colors,
                                    maxValue: _vm.getterChartResult.max_punctuation,
                                    levels: _vm.getterChartResult.max_punctua,
                                    roundStrokes: false,
                                    labelFactor: 1.3,
                                    wrapWidth: 120,
                                    opacityArea: 0,
                                    dotRadius: 4,
                                    opacityCircles: 0,
                                    strokeWidth: 3,
                                    blur: 5
                                },"chartData":_vm.chartRadar}})],1)]),_c('div',{staticStyle:{"width":"50%"}},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"T-subtitle color-light-blue",staticStyle:{"margin-right":"5px","line-height":"1.4"}},[_vm._v(_vm._s(_vm.$t('this_is_yours_position_chart')))])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-top":"15px"}},[_c('label',{staticClass:"T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('chart_text_send_mail')))])])])]),_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'to_pay', params: {
                        id: _vm.$root._route.params.id
                    }}}},[_c('GhButton',{staticClass:"button-questionnaire-primary",attrs:{"datainput":{
                                id: 'btn_start',
                                text: _vm.$t('want_start_excelence'),
                                class: '',
                                style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'
                            }}})],1)],1)]},proxy:true},{key:"finishQuestionnaire",fn:function(){return [(!_vm.readOnly || _vm.readOnly === 'false')?[_c('div',{staticClass:"centered",staticStyle:{"padding-top":"20px"}},[_c('GhButton',{staticClass:"button-questionnaire-primary",attrs:{"datainput":{
                                id: 'btn_finish',
                                text: _vm.$t('finish')
                            }},on:{"click":function($event){return _vm.setFinishAllQuestionnaire()}}})],1)]:_vm._e()]},proxy:true},{key:"contentInsideMenu",fn:function(){return [(!_vm.readOnly || _vm.readOnly === 'false')?[(_vm.getterQuestionnaireBodyContent.impartition === 1 || _vm.getterQuestionnaireBodyContent.impartition === true || _vm.getterQuestionnaireBodyContent.impartition === false ?
                    _vm.getterAllowImpartitionFinish && !_vm.getterFirstQuestionary && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.finish !== undefined && _vm.getterQuestionnaireIndex.finish && _vm.getterQuestionnaireBodyContent !== undefined && _vm.getterQuestionnaireBodyContent.screens !== undefined && _vm.getterQuestionnaireBodyContent.screens['first'] :
                    !_vm.getterFirstQuestionary && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.finish !== undefined && _vm.getterQuestionnaireIndex.finish)?[_c('GhButton',{staticClass:"button-questionnaire-primary",attrs:{"datainput":{
                                id: 'btn_finish',
                                text: _vm.$t('finish')
                            }},on:{"click":function($event){return _vm.setFinishAllQuestionnaire()}}})]:_vm._e(),(_vm.getterQuestionnaireIndex.show_ponderation)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                id: 'btn_show_report',
                                text: _vm.$t('view_ponderation')
                            }},on:{"click":function($event){return _vm.getPonderation()}}})]:_vm._e(),(_vm.getterQuestionnaireIndex.show_report)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                id: 'btn_show_report',
                                text: _vm.pdfGenerate ? _vm.$t('view_report') : _vm.$t('generating'),
                                icon: _vm.pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"disabled":!_vm.pdfGenerate},on:{"click":function($event){return _vm.generatePDF()}}})]:_vm._e(),(_vm.getterQuestionnaireIndex.show_memory)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                id: 'btn_view_annexes',
                                text: _vm.pdfMemory ? _vm.$t('company_memory') : _vm.$t('generating'),
                                icon: _vm.pdfMemory ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"disabled":!_vm.pdfMemory},on:{"click":_vm.generateMemory}})]:_vm._e(),(_vm.$root._route.params.type !== 'grouper' && _vm.$root._route.params.type !== 'exercise' && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.show_annexes !== undefined && _vm.getterQuestionnaireIndex.show_annexes)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                id: 'btn_view_annexes',
                                text: _vm.$t('view_annexes')
                            }},on:{"click":_vm.showAnnexes}})]:_vm._e(),(_vm.$root._route.params.type !== 'grouper' && _vm.$root._route.params.type !== 'exercise' && _vm.getterQuestionnaireIndex !== undefined && _vm.getterQuestionnaireIndex.joint_map !== undefined && _vm.getterQuestionnaireIndex.joint_map)?[_c('router-link',{attrs:{"to":{name: 'joint_map', params: {
                            id: _vm.$root._route.params.id,
                            type: _vm.$root._route.params.type,
                            extra_id: _vm.$root._route.params.extra_id
                        }}}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                    id: 'btn_view_joint_map',
                                    text: _vm.$t('view_joint_map')
                                }}})],1)]:_vm._e()]:_vm._e(),_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                        id: 'btn_view_global_map',
                        text: _vm.$t('view_global_map')
                    },"disabled":_vm.$root._route.params.type === 'grouper' || _vm.$root._route.params.type === 'exercise'},on:{"click":function($event){return _vm.openMap()}}}),(_vm.$route.name === 'preview_grouper' || _vm.$route.name === 'initial_itinerary_window')?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px"},attrs:{"dataction":{
                            id: 'close_eval',
                            text: '',
                            class: 'T-subtitle-colored',
                            icon: require('@/assets/gh_new_dessign/out.svg'),
                        }},on:{"click":function($event){_vm.$route.name === 'preview_grouper' ? _vm.Global.windowClose() : _vm.$route.name === 'initial_itinerary_window' ? _vm.router.push({name: _vm.activeItem === 'todo' ? 'todo' : 'my_itineraries'}) : ''}}})]:[_c('div',{staticStyle:{"width":"50px"}})]]},proxy:true},{key:"extraContent",fn:function(){return [_c('GhChartTable',{ref:"RefGlobalMap",staticStyle:{"margin-top":"-15px"},attrs:{"extratable":{
                        id: 'global_map',
                        more: require('@/assets/questionnaire/arrow-down.svg'),
                        less: require('@/assets/questionnaire/arrow-up.svg'),
                        pointer: {
                            colors: [
                                '#C7C7C7',
                                '#F58449',
                                '#005392',
                                '#3DA106'
                            ],
                            title: [
                                'for_start',
                                'in_progress',
                                'finish'
                            ]
                        },
                    },"header":_vm.headerChart,"data":_vm.getterQuestionnaireGlobalMap.data,"footer":[],"type":_vm.getterQuestionnaireGlobalMap.type,"evaluator":_vm.getterQuestionnaireGlobalMap.is_evaluator,"states":_vm.statesChart},on:{"advice":function($event){''},"columnAdvice":_vm.columnAdvice,"columnPunctuation":_vm.columnPunctuation},scopedSlots:_vm._u([{key:"advice_actions",fn:function({itemProp}){return [(_vm.getterQuestionnaireGlobalMap.type !== 3 && ((itemProp.hard_points !== undefined && itemProp.hard_points !== '') || (itemProp.improvement_opportunities !== undefined && itemProp.improvement_opportunities !== '') || (itemProp.good_practices !== undefined && itemProp.good_practices !== '')))?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px"},attrs:{"dataction":{
                                    id: 'see_advice_' + itemProp.id,
                                    text: _vm.$t('see'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/view2.svg'),
                                }},on:{"click":function($event){return _vm.showAdvice(itemProp)}}})]:_vm._e()]}},{key:"punctuation_actions",fn:function({itemProp}){return [(itemProp.detail)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px","margin-top":"1px"},attrs:{"dataction":{
                                    id: 'detail_' + itemProp.id,
                                    text: _vm.$t('detail'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/doc.svg'),
                                }},on:{"click":function($event){return _vm.showDetail(itemProp)}}})]:_vm._e()]}},{key:"header_actions",fn:function(){return undefined},proxy:true}],null,false,3094465462)})]},proxy:true},{key:"bodyHeaderActions",fn:function(){return [(!_vm.getterQuestionnaireBodyContent.evaluator)?[(_vm.getterQuestionnaireBodyContent.actions.know_more)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                id: 'action_know_more',
                                text: _vm.$t('know_more'),
                                icon: require('@/assets/questionnaire/hat.svg')
                            }},on:{"click":function($event){return _vm.getKnowMore()}}})]:_vm._e(),(_vm.getterQuestionnaireBodyContent.actions.information)?[_c('div',{on:{"click":function($event){return _vm.getInformation()}}},[_c('GhCECustomTitle',{attrs:{"content":_vm.getterQuestionnaireInformation.information !== undefined ? _vm.getterQuestionnaireInformation.information : ''}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                        id: 'action_information',
                                        text: _vm.$t('information'),
                                        icon: require('@/assets/questionnaire/information.svg'),
                                    }}})],1)],1)]:_vm._e()]:_vm._e()]},proxy:true},{key:"answerLegend",fn:function(){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row-reverse"},on:{"click":function($event){return _vm.getLegend()}}},[_c('GhCECustomTitle',{attrs:{"content":_vm.getterQuestionnaireInformation.legend !== undefined ? _vm.getterQuestionnaireInformation.legend : ''}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"padding-right":"5px","margin-right":"auto"},attrs:{"dataction":{
                                id: 'action_legend',
                                text: _vm.$t('legend'),
                                icon: require('@/assets/questionnaire/information.svg')
                            }}})],1)],1)]},proxy:true},{key:"buttonEvaluator",fn:function(){return [(!_vm.readOnly || _vm.readOnly === 'false')?[(_vm.getterQuestionnaireBodyContent !== undefined && _vm.getterQuestionnaireBodyContent.actions !== undefined && _vm.getterQuestionnaireBodyContent.actions.rate)?[_c('div',{staticClass:"no-apply-pallete"},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_rate',
                                    text: _vm.$t('rate'),
                                    class: 'T19 container-md_button_content button-questionnaire-primary',
                                }},on:{"click":function($event){return _vm.setRate()}}})],1)]:_vm._e(),(_vm.getterQuestionnaireBodyContent !== undefined && _vm.getterQuestionnaireBodyContent.actions !== undefined && _vm.getterQuestionnaireBodyContent.actions.evaluate)?[_c('div',{staticClass:"no-apply-pallete"},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_evaluate',
                                    text: _vm.$t('evaluate'),
                                    class: 'T19 container-md_button_content button-questionnaire-primary',
                                }},on:{"click":function($event){return _vm.setEvaluate()}}})],1)]:_vm._e()]:_vm._e()]},proxy:true}],null,false,2373062186)}):_vm._e(),_c(_vm.popups.learning,{tag:"component",attrs:{"title":_vm.titlePopupLearning}}),_c(_vm.popups.evaluate,{tag:"component"}),_c(_vm.popups.advice,{tag:"component"}),_c(_vm.popups.popup_weighting,{tag:"component",attrs:{"refresher":_vm.refreshWeighting,"dataWeighting":_vm.dataWeighting,"maxlength":_vm.maxlength,"show":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }