<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_assigned',
            title: $t('assignment'),
            type: 'info',
            style: 'width: 600px; min-width:600px;',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            text: title,
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important;'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div style="max-height: 300px; overflow-y: auto; margin-top: 20px;">
                <div v-for="(item, key) in data" :key="'LIST_PROGRAM_' + key" :style="key % 2 ? 'background-color: #fff;' : 'background-color: #d9edfc;'">
                    <label class="gh_text_field-label T15" style="padding: 2px 20px; display: block; margin-top: unset;">{{item}}</label>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_close_popup_assigned',
                    text: $t('close')
                }"
                @click="Global.closePopUp('popup_assigned');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'popup_assigned',
        props: ['title', 'data'],
        components: {
            GhPopUp,
            GhButton
        }
    }
</script>