var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{id:'table_todo'},"data":_vm.TabTodo.list_todo,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',{staticClass:"T15_b",style:(itemProp.color_end_date !== '' ? 'color:' + itemProp.color_end_date : '')},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]}},{key:"actions",fn:function({itemProp}){return [(itemProp.modify)?_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_todo_' + itemProp.id,
                    text: _vm.$t('modify'),
                    icon: require('@/assets/gh_new_dessign/edit.svg'),
                }},on:{"click":function($event){return _vm.modify(itemProp)}}}):_vm._e(),(itemProp.realize)?_c('GhAction',{attrs:{"dataction":{
                    id: 'realize_todo_' + itemProp.id,
                    text: _vm.$t('realize'),
                    icon: require('@/assets/gh_new_dessign/edit.svg'),
                }},on:{"click":function($event){return _vm.realize(itemProp)}}}):_vm._e(),_c('GhAction',{attrs:{"dataction":{
                    id: 'view_todo_' + itemProp.id,
                    text: _vm.$t('view'),
                    icon: require('@/assets/gh_new_dessign/view.svg'),
                }},on:{"click":function($event){return _vm.openAssign(itemProp)}}})]}}],null,false,610092419)}),_c(_vm.popup.assigned,{tag:"component",attrs:{"title":_vm.title,"data":_vm.getterAssignByUser}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }