<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_weighting',
            title: show ? $t('assing_weighting_value') : $t('assing_weighting_to_program'),
            type: show ? 'info' : 'add',
            style: 'width: 40%; min-width: 940px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
            text: $t(show ? 'assing_weighting_value_continue' : 'assing_weighting_to_program_continue') + (show ? '' : ': ' + getterInfoAgrupator.title)
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_weighting" :key="'REFRESHER_ASSIGN' + refresher + refreshPonderation">
                <div class="gh-content-global">
                    <template v-if="!show">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-left: 5px;">{{$t('ponderation_accepted_values') + '.'}}</label>
                        </div>
                    </template>
                    <div v-for="(program, keyProgram) in getterWeightingElementAgrupator" :key="'PROGRAM' + keyProgram" :style="keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : ''" style="width: 100%;">
                        <div class="gh-row-content" style="margin-left: 10px;">
                            <template v-if="!show">
                                <GhInputTextField
                                    :datalabel="{
                                        text: '',
                                        style: 'width: 160px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'assignation_base_associated_weighting_groups_' + keyProgram,
                                        name: 'weighting[' + keyProgram + ']',
                                        style: 'min-width: 40px; width: 40px',
                                        value: propDataWeighting[keyProgram] !== undefined && propDataWeighting[keyProgram].value !== undefined && propDataWeighting[keyProgram].value !== '' ? propDataWeighting[keyProgram].value : 20,
                                        type: 'int',
                                        limitNumMax: 40,
                                        label_required: program.name + '*'
                                    }"
                                    @focusout="controlWeighting('refWeighting' + keyProgram, keyProgram)"
                                    :ref="'refWeighting' + keyProgram"
                                />
                            </template>
                            <label v-if="show" class="gh_text_field-label T15_b" style="width: 20px;">{{propDataWeighting[keyProgram] !== undefined && propDataWeighting[keyProgram].value !== undefined && propDataWeighting[keyProgram].value !== '' ? propDataWeighting[keyProgram].value : ''}}</label><label class="gh_text_field-label" :class="show ? 'T15' : 'T15_b'" :style="show ? '' : 'margin-left: 10px;'">{{program.name + (show ?  '' : '*')}}</label>
                        </div>
                    </div>
                    <template v-if="!show">
                        <div class="gh-row-content" style="margin-left: 10px;">
                            <label class="gh_text_field-label T15_b">
                                <span :style="sumPonderation === 100 ? '' : 'color: red'">{{sumPonderation}}</span>
                                {{' ' + $t('of') + ' ' + 100}}
                            </label>
                        </div>
                    </template>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <template v-if="!show">
                <GhButton
                    :datainput="{
                        id: 'button_accept_assign',
                        text: $t('accept'),
                        class: 'T19 container-md_button_content ' + (sumPonderation === 100 ? '': 'disabled'),
                        style: ' display: flex;'
                    }"
                    @click="setWeighting"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'button_close_assign',
                    text: $t('close'),
                    class: show ? 'button-questionnaire-secondary' : 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_weighting');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";


    export default {
        name: "popup_assign",
        props: ['refresher', 'dataWeighting', 'maxlength', 'show'],
        computed: {
            ...mapGetters(['getterWeightingElementAgrupator', 'getterInfoAgrupator'])
        },
        data(){
            return {
                propDataWeighting: [],
                sumPonderation: this.$props.maxlength,
                refreshPonderation: 0
            }
        },
        watch: {
            'refresher'(){
                this.propDataWeighting = this.$props.dataWeighting;
                this.sumPonderation = parseInt(this.$props.maxlength);
            }
        },
        components: {
            GhPopUp,
            GhInputTextField,
            GhButton
        },
        methods: {
            controlWeighting(ref, id){
                var num = parseInt(this.$refs[ref][0].datainput.value, 10);

                if(num < 10){
                    this.$refs[ref][0].datainput.value = 10;
                    num = 10;
                }

                if (num % 5 !== 0) {
                    num = Math.floor(num / 5) * 5;
                    this.$refs[ref][0].datainput.value = num;
                }

                this.propDataWeighting[id].value = num;

                var sumPonderation = 0;
                Object.values(this.propDataWeighting).forEach((item) => {
                    sumPonderation += item.value
                });

                this.sumPonderation = sumPonderation;
                this.refreshPonderation++;
            },
            async setWeighting(){
                if(this.Global.checkRequired('form_weighting') === 'OK') {
                    const formData = new FormData(document.getElementById('form_weighting'));

                    await this.$store.dispatch('setWeightingByAgrupatorAndCompany', {
                        formData: [...formData],
                        id: this.getterInfoAgrupator.id,
                        from: this.getterInfoAgrupator.from,
                        agrupator_id: this.getterInfoAgrupator.agrupator_id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    [id^="label_user_assign_"][id$="_selector_label"]{
        width: 150px;
        min-width: 150px !important;
        text-align: right;
    }

    #label_user_assign_permission-Summary{
        display: none !important;
    }
</style>