<template>
    <div>
        <div class="gh-row-content">
            <GhCalendar
                :datalabel="{
                    id: 'label_search_start_date',
                    text: $t('date_from'),
                    style: 'margin-left: 45px;text-align: right;'
                }"
                :datainput="{
                    id: 'search_start_date',
                    name: 'historical[start_date]',
                    value: ''
                }"
            />
            <GhCalendar
                :datalabel="{
                    id: 'label_search_date_end',
                    text: $t('date_until'),
                    style: 'margin-right: 25px margin-left: 20px; min-width: 150px; !important; width: 40px !important; text-align: right;'
                }"
                :datainput="{
                    id: 'search_end_date',
                    name: 'historical[end_date]',
                    value: ''
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhMultipleSearch
                :datalabel="{
                    text: $t('open_by'),
                    style: 'margin-left: 45px;',
                    class: 'T15_b',
                    id: 'label_historical_user'
                }"
                :datainput="{
                    id: 'historical_user',
                    name: 'historical[user][name]',
                    nameS:'historical[user][surname]',
                    nameId:'historical[user][id]',
                    style: 'min-width: 200px;',
                    placeholder: $t('name'),
                    placeholderS: $t('surnames'),
                    result_1: 'first_name',
                    result_2: 'last_name',
                    result_id: 'id',
                    href: dispatchUserResponsible
                }"
            />
        </div>
        <div class="gh-row-content">
            <div>
                <div style="width: 50%;" class="no-min-width">
                    <GhInputTextField
                        :datalabel="{
                            id: 'label_search_identificator',
                            text: $t('identifier'),
                            style: 'margin-left: 45px;text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            id: 'input_search_name',
                            name: 'historical[identificator]',
                            style: 'width:280px; min-width:280px; max-height: 17px;',
                            type: 'text'
                        }"
                    />
                </div>
                <div style="width: 50%; margin-left: -13px;" class="no-min-width">
                    <GhDataSelector
                        :datablock="{
                            id: 'selected_support_category',
                            class: 'T15_b',
                            subtitle: $t('select_category') + ':',
                            text: $t('category'),
                            label: $t('category'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            style: ''

                        }"
                        :datainput="{
                            name: 'historical[category][]'
                        }"
                        :feed="getFeeds.feed_support_categories"
                        class="none_margin"
                    />
                </div>
            </div>
        </div>
        <div class="gh-row-content">
            <div>
                <div style="width: 50%;" class="no-min-width">
                    <GhDataSelector
                        :datablock="{
                            id: 'selected_support_assignation',
                            class: 'T15_b',
                            subtitle: $t('select_assignment'),
                            text: $t('assign_to'),
                            label: $t('assignment'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            style: 'display: flex; margin-left: 45px;',
                            popupStyleContent:'height:60px;'
                        }"
                        :datainput="{
                            name: 'historical[assigned_to][]'
                        }"
                        :feed="getFeeds.feed_support_asigned"
                    />
                </div>
                <div style="width: 50%;" class="no-min-width">
                    <GhDataSelector
                        :datablock="{
                            id: 'selected_support_priority',
                            class: 'T15_b',
                            subtitle: $t('select_priority')+':',
                            text: $t('priority'),
                            label: $t('priority'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            style: 'display: flex; margin-left:-12px;',
                            popupStyleContent: 'height:90px;'
                        }"
                        :datainput="{
                            name: 'historical[priority][]'
                        }"
                        :feed="getFeeds.feed_support_priority"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib"
    import GhCalendar from "fe-gh-input-calendar-lib"
    import GhDataSelector from 'fe-gh-data-selector-lib'
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    import {mapState} from "vuex";
    export default {
        name: "view_historical_search",
        components: {
            GhCalendar,
            GhInputTextField,
            GhDataSelector,
            GhMultipleSearch,
        },
        data(){
            return{
                dispatchUserResponsible: 'getUserSearch',
            }
        },
        computed:{
            ...mapState(['getFeeds'])
        }
    }
</script>
