<template>
    <div>
        <div class="gh_position-line" style="min-width: 1024px;">
            <div class="gh-content-questionnaire" style="padding-left: 30px; padding-right: 30px;">
                <div class="gh-row-content-without-padding">
                    <div class="ellipsis" style="width: 100%; display: flex; margin-right: 60px;" :title="titleBreadCrum">
                        <div v-for="(item, key) in tabs" :key="'BREAD_CRUMB' + key">
                            <div style="display: flex;" @click="dataIndex.finish && firstQuest || finishedQuestionnaireInitial ? '' : showIndex ? openParent() : openBreadCrumbIndex(),resetBreadCrum(),resetTitleBreadCrum()">
                                <template v-if="key !== 0">
                                    <div class="gh_margin-arrow">
                                        <img height="12" width="10" :src="block.svg.arrowRight" alt="" style="margin-top: 10px;">
                                    </div>
                                </template>
                                <template v-if="key === 0">
                                    <template v-if="!finishedQuestionnaireInitial && (!dataIndex.finish && firstQuest || !firstQuest)">
                                        <div style="align-content: center; margin-left: 5px; margin-top: -2px;">
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #1B82C5; margin: 2px"></div>
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #1B82C5; margin: 2px"></div>
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #1B82C5; margin: 2px"></div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div style="align-content: center; margin-left: 5px; margin-top: -2px;">
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #FFFFFF; margin: 2px"></div>
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #FFFFFF; margin: 2px"></div>
                                            <div style="cursor: pointer; width: 20px; height: 5px; background-color: #FFFFFF; margin: 2px"></div>
                                        </div>
                                    </template>
                                </template>
                                <div class="bread-crumb color-light-blue T-header" style="margin-top: -4px;width: max-content;" :style="key === 0 ? 'margin-left: -24px' : ''">
                                    <template v-if="key === 0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</template>{{item.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="!showMap">
                        <slot name="contentInsideMenu"/>
                    </template>
                    <template v-else-if="showMap">
                        <div class="cursor-pointer" style="margin-top: 4px;z-index: 9999" @click="closeMap()">
                            <img :src="block.svg.close" alt="">
                        </div>
                    </template>
                </div>
                <div class="gh-padding-index-questionnaire gh_position-index" v-if="showIndex" :key="'INDEX_REFRESHER' + refreshIndex" ref="indexContainer">
                    <div class="overflow-index">
                        <div class="margin-index-questionnaire">
                            <div style="display: flex; background-color: #e9f7ff; border-radius: 10px; width: min-content; margin-bottom: 20px; margin-top: -7px;">
                                <div class="tab-upcoming gh_text_field-label T15_b centered" style="margin-right: unset !important;" :style="showTypeIndex ? 'background-color: #1B82C5; color: #ffffff;' : ''" @click="checkShowTypeIndex(true)">
                                    {{$t('detailed').toUpperCase()}}
                                </div>
                                <div class="tab-past gh_text_field-label T15_b centered" style="margin-right: unset !important;" :style="!showTypeIndex ? 'background-color: #1B82C5; color: #ffffff;' : ''" @click="checkShowTypeIndex(false)">
                                    {{$t('resumed').toUpperCase()}}
                                </div>
                            </div>
                            <template v-if="showTypeIndex">
                                <div v-for="(itemIndex, keyIndex) in index" :key="'INDEX' + keyIndex + refreshIndex" v-show="itemIndex.show || itemIndex.level === 0">
                                    <div :style="itemIndex.level !== 0 ? 'display: flex; padding-left: ' + (itemIndex.level * 25) + 'px;' : 'display: flex; padding-left: 5px;'">
                                        <div style="display: flex; cursor: pointer; width: 100%;">
                                            <template v-if="itemIndex.parent_id === null && itemIndex.have_childrens">
                                                <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                                    <img height="18" width="18" style="margin-top: 5px;" :src="itemIndex.active ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                </div>
                                            </template>
                                            <div style="display: flex; width: 100%;">
                                                <template v-if="itemIndex.parent_id !== null">
                                                    <div class="gh-pointer" @click="checkQuestion(itemIndex); $emit('setQuestion', itemIndex, keyIndex, 'index')" :style="'color:' + block.pointer.colors[itemIndex.state]" :title="$t(block.pointer.title[itemIndex.state])">
                                                        ⬤
                                                    </div>
                                                </template>
                                                <span @click="checkQuestion(itemIndex); $emit('setQuestion', itemIndex, keyIndex, 'index')" :class="itemIndex.parent_id !== null ? itemIndex.current && keyIndex === current.key ? 'T-subtitle-second' : 'T-text' : 'T-subtitle'" class="ellipsis" style="display: table-cell; margin-top: 2px; white-space: normal; padding-bottom: 8px;" :title="itemIndex.name.replace(/<[^>]*>/g, '')" v-html="itemIndex.name" :style="itemIndex.parent_id !== null ? itemIndex.current ? '' : '' : 'color: #1B82C5; width: 100% !important;'"/>
                                                <template v-if="itemIndex.parent_id !== null && itemIndex.have_childrens">
                                                    <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                                        <img height="18" width="18" style="margin-top: 3px;" :src="itemIndex.active ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                    </div>
                                                </template>
                                                <template v-if="itemIndex.completed !== null">
                                                    <span class="T-small-text gh_margin-spaces" style="display: flex; white-space: nowrap; margin-top: 6px; margin-left: 10px;">{{itemIndex.completed + ' ' + $t('completed').toLowerCase()}}</span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <template v-for="(itemIndex, keyIndex) in index">
                                        <div :key="'INDEX' + keyIndex + refreshIndex"
                                             :style="{
                                                 display: itemIndex.grouper ? 'block' : 'inline-block',
                                                 paddingLeft: (itemIndex.level !== 0 ? (itemIndex.level * 25) + 'px' : '5px'),
                                                 marginRight: itemIndex.grouper ? '0' : '10px',
                                                 width: itemIndex.grouper ? '100%' : 'auto'
                                             }">
                                            <div style="display: flex; cursor: pointer; width: 100%;">
                                                <div style="display: flex; width: 100%;">
                                                    <template v-if="itemIndex.parent_id !== null">
                                                        <div class="gh-pointer" @click="checkQuestion(itemIndex); $emit('setQuestion', itemIndex, keyIndex, 'index')" :style="'color:' + block.pointer.colors[itemIndex.state]" :title="$t(block.pointer.title[itemIndex.state])">
                                                            ⬤
                                                        </div>
                                                    </template>
                                                    <span @click="checkQuestion(itemIndex); $emit('setQuestion', itemIndex, keyIndex, 'index')"
                                                          :class="itemIndex.parent_id !== null ? (itemIndex.current && keyIndex === current.key ? 'T-subtitle-second' : 'T-text') : 'T-subtitle'"
                                                          class="ellipsis"
                                                          style="display: table-cell; margin-top: 2px; white-space: normal; padding-bottom: 8px;"
                                                          :title="(itemIndex.prename !== '' ? itemIndex.prename + ' | ' : '') + itemIndex.name.replace(/<[^>]*>/g, '')"
                                                          v-html="itemIndex.prename !== '' ? itemIndex.prename : itemIndex.name"
                                                          :style="itemIndex.parent_id !== null ? (itemIndex.current ? '' : '') : 'color: #1B82C5; width: 100% !important;'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="gh-padding-index-questionnaire gh_position-map" v-if="showMap" :key="'MAP' + refreshMap" ref="mapContainer">
                    <slot name="extraContent"/>
                </div>
            </div>
        </div>
        <div class="gh-content-global-questionnaire" :style="dataBody.evaluator ? 'padding-top: 20px' : ''">
            <template v-if="!finishedQuestionnaireInitial && (dataBody.evaluator || dataBody.actions !== undefined && dataBody.actions.evaluate)">
                <div class="flex-direction-reverse">
                    <slot name="buttonEvaluator"/>
                </div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && dataBody !== undefined && dataBody.header_actions_visible !== undefined && dataBody.header_actions_visible.actions && (dataBody.actions.information || dataBody.actions.know_more)">
                <div class="flex-direction-reverse">
                    <slot name="bodyHeaderActions"/>
                </div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && dataBody !== undefined && dataBody.header_actions_visible !== undefined && dataBody.header_actions_visible.actions && (dataBody.is_structural || dataBody.direct)">
                <div class="separator-line"></div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && dataBody.is_structural && !showInfoAgrupator">
                <div style="width: 100%;" :style="(actualIndex !== undefined && !dataIndex.finish && firstQuest) || !dataIndex.finish ? '' : 'display: flex;'">
                    <template v-if="(dataBody.impartition && !allowImpartitionFinish && dataBody.screens !== undefined && !dataBody.screens['first']) || (actualIndex !== undefined && !dataIndex.finish && firstQuest) || !dataIndex.finish || dataIndex.finish && dataBody.screens !== undefined && !dataBody.screens['first']">
                        <div class="card-container" :style="dataBody !== undefined && dataBody.header_actions_visible !== undefined && dataBody.header_actions_visible.actions ? 'padding-top: 40px;' : 'padding-top: 10px;'">
                            <template v-for="(itemCard, keyCard) in dataBody.cards">
                                <div @click="cardUpdateBreadCrum(itemCard, keyCard);" :key="'KEY'+ keyCard">
                                    <GhCECard
                                        :key="'CARD' + keyCard"
                                        :block="{
                                            image: block.svg.noImg,
                                            progressBar: true,
                                            colors: [
                                                '#0099CC',
                                                '#005392'
                                            ],
                                            colorsHover: [
                                                '#B7C0C5',
                                                '#B7C0C5'
                                            ]
                                        }"
                                        :data="itemCard"
                                    />
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div style="padding-top: 20px; width: 25%;">
                            <template v-for="(itemCard, keyCard) in dataBody.cards">
                                <div @click="!firstQuest ? cardUpdateBreadCrum(itemCard, keyCard) : ''" :key="'KEY'+ keyCard">
                                    <GhCECard
                                        :key="'CARD' + keyCard"
                                        :block="{
                                            image: block.svg.noImg,
                                            progressBar: true,
                                            colors: [
                                                '#0099CC',
                                                '#005392'
                                            ],
                                            colorsHover: [
                                                '#B7C0C5',
                                                '#B7C0C5'
                                            ]
                                        }"
                                        :data="itemCard"
                                    />
                                </div>
                            </template>
                        </div>
                        <div style="padding-top: 40px; margin-left: 40px; width: 75%;">
                            <div class="gh-row-content">
                                <label class="T-subtitle color-light-blue" style="margin-right: 5px; line-height: 1.4;">{{$t('has_answered_questions_in_the_questionnaire') + ' ' + defaultIndex.parent.name }}</label>
                            </div>
                            <template v-if="firstQuest">
                                <div class="gh-row-content" style="padding-top: 15px;">
                                    <label class="T-subtitle-second">{{$t('press_finish')}}</label>
                                </div>
                                <div class="gh-row-content" style="padding-top: 15px;">
                                    <label class="T-subtitle-second">{{$t('text_finish_questionnaire')}}</label>
                                </div>
                            </template>
                            <template v-else>
                                <div class="gh-row-content" style="padding-top: 15px;">
                                    <label class="T-subtitle-second">{{$t('if_you_finish_press_finish')}}</label>
                                </div>
                                <div class="gh-row-content" style="padding-top: 15px;">
                                    <label class="T-subtitle-second">{{$t('second_if_you_finish_press_finish')}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="T-subtitle-second">{{$t('second_continue_if_you_finish_press_finish')}}</label>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
                <div v-if="actualIndex === undefined && dataIndex.finish && firstQuest">
                    <slot name="finishQuestionnaire"/>
                </div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && (showInfoAgrupator || dataBody.direct)">
                <div style="width: 100%; display: flex;">
                    <div style="padding-top: 40px; width: 25%;">
                        <GhCECard
                            :block="{
                                image: block.svg.noImg,
                                progressBar: false,
                                colors: [
                                    '#0099CC',
                                    '#005392'
                                ],
                                colorsHover: [
                                    '#B7C0C5',
                                    '#B7C0C5'
                                ]
                            }"
                            :data="{src: actualAgrupatorItem.src}"
                        />
                    </div>
                    <div style="padding-top: 40px; margin-left: 40px; width: 75%;" :style="actualAgrupatorItem.extra_info_statement.length === 0 ? 'padding-bottom: 15px;' : ''">
                        <div class="gh-row-content">
                            <label class="T-subtitle color-light-blue" style="margin-right: 5px; line-height: 1.4;" v-html="actualAgrupatorItem.name"/>
                        </div>
                        <div class="gh-row-content" style="padding-top: 15px;">
                            <label class="T-text" v-html="actualAgrupatorItem.description"/>
                        </div>
                    </div>
                </div>
                <template v-if="actualAgrupatorItem.extra_info_statement.length !== 0">
                    <div style="padding-top: 25px; padding-bottom: 15px;" :class="actualAgrupatorItem.extra_info_statement.length < 3 ? 'extra-info-statement-container-2' : 'extra-info-statement-container-3'">
                        <template v-for="(statement, keyStatement) in actualAgrupatorItem.extra_info_statement">
                            <div :key="'STATEMENT_' + keyStatement">
                                <div>
                                    <label class="T-subtitle" v-html="statement.statement"></label>
                                </div>
                                <div style="padding-top: 15px;">
                                    <label class="T-text" v-html="statement.sub_statement"></label>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <div class="centered">
                    <GhButton
                        :datainput="{
                            id: 'button_download_content_',
                            text: $t('next'),
                            class: 'T19 container-md_button_content button-questionnaire-primary',
                            icon: checkNext ? require('@/assets/gh_new_dessign/loader.gif') : ''
                        }"
                        :title="$t('next')"
                        :disabled="checkNext"
                        @click="$emit('setQuestion', actualAgrupatorItem, actualAgrupatorKey); checkNext = true"
                    />
                </div>
            </template>
            <template v-if="finishedQuestionnaireInitial">
                <slot name="slotFinishedQuestionnaireInitial"/>
            </template>
            <template v-if="!finishedQuestionnaireInitial && !dataBody.is_structural && dataBody.length !== 0 && dataBody.type !== undefined">
                <div class="question-body" :style="dataBody.header_actions_visible !== undefined && dataBody.header_actions_visible.actions ? 'margin-top: 20px;' : 'margin-top: 10px;'" :key="'QUESTIONNARY' + refreshContent">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.levels[dataBody.level > 5 ? 5 : dataBody.level]" alt="">
                        </div>
                        <div class="T-subtitle" v-html="dataBody.statement" style="margin-left: 10px; margin-top: 2px;"></div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <form id="form_result_questionnaire">
                        <div class="gh-answer-content">
                            <div class="gh-row-content" v-if="dataBody.description !== undefined && dataBody.description !== ''">
                                <label class="T-text" style="color: black; margin-right: 5px; line-height: 2;" v-html="dataBody.description"/>
                            </div>
                            <div class="gh-row-content" v-if="dataBody.real_statement !== undefined && dataBody.real_statement !== ''" :style="dataBody.description !== undefined && dataBody.description !== '' ? 'margin-top: 15px;' : ''">
                                <label class="T-subtitle color-light-blue" style="margin-right: 5px; line-height: 2;" v-html="dataBody.real_statement"/>
                            </div>
                            <div class="gh-row-content" v-if="dataBody.real_statement !== undefined && dataBody.real_statement !== '' && dataBody.substatement !== undefined && dataBody.substatement !== ''">
                                <label class="T-text" style="margin-right: 5px; line-height: 2; margin-top: -15px;" v-html="dataBody.substatement"/>
                            </div>
                            <template v-if="dataBody.type === 1">
                                <template v-if="dataBody.evaluator || dataBody.disabled || $root._route.params.view">
                                    <label class="gh_text_field-label T-text" v-html="dataBody.answer"/>
                                </template>
                                <template v-else>
                                    <GhTextarea
                                        :datalabel="{
                                            text: '',
                                            class: '',
                                            styles: '',
                                        }"
                                        :datainput="{
                                            id: 'answer',
                                            name: 'answer[answer]',
                                            required: false,
                                            value: dataBody.answer,
                                            class: 'padding-element-questionnary T-text extra-heigth-textarea',
                                            edited: true,
                                            image: {
                                                subscript: require('@/assets/img/text/subscript.svg'),
                                                superscript: require('@/assets/img/text/superscript.svg'),
                                                bold: require('@/assets/img/text/text-bold.svg'),
                                                italic: require('@/assets/img/text/text-italic.svg'),
                                                underline: require('@/assets/img/text/text-underline.svg')
                                            }
                                        }"
                                        ref="RefTextarea"
                                        @emiterTextarea="setTextarea()"
                                    />
                                </template>
                            </template>
                            <template v-if="dataBody.type === 2">
                                <template v-if="dataBody.type_answer === 1">
                                    <div>
                                        <GhCECheckSelectBox
                                            :block="{
                                                type: 'radio',
                                                name: 'answer[answer]'
                                            }"
                                            :disabled="dataBody.evaluator || dataBody.disabled || $root._route.params.view"
                                            :answers="dataBody.answers"
                                            :defaultSelected="dataBody.selected"
                                            ref="RefCheckSelectorBox"
                                            @input="checkSelectorBox"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="width: 100%;margin-top: 20px;">
                                        <GhCEInputRange
                                            :datainput="{
                                                id: 'answer',
                                                name: 'answer[answer]',
                                                value: dataBody.selected,
                                                min: dataBody.min,
                                                max: dataBody.max,
                                            }"
                                            :disabled="dataBody.evaluator || dataBody.disabled || $root._route.params.view"
                                            ref="RefSelectorRange"
                                            @input="checkSelectorRange"
                                        />
                                    </div>
                                </template>

                                <template v-if="!dataBody.evaluator">
                                    <template v-if="dataBody.actions.have_legend">
                                        <slot name="answerLegend"/>
                                    </template>
                                </template>
                            </template>
                            <template v-if="dataBody.type === 3 || dataBody.type === 4">
                                <div v-for="(answers, keyAnswers) in dataBody.answers" :key="'ANSWER' + keyAnswers" class="input-contorn-selector" style="margin-bottom: 10px;" @click="checkInput(keyAnswers)" :style="selecteds.includes(keyAnswers) ? 'border: 1px solid #1B82C5;' : ''">
                                    <GhChecks
                                        :datainput="{
                                            id: 'radio_and',
                                            value: keyAnswers,
                                            type: dataBody.type === 3 ? 'radio' : 'checkbox',
                                            name: dataBody.type === 3 ? 'answer[answer]' : 'answer[answer][]',
                                            selected: selecteds.includes(keyAnswers),
                                            disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                        }"
                                        :datalabel="{class: '', id: '', text: ''}"
                                        :ref="'RefCheck' + keyAnswers"
                                        @click="checkInput(keyAnswers)"
                                    />
                                    <label class="gh_text_field-label T-text" style="margin-left: 10px; cursor: pointer;">{{answers}}</label>
                                </div>
                            </template>
                            <template v-if="dataBody.type === 6">
                                <div style="padding-bottom: 10px;">
                                    <div class="double-bootom-border" style="display: flex;">
                                        <div style="width: 100%">
                                            {{'‎'}}
                                        </div>
                                        <template v-if="dataBody.type_answer !== 3 && dataBody.type_answer !== 4">
                                            <div style="width: 80px; text-align: center;">
                                                <label class="T-subtitle-second">{{dataBody.type_answer === 1 ? $t('yes') : $t('true')}}</label>
                                            </div>
                                            <div style="width: 80px; text-align: center;">
                                                <label class="T-subtitle-second">{{dataBody.type_answer === 1 ? $t('no') : $t('false')}}</label>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-for="(answers, keyAnswers) in dataBody.questions" :key="'ANSWER' + keyAnswers">
                                        <div class="gh-row-content" style="border-bottom: 1px solid #AFAFAF; padding-bottom: 4px; padding-top: 8px; width: 100%;">
                                            <div>
                                                <div style="width: 100%">
                                                    <label class="T-text">{{answers.name}}</label>
                                                </div>
                                                <template v-if="dataBody.type_answer !== 4">
                                                    <div class="centered" :class="dataBody.type_answer === 3 ? 'radio-check-ok' : 'radio-check-new-style'" style="width: 80px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_one_' + answers.id,
                                                                value: 1,
                                                                type: 'radio',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 1,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireOne' + answers.id"
                                                            @click="checkInputQuestionnaire(answers.id)"
                                                        />
                                                    </div>
                                                    <div class="centered" :class="dataBody.type_answer === 3 ? 'radio-check-ko' : 'radio-check-new-style'" style="width: 80px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_two_' + answers.id,
                                                                value: 2,
                                                                type: 'radio',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 2,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireTwo' + answers.id"
                                                            @click="checkInputQuestionnaire(answers.id)"
                                                        />
                                                    </div>
                                                </template>
                                                <template v-else-if="dataBody.type_answer === 4">
                                                    <div class="radio-check-new-style" style="width: 25px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_and',
                                                                value: 1,
                                                                type: 'checkbox',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 1,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireOnly' + answers.id"
                                                            @click="checkInputQuestionnaire( answers.id)"
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="dataBody.type === 7">
                                <template v-for="(item,index) in defaultBody.answers">
                                    <div :key="'BLOCK_ELEMENT_ANSWERS_' + index + refreshMultistatement" :style="index !== 0 ? 'margin-top: 15px;' : ''">
                                        <div style="display: flex;">
                                            <div style="width: 100%; display: flex;">
                                                <template v-if="dataBody.allow.more_elements">
                                                    <label @click="showHide(index)" class="T-subtitle color-light-blue" style="display: flex;width: fit-content;cursor: pointer;">
                                                        <img height="18" width="18" style="margin-right: 5px;margin-top: 3px;" :src="item.show ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                        {{$t('element') + (dataBody.evaluator || dataBody.disabled || $root._route.params.view ? ' ' + item.name : '')}}
                                                    </label>
                                                    <template v-if="!dataBody.evaluator && !dataBody.disabled && !$root._route.params.view">
                                                        <GhInputTextField
                                                            style="width: 100%; margin-left: 10px;"
                                                            :datalabel="{
                                                                text: '',
                                                                style: '',
                                                                class: ''
                                                            }"
                                                            :datainput="{
                                                                required: false,
                                                                id: 'answer_answer_' + index + '_name',
                                                                name: 'answer[answer][' + index + '][name]',
                                                                style: 'min-width: 400px; width: 50%',
                                                                value: item.name,
                                                                class: 'new-padding-style-input',
                                                                type: 'text'
                                                            }"
                                                            :ref="'RefAnswerName' + index"
                                                            @keyup="persistanceMultistatement('RefAnswerName', index)"
                                                        />
                                                    </template>
                                                    <template v-if="!dataBody.evaluator && !dataBody.disabled && !$root._route.params.view && defaultBody.answers.length !== 1">
                                                        <GhAction
                                                            class="color-light-blue T-subtitle-second underline-light-blue"
                                                            :dataction="{
                                                                id: 'remove_element_' + index,
                                                                text: $t('delete')
                                                            }"
                                                            @click="deleteElement(index)"
                                                        />
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="item.children.length !== 0">
                                        <template v-for="(answer, keyAnswer) in item.children">
                                            <div :key="'BLOCK_ANSWER_LABEL_' + index + keyAnswer + refreshMultistatement" v-show="!dataBody.allow.more_elements || item.show" style="margin-top: 10px; margin-left: 23px;">
                                                <div style="display: flex;">
                                                    <div style="width: 100%; display: flex;">
                                                        <label @click="showHide(index, keyAnswer)" class="T-subtitle color-light-blue" style="display: flex;width: fit-content;cursor: pointer;">
                                                            {{answer.text}}
                                                            <img height="18" width="18" style="margin-left: 5px;margin-top: 3px;" :src="answer.show ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                            <label class="T-small-text gh_margin-spaces" style="white-space: nowrap; margin-top: 4px; margin-left: 5px;" :style="answer.max_word !== '' && answer.max_word < answer.words ? 'color: red' : answer.words === 0 ? '' : 'color: green'">{{(answer.words === 0 ? $t('to_complete') : ($t('complete_with') + ' ' + answer.words + ' ' + $t('words').toLowerCase())) + (answer.max_word !== '' && answer.max_word < answer.words ? ' (' + answer.max_word + ' ' + $t('max_words') + ')' : '')}}</label>
                                                        </label>
                                                    </div>
                                                    <template v-if="answer.indication">
                                                        <div style="width: 200px; text-align-last: right; margin-top: 1px;">
                                                            <GhAction
                                                                style="margin-top: 2px;"
                                                                class="color-light-blue T-subtitle-second underline-light-blue"
                                                                :dataction="{
                                                                    id: 'action_indications_' + index + keyAnswer,
                                                                    text: $t('indications')
                                                                }"
                                                                @click="indications(item, answer)"
                                                            />
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                            <div :key="'BLOCK_ANSWER_' + index + keyAnswer + refreshMultistatement" v-show="(!dataBody.allow.more_elements && answer.show) || (item.show && answer.show)" style="margin-top: 10px;">
                                                <template v-if="dataBody.evaluator || dataBody.disabled || $root._route.params.view">
                                                    <label class="gh_text_field-label T-text" style="margin-left: 23px; display: block;" v-html="answer.answer"/>
                                                </template>
                                                <template v-else>
                                                    <GhTextarea
                                                        style="margin-left: 23px;"
                                                        :datalabel="{
                                                            text: '',
                                                            class: '',
                                                            styles: '',
                                                        }"
                                                        :datainput="{
                                                            id: 'answer_answer_' + index + '_answers_' + keyAnswer,
                                                            name: 'answer[answer][' + index + '][answers][' + keyAnswer + ']',
                                                            required: false,
                                                            value: answer.answer,
                                                            class: 'padding-element-questionnary T-text extra-heigth-textarea ' + (answer.max_word !== '' && answer.max_word < answer.words ? 'shadow-textarea' : ''),
                                                            edited: true,
                                                            image: {
                                                                subscript: require('@/assets/img/text/subscript.svg'),
                                                                superscript: require('@/assets/img/text/superscript.svg'),
                                                                bold: require('@/assets/img/text/text-bold.svg'),
                                                                italic: require('@/assets/img/text/text-italic.svg'),
                                                                underline: require('@/assets/img/text/text-underline.svg')
                                                            }
                                                        }"
                                                        :tolerance="answer.tolerance"
                                                        :maxlength="answer.tolerance !== '' && answer.words >= answer.tolerance ? 1 : 50000"
                                                        :ref="'RefTextarea' + index + keyAnswer"
                                                        @emiterTextarea="afterPaste(index, keyAnswer, answer);"
                                                    />
                                                </template>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-if="item.reference !== undefined && item.reference !== null && Object.keys(item.reference).length !== 0">
                                        <div :key="'BLOCK_REFERENCE_LABEL_' + index  + refreshMultistatement" v-show="!dataBody.allow.more_elements || item.show" style="margin-top: 10px; margin-left: 23px;">
                                            <div style="display: flex;">
                                                <div style="width: 100%; display: flex;">
                                                    <label @click="showHideReference(index)" class="T-subtitle color-light-blue" style="display: flex;width: fit-content;cursor: pointer;">
                                                        {{item.reference.statement}}
                                                        <img height="18" width="18" style="margin-left: 5px;margin-top: 3px;" :src="item.reference.show ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                        <label class="T-small-text gh_margin-spaces" style="white-space: nowrap; margin-top: 4px; margin-left: 5px;">{{item.reference.selecteds !== undefined && item.reference.selecteds !== null && Object.keys(item.reference.selecteds).length !== 0 ? $t('completed') : $t('to_complete')}}</label>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div :key="'BLOCK_REFERENCE_' + index + refreshMultistatement" v-show="(!dataBody.allow.more_elements && item.reference.show) || item.reference.show" style="margin-top: 10px;">
                                            <div v-for="(references, keyReferences) in item.reference.reference" :key="'ANSWER_REFERENCE_' + index + keyReferences" class="input-contorn-selector" style="margin-bottom: 10px; margin-left: 23px;" @click="checkInputMultistatements(keyReferences, index)" :style="item.reference.selecteds.includes(keyReferences) ? 'border: 1px solid #1B82C5;' : ''">
                                                <GhChecks
                                                    :datainput="{
                                                        id: 'reference_check_',
                                                        value: keyReferences,
                                                        type: 'checkbox',
                                                        name: 'answer[answer][' + index + '][reference][]',
                                                        selected: item.reference.selecteds.includes(keyReferences),
                                                        disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                    }"
                                                    :datalabel="{class: '', id: '', text: ''}"
                                                    :ref="'RefCheck' + index + keyReferences"
                                                    @click="checkInputMultistatements(keyReferences, index)"
                                                />
                                                <label class="gh_text_field-label T-text" style="margin-left: 10px; cursor: pointer;">{{references}}</label>
                                            </div>
                                        </div>
                                    </template>
                                    <div :key="'BLOCK_ELEMENT_EXTRA_' + index + refreshMultistatement" style="display: flex;">
                                        <template v-if="dataBody.evidence">
                                            <div class="container-evidences" :class="item.image !== '' && item.image !== undefined ? 'container-evidences-image' : ''" :key="'BLOCK_ELEMENT_ATTACH_' + index" v-show="!dataBody.allow.more_elements || item.show" style="margin-left: 23px; width: 49%" :style="dataBody.allow.associate ? 'width: 49%' : 'width: 100%'">
                                                <template v-if="dataBody.after_download !== '' && (item.image === '' || item.image === undefined)">
                                                    <label class="gh_text_field-label T-text" style="color: black; line-height: 2;" v-html="dataBody.after_download"></label>
                                                </template>
                                                <div v-if="item.image !== '' && item.image !== undefined" style="width: 168px; margin-top: 10px;">
                                                    <img :src="item.image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
                                                </div>
                                                <GhCEMultiUpload
                                                    :block="{
                                                        id: 'answer_answer_' + index + '_evidence',
                                                        text: 'upload_document',
                                                        class: 'T19 container-md_button_content button-questionnaire-primary'
                                                    }"
                                                    :datainput="{
                                                        dispatch: block.evidence.dispatch,
                                                        accept: dataBody.type_attach === 2 ? '.png, .jpg' : '.pdf, .xls, .xlsx, .doc, .docx, .odt, .txt',
                                                        name: 'answer[answer][' + index + '][evidence]',
                                                        remove: block.evidence.remove,
                                                        removeText: block.evidence.removeText,
                                                        removeSvg: block.evidence.removeSvg,
                                                        lupaSvg: block.evidence.lupaSvg
                                                    }"
                                                    :disabled="dataBody.evaluator || dataBody.disabled || $root._route.params.view"
                                                    :dataFiles="item.files"
                                                    :onlyOneFile="dataBody.type_attach === 2"
                                                    :actualIndex="index"
                                                    :showImage="item.image"
                                                    :hideButtons="dataBody.type_attach === 2"
                                                    :imageAccept="dataBody.type_attach === 2"
                                                    :ref="'RefEvidence' + index"
                                                    @evidenceLoad="checkEvidenceLoad"
                                                    @evidenceResponse="loadImage"
                                                    @evidenceRemove="removeImage"
                                                ></GhCEMultiUpload>
                                            </div>
                                        </template>
                                        <template v-if="dataBody.allow.associate">
                                            <div class="container-evidences" :key="'BLOCK_ELEMENT_ASSOCIATED_' + index" v-show="!dataBody.allow.more_elements || item.show" style="margin-left: 23px; padding-top: 13px;" :style="dataBody.evidence ? 'width: 51%' : 'width: 100%'">
                                                <template v-if="!dataBody.evaluator && !dataBody.disabled && !$root._route.params.view">
                                                    <GhAction
                                                        class="color-light-blue T-subtitle-second underline-light-blue"
                                                        :dataction="{
                                                            id: 'action_associated_with_' + index,
                                                            text: $t('associated_with')
                                                        }"
                                                        @click="Global.openPopUp('label_answer_answer_' + index + '_associated_with-Popup')"
                                                    />
                                                    <GhTreeSelectorBlock
                                                        :dataBlock="{
                                                            type: 'checkbox',
                                                            id: 'label_answer_answer_' + index + '_associated_with',
                                                            label: $t('associated_with'),
                                                            title: $t('associated_with'),
                                                            text: $t('select_associated_with') + ':',
                                                            name: 'answer[answer][' + index + '][associated_with][]',
                                                            id_value: 'id',
                                                            name_label: 'name',
                                                            child_key: 'children',
                                                            required: false,
                                                            checkparentdata: true,
                                                            style: 'display:none;',
                                                            label_style: 'width: 149px',
                                                            popupStyle: 'min-width: 900px;width: 55%;'
                                                        }"
                                                        :selected_input="item.associated_with"
                                                        :items="feedIndex"
                                                        :ref="'RefAssociatedWith' + index"
                                                        @accept="setAssociatedWith(index)"
                                                    />
                                                </template>
                                                <div style="margin-top: 10px;" v-if="item.associated_with !== undefined && item.associated_with.length !== 0" :key="'REFRESH_ASSOCIATED_' + index + refreshAssociatedWith">
                                                    <div v-for="(associated, keyAssociated) in item.associated_with" :key="'ASSOCIATED_' + keyAssociated" :style="keyAssociated % 2 === 0 ? 'background-color: #e1f1fd' : ''">
                                                        <label class="gh_text_field-label T-text" style="color: black; line-height: 2; margin-left: 5px;">{{associated.name}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>

                                <template v-if="dataBody.allow.more_elements && !dataBody.evaluator && !dataBody.disabled && !$root._route.params.view">
                                    <div class="separator-line"></div>
                                    <div style="margin-top: 10px;">
                                        <GhAction
                                            style="margin-left:3px;"
                                            class="color-light-blue T-subtitle-action underline-light-blue"
                                            :dataction="{
                                                id: 'action_add_element',
                                                text: $t('add_element'),
                                                icon: block.svg.add
                                            }"
                                            @click="addElement"
                                        />
                                    </div>
                                </template>
                            </template>
                            <template v-if="dataBody.type === 8">
                                <GhCENumericQuestionnaire
                                    :data="dataBody.answers"
                                    :dataBlock="{
                                        name: 'answer[answer]',
                                        selected: dataBody.selected,
                                        disabled: dataBody.disabled
                                    }"
                                    ref="RefNumericQuestionnaire"
                                    @emiter="checkAllowFinishNumericQuestionnaire"
                                />
                            </template>

                            <template v-if="dataBody.evidence && !dataBody.evaluator && dataBody.type !== 7">
                                <div class="container-evidences">
                                    <div style="width: 100%; display: flex">
                                        <label class="T-text" style="color: black; margin-right: 5px; line-height: 2;" v-html="dataBody.before_download"></label>
                                        <a :href="dataBody.template" v-if="dataBody.before_download !== undefined && dataBody.before_download !== ''">
                                            <GhButton
                                                class="button-questionnaire-secondary"
                                                :datainput="{
                                                    id: 'btn_download_evidence',
                                                    text: $t('download'),
                                                    style: 'display: unset !important'
                                                }"
                                            />
                                        </a>
                                        <label class="gh_text_field-label T-text" style="color: black; line-height: 2;" v-html="dataBody.after_download"></label>
                                        <GhCEMultiUpload
                                            :block="{
                                                id: 'evidence',
                                                text: 'upload_document',
                                                class: 'T19 container-md_button_content button-questionnaire-primary'
                                            }"
                                            :datainput="{
                                                dispatch: block.evidence.dispatch,
                                                accept: block.evidence.accept,
                                                name: 'answer[evidence]',
                                                remove: block.evidence.remove,
                                                removeText: block.evidence.removeText,
                                                removeSvg: block.evidence.removeSvg
                                            }"
                                            :disabled="dataBody.disabled || $root._route.params.view"
                                            :dataFiles="dataBody.files"
                                            ref="RefEvidence"
                                            @evidenceLoad="checkEvidenceLoad"
                                        ></GhCEMultiUpload>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </form>

                    <div class="centered" :style="dataBody.evidence && !dataBody.evaluator ? 'margin-top: 10px' : ''" v-if="dataBody.impartition === 1 || dataBody.validator || !dataBody.evaluator && !dataBody.disabled && !$root._route.params.view">
                        <template v-if="!dataBody.is_agrupator || dataBody.is_agrupator === undefined">
                            <template v-if="dataBody.disabled && dataBody.validator">
                            </template>
                            <template v-else>
                                <GhButton
                                    style="margin-bottom: 20px; margin-top: -10px;"
                                    class="button-questionnaire-secondary"
                                    :datainput="{
                                        id: 'btn_save_questionnaire',
                                        text: $t('save')
                                    }"
                                    :title="disabledSave ? $t('to_save_need_action') : $t('save')"
                                    :disabled="checkNext ? checkNext : disabledSave"
                                    @click="$emit('setSave', dataBody.id, dataBody.is_autoevaluation, dataBody.is_proposed)"
                                />
                            </template>
                        </template>
                        <GhButton
                            style="margin-bottom: 20px; margin-top: -10px;"
                            :datainput="{
                                id: 'button_download_content_',
                                text: $t(dataBody.disabled && dataBody.validator ? $t('next') : dataBody.validator ? 'validate' : 'next'),
                                class: 'T19 container-md_button_content button-questionnaire-primary',
                                icon: checkNext ? require('@/assets/gh_new_dessign/loader.gif') : ''
                            }"
                            :title="dataBody.disabled && dataBody.validator ? $t('next') : dataBody.is_agrupator ? $t('next') : (disabledFinish ? $t('to_finish_need_all_requireds') : $t(dataBody.validator ? 'validate' : 'next'))"
                            :disabled="dataBody.disabled && dataBody.validator ? false : dataBody.is_agrupator ? false : checkNext ? checkNext : disabledFinish"
                            @click="goParent = 0;$emit('setFinish', dataBody.id, dataBody.is_autoevaluation, dataBody.is_proposed, dataBody.validator); checkNext = true"
                        />
                    </div>

                    <template v-if="!dataBody.impartition">
                        <div class="centered" :style="dataBody.evaluator ? 'margin-top: 10px' : ''" v-if="dataBody.evaluator">
                            <GhButton
                                style="margin-bottom: 20px; margin-top: -10px;"
                                :datainput="{
                                    id: 'button_download_content_',
                                    text: $t('next'),
                                    class: 'T19 container-md_button_content button-questionnaire-primary',
                                    icon: checkNext ? require('@/assets/gh_new_dessign/loader.gif') : ''
                                }"
                                :title="$t('next')"
                                @click="goParent = 0;$emit('setFinish', dataBody.id, dataBody.is_autoevaluation, dataBody.is_proposed, dataBody.validator); checkNext = true"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && dataMaterials.length !== 0">
                <div class="question-body" :key="'MATERIALS' + refreshContent" :style="dataBody.is_structural ? 'margin-top:40px;' : ''">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.material" alt="">
                        </div>
                        <div style="margin-left: 10px; margin-top: 2px;">{{$t('support_material')}}</div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <div class="gh-answer-content gh-answer-content2">
                        <div v-for="keyRowMaterial in Math.ceil(dataMaterials.length / 4)" class="row-material" :key="'MATERIAL_ROW' + keyRowMaterial">
                            <template v-for="columnIndex in 4">
                                <div
                                    :key="columnIndex"
                                    class="column-material"
                                    v-if="dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)] !== undefined"
                                    @click="$emit('getMaterial', dataBody.id !== null ? dataBody.id : grouperMaterial, dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].slug)"
                                >
                                    <div class="gh-row-content T-subtitle-second">
                                        {{ dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].name }}
                                    </div>
                                    <div class="gh-row-content T-text">
                                        {{ dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].description }}
                                    </div>
                                </div>
                                <div :key="columnIndex" v-if="dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)] === undefined"></div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!finishedQuestionnaireInitial && dataBody.evidence && dataBody.evaluator && dataBody.type !== 7">
                <div class="question-body" :key="'EVIDENCES' + refreshContent">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.material" alt="">
                        </div>
                        <div style="margin-left: 10px; margin-top: 2px;">{{$t('evidence')}}</div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <div class="gh-answer-content gh-answer-content2" style="display: flex;">
                        <div class="card-container-evidence" style="width: 100%">
                            <div v-for="(answers, keyAnswers) in dataBody.files" class="row-material" :key="'EVIDENCE_ROW' + keyAnswers" style="display: grid">
                                <div style="display: flex">
                                    <div class="ellipsis" style="width: 40%; padding-right: 10px;">
                                        <label class="gh_text_field-label T-subtitle-second ellipsis">{{answers.file_name}}</label>
                                    </div>
                                    <div style="width: 60%">
                                        <GhAction
                                            class="color-light-blue T-subtitle-second underline-light-blue"
                                            :dataction="{
                                                id: 'action_download_file_' + keyAnswers,
                                                text: $t('download_file'),
                                                icon: block.svg.download,
                                                href: answers.file_download
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_show_image',
                title: $t('image'),
                type: 'info',
                style: 'font-size: 20px; overflow: unset !important;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: ' overflow: unset !important;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <img :src="imageShow" height="390" alt="">
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_confirm',
                        text: $t('close'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="Global.closePopUp('popup_show_image');"
                />
            </template>
        </GhPopUp>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_advertisment_finish_questionnaire',
                title: $t('questionnaire_ready_finish'),
                type: 'info',
                style: 'font-size: 20px; overflow-y: auto !important; width: 500px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                    <div class="T15_b" style="padding-left: 20px; padding-right: 20px;">
                        {{$t('congratulations_questionnaire') + ' \'' + (dataIndex.parent !== undefined ? dataIndex.parent.name : '') + '\' ' + $t('finalize_correctly')}}
                    </div>
                    <div class="T15_b" style="padding-left: 20px; padding-right: 20px; margin-top: 10px;">
                        <template v-if="dataIndex.finish && dataBody.screens !== undefined && !dataBody.screens['first']">
                            {{$t('finish_auto_end_date')}}
                        </template>
                        <template v-else>
                            {{$t('finalize_correctly_second')}}
                        </template>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_confirm',
                        text: $t('close'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="Global.closePopUp('popup_advertisment_finish_questionnaire');"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhCECard from "@/components/GhCECard.vue";
    import GhTextarea from "@/components/GhTextarea.vue";
    import GhChecks from "fe-gh-checks-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCECheckSelectBox from "@/components/GhCECheckSelectBox.vue";
    import GhCEInputRange from "@/components/GhCEInputRange.vue";
    import GhCEMultiUpload from "@/components/GhCEMultiUpload.vue";
    import GhCENumericQuestionnaire from "@/components/GhCENumericQuestionnaire.vue";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'gh_ce_questionnaire_constructor',
        props: ['dataIndex', 'dataBody', 'dataMaterials', 'nextQuestionnary', 'block', 'feedIndex', 'firstQuest', 'actualIndex', 'finishedQuestionnaireInitial', 'allowImpartitionFinish'],
        components: {
            GhCECard,
            GhTextarea,
            GhChecks,
            GhInputTextField,
            GhCECheckSelectBox,
            GhCEInputRange,
            GhCEMultiUpload,
            GhCENumericQuestionnaire,
            GhTreeSelectorBlock,
            GhPopUp,
            GhButton,
            GhAction
        },
        data(){
            return {
                goParent: 0,
                counterAlertFinish: 0,
                defaultIndex: this.dataIndex,
                defaultBody: this.dataBody,
                tabs : [],
                index: [],
                showIndex: false,
                showMap: false,
                current: {
                    item: [],
                    key: null
                },
                selecteds: [],
                afterChanges: [],
                refreshContent: 0,
                refreshIndex: 0,
                refreshMap: 0,
                titleBreadCrum: '',
                disabledSave: true,
                disabledFinish: true,
                activePopup: false,
                refreshAssociatedWith: 0,
                refreshMultistatement: 0,
                showInfoAgrupator: 0,
                actualAgrupatorItem: [],
                actualAgrupatorKey: null,
                checkNext: false,
                showTypeIndex: true,
                imageShow: '',
                grouperMaterial: ''
            }
        },
        mounted(){
            this.showTypeIndex = localStorage.getItem('showTypeIndex') !== undefined && localStorage.getItem('showTypeIndex') !== null ? localStorage.getItem('showTypeIndex') === 'false' || localStorage.getItem('showTypeIndex') === false ? false : true : true;

            this.tabs.push({
                id: this.defaultIndex.parent.id,
                name: this.defaultIndex.parent.name
            });

            if(this.dataBody.direct){
                this.showInfoAgrupator = true;
                this.actualAgrupatorItem = this.dataBody;
                this.actualAgrupatorKey = null;
            }

            this.titleBreadCrum = this.defaultIndex.parent.name;

            if(this.dataBody.screens !== undefined && this.dataBody.screens.last_item && this.counterAlertFinish === 0 && this.dataIndex.finish && !this.firstQuest) {
                this.$toast(this.$t('congratulations_questionnaire') + ' \'' + (this.dataIndex.parent !== undefined ? this.dataIndex.parent.name.trim() : '') + '\' ' + this.$t('finalize_correctly') + "\n\n" +
                    (this.dataIndex.finish && this.dataBody.screens !== undefined && !this.dataBody.screens['first'] ? this.$t('finish_auto_end_date') : this.$t('finalize_correctly_second')) + '.', {
                    position: "bottom-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

                this.counterAlertFinish++;
            }

            document.addEventListener('click', this.handleClickOutside);
            document.addEventListener('click', this.handleClickOutsideMap);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
            document.removeEventListener('click', this.handleClickOutsideMap);
        },
        watch: {
            'dataBody'(data){
                this.checkNext = false;
                if(data.type !== null && data.type !== undefined) {
                    let temp_selected = '';
                    if(data.type === 1){
                        temp_selected = data.answer;
                        this.disabledSave = data.answer === '';
                    }

                    if(data.type === 2){
                        temp_selected = data.selected;
                        this.disabledSave = data.selected === '';
                    }

                    if(data.type === 3 || data.type === 4) {
                        this.selecteds = data.selecteds;
                        temp_selected = data.selecteds;
                        this.disabledSave = this.selecteds.length === 0;
                    }

                    if(data.type === 5){
                        this.disabledSave = false;
                    }

                    if(data.type === 6){
                        let selecteds = [];

                        Object.entries(data.selecteds).forEach((i) => {
                            selecteds['ID' + i[0]] = i[1];
                        });

                        this.afterChanges = selecteds;
                        this.selecteds = selecteds;
                        temp_selected = selecteds;
                        this.disabledSave = Object.entries(this.selecteds).length === 0;
                    }

                    if(data.type === 7){
                        this.disabledSave = true;
                        this.checkAllowFinishMultiStatement();
                    }

                    if(data.type === 8){
                        this.disabledSave = true;
                    }

                    if(data.direct){
                        this.showInfoAgrupator = true;
                        this.actualAgrupatorItem = data;
                        this.actualAgrupatorKey = null;
                    }

                    if(data.type !== 7 && data.type !== 8) {
                        if (data.evidence_required) {
                            if (temp_selected.length === 0 && data.type !== 5) {
                                this.disabledFinish = true
                            } else {
                                this.disabledFinish = data.files.length === 0;
                            }
                        } else {
                            this.disabledFinish = this.disabledSave;
                        }
                    }else if(data.type === 8){
                        this.disabledFinish = !(data.selected.answer !== null && data.selected.value !== null);
                    }

                    this.refreshContent++;
                }

                if(data.screens !== undefined) {
                    if (data.screens.last_item && this.firstQuest || data.screens.last_item) {
                        if (this.counterAlertFinish === 0 && !this.firstQuest) {
                            this.$toast(this.$t('congratulations_questionnaire') + ' \'' + (this.dataIndex.parent !== undefined ? this.dataIndex.parent.name.trim() : '') + '\' ' + this.$t('finalize_correctly') + "\n\n" +
                                (this.dataIndex.finish && this.dataBody.screens !== undefined && !this.dataBody.screens['first'] ? this.$t('finish_auto_end_date') : this.$t('finalize_correctly_second')) + '.', {
                                position: "bottom-right",
                                timeout: 5000,
                                closeOnClick: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false
                            });

                            this.counterAlertFinish++;
                        }

                        if (data.screens.first && this.goParent === 0) {
                            this.goParent++;
                            this.openParent();
                        }
                    }
                }

                if(this.index.find((i) => i.id === data.id) !== undefined){
                    this.generateBreadCrum(this.index.find((i) => i.id === data.id));
                }

                if(data.id === null && data.cards !== undefined){
                    this.grouperMaterial = '';
                    data.cards.forEach((i) => {
                        this.grouperMaterial += i.id + '&';
                    });
                    this.grouperMaterial = this.grouperMaterial.substring(0, this.grouperMaterial.length - 1);
                }
            },
            'dataIndex'(data){
                this.defaultIndex = data;
                this.index = this.defaultIndex.childrens;

                if(this.index !== undefined) {
                    if (this.nextQuestionnary.length !== 0) {
                        this.index.forEach((i) => {
                            if (i.id === this.nextQuestionnary.id) {
                                this.current.item = i;
                            }
                        });

                        const currentItem = this.index.find(item => item.id === this.current.item.id);

                        if (currentItem) {
                            this.currentItem(currentItem);
                        }
                    }

                    const index = this.index.findIndex(item => item.id === this.current.item.id);

                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                        this.$set(this.index[index], 'current', true);
                        this.current.key = index;
                    }

                    if (this.nextQuestionnary.length !== 0) {
                        this.$emit('watcherFinish', this.current.item);
                        this.$emit('resetNextQuestionnaire');
                        this.refreshContent++;
                    }
                }

                this.refreshIndex++;
            }
        },
        methods: {
            indications(item, answerItem){
                this.Global.windowOpen(this.Global.openSecondWindow('indications',{
                    id: this.dataBody.id,
                    id_statement: answerItem.id,
                }),'indications');
            },
            countWords(index, keyAnswer) {
                var text = this.$refs['RefTextarea' + index + keyAnswer][0]['hiddenValue'];
                var words = text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').replace(/\n/g, ' ').trim().split(/\s+/).filter(i => i !== '');
                this.defaultBody.answers[index].children[keyAnswer].words = words.length;
            },
            showHide(index, answerIndex){
                if(answerIndex !== undefined){
                    this.defaultBody.answers[index].children[answerIndex].show = !this.defaultBody.answers[index].children[answerIndex].show;
                }else {
                    this.defaultBody.answers[index].show = !this.defaultBody.answers[index].show;
                }
            },
            showHideReference(index){
                this.defaultBody.answers[index].reference.show = !this.defaultBody.answers[index].reference.show;
            },
            openParent(){
                this.index.forEach((i, k) => {
                    this.$set(this.index[k], 'show', false);
                    this.$set(this.index[k], 'active', false);
                    this.$set(this.index[k], 'current', false);
                });

                this.current = {
                    item: [],
                    key: null
                };

                this.$emit('checkParent');

                this.showInfoAgrupator = false;
                this.actualAgrupatorItem = [];
                this.actualAgrupatorKey = null;

                this.closeBreadCrumbIndex();
            },
            openBreadCrumbIndex(){
                this.index = this.defaultIndex.childrens;

                if (this.current.key !== null) {
                    const currentItem = this.index.find(item => item.id === this.current.item.id);

                    if (currentItem) {
                        this.currentItem(currentItem);

                        this.$nextTick(() => {
                            this.showIndex = true;
                        });
                    }
                } else {
                    this.showIndex = true;
                }
            },
            closeBreadCrumbIndex(){
                this.showIndex = false;
            },
            closeMap(){
                this.showMap = false;
            },
            currentItem(currentItem){
                const parentIds = this.getAllParentIds(currentItem);
                const relatedIds = [...parentIds, currentItem.id];

                relatedIds.forEach(id => {
                    const index = this.index.findIndex(item => item.id === id);
                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                    }
                });

                parentIds.forEach(parentId => {
                    const children = this.findAllChildren(parentId);
                    children.forEach(childId => {
                        const childIndex = this.index.findIndex(item => item.id === childId);
                        if (childIndex !== -1) {
                            this.$set(this.index[childIndex], 'show', true);
                        }
                    });
                });

                this.index.forEach((item, index) => {
                    if (!relatedIds.includes(item.id) && !parentIds.includes(item.parent_id)) {
                        this.$set(this.index[index], 'show', false);
                        this.$set(this.index[index], 'active', false);
                    }
                });

                if(this.current.key === null){
                    this.defaultIndex = this.$props.dataIndex;
                    this.index = this.defaultIndex.childrens;

                    const index = this.index.findIndex(item => item.id === this.current.item.id);

                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                        this.$set(this.index[index], 'current', true);
                        this.current.key = index;
                    }
                }

                this.$set(this.index[this.current.key], 'active', false);
            },
            handleClickOutside(event) {
                if(event.srcElement._prevClass !== 'bread-crumb color-light-blue T-header') {
                    const indexContainer = this.$refs.indexContainer;
                    if (indexContainer && !indexContainer.contains(event.target)) {
                        this.showIndex = false;
                    }
                }
            },
            handleClickOutsideMap(event) {
                if(event.srcElement.closest('.gh-padding-index-questionnaire') === null && event.srcElement.parentNode !== null){
                    this.activePopup = false;
                }

                if(event.srcElement.closest('#popup_evaluate') !== null || event.srcElement.closest('#popup_advice') !== null){
                    this.activePopup = true;
                }

                if(!this.activePopup && event.srcElement.parentNode !== null) {
                    this.activePopup = event.srcElement._prevClass === 'T15_action' || (event.srcElement.parentNode.id !== undefined && event.srcElement.parentNode.id.includes('list_chart_global_map_'));
                }

                if(!this.activePopup && event.srcElement.parentNode !== null) {
                    const mapContainer = this.$refs.mapContainer;
                    if (mapContainer && !mapContainer.contains(event.target)) {
                        this.showMap = false;
                    }
                }
            },
            showOrHideIndex(currentItem, currentIndex) {
                const currentLevel = currentItem.level;
                const isActive = !this.index[currentIndex].active;

                this.$set(this.index[currentIndex], 'active', isActive);

                if (currentLevel === 0) {
                    this.index.forEach((item, index) => {
                        if (item.level === 1 && item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                        }
                    });

                    if (!isActive) {
                        this.hideAllDescendants(currentItem.id);
                    }
                } else if (currentLevel !== 0) {
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                            if (!isActive) {
                                this.hideAllDescendants(item.id);
                            }
                        }
                    });
                }
            },
            hideAllDescendants(parentId) {
                const stack = [parentId];
                while (stack.length > 0) {
                    const currentId = stack.pop();
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentId) {
                            this.$set(this.index[index], 'show', false);
                            this.$set(this.index[index], 'active', false);
                            stack.push(item.id);
                        }
                    });
                }
            },
            checkQuestion(currentItem){
                this.index.forEach((item, index) => {
                    let isCurrent = item.id === currentItem.id;

                    this.$set(this.index[index], 'current', isCurrent);

                    if(isCurrent){
                        this.current.item = this.index[index];
                        this.current.key = index;
                    }
                });

                this.generateBreadCrum(currentItem);

                this.showInfoAgrupator = false;
                this.actualAgrupatorItem = [];
                this.actualAgrupatorKey = null;

                let than = this;
                setTimeout(function(){
                    than.showIndex = false;
                },100);
            },
            eliminarEtiquetasHTML(cadena) {
                const elemento = document.createElement('div');
                elemento.innerHTML = cadena;
                const textoLimpio = elemento.textContent || elemento.innerText || '';
                elemento.remove();
                return textoLimpio;
            },
            resetBreadCrum(){
                this.tabs = [];
                this.tabs.push({
                    id: this.defaultIndex.parent.id,
                    name: this.defaultIndex.parent.name
                });
            },
            generateBreadCrum(item) {
                this.resetBreadCrum();

                var bread_crum = [];
                let current = item;
                if(current.grouper){
                    bread_crum.push({
                        id: item.id,
                        name: this.eliminarEtiquetasHTML(item.name)
                    });
                }

                while (current.level !== 0) {
                    const parent = this.index.find(parentItem => parentItem.id === current.parent_id);
                    if (parent) {
                        if(parent.grouper){
                            bread_crum.push({
                                id: parent.id,
                                name: this.eliminarEtiquetasHTML(parent.name)
                            });
                        }
                        current = parent;
                    } else {
                        break;
                    }
                }

                if(bread_crum.length !== 0){
                    bread_crum = bread_crum.reverse();

                    bread_crum.forEach((e,k) => {
                        if(k === 0) this.tabs.push(e);
                    });
                }
                this.generateTitleBreadCrum(bread_crum);
            },
            resetTitleBreadCrum(){
                this.titleBreadCrum = this.defaultIndex.parent.name;
            },
            generateTitleBreadCrum(toTitle){
                this.resetTitleBreadCrum();

                toTitle.forEach((e,k) => {
                    if(k === 0) this.titleBreadCrum += ' > ' + e.name;
                });
            },
            cardUpdateBreadCrum(item, key){
                if(item.id !== null){
                    if(this.index.length === 0) this.index = this.defaultIndex.childrens;
                    var actual = this.index.find(e => e.id === item.id);
                    item['grouper'] = actual['grouper'];
                    item['level'] = actual['level'];
                    item['parent_id'] = actual['parent_id'];
                    this.generateBreadCrum(item);
                }

                if(item.is_agrupator !== undefined && item.is_agrupator){
                    this.showInfoAgrupator = true;
                    this.actualAgrupatorItem = item;
                    this.actualAgrupatorKey = key;
                }
            },
            getAllParentIds(item) {
                let parentIds = [];
                let current = item;
                while (current.level !== 0) {
                    const parent = this.index.find(parentItem => parentItem.id === current.parent_id);
                    if (parent) {
                        parentIds.unshift(parent.id);
                        current = parent;
                    } else {
                        break;
                    }
                }
                return parentIds;
            },
            findAllChildren(parentId) {
                return this.index.filter(item => item.parent_id === parentId).map(item => item.id);
            },
            checkInput(value){
                if(!this.dataBody.evaluator && !this.dataBody.disabled && !this.$root._route.params.view) {
                    this.$refs['RefCheck' + value][0].selected = !this.$refs['RefCheck' + value][0].selected;

                    let selecteds = [];

                    Object.keys(this.dataBody.answers).forEach((i) => {
                        if (this.$refs['RefCheck' + i][0].selected) {
                            selecteds.push(i);
                        }
                    });

                    this.selecteds = selecteds;

                    this.disabledSave = this.selecteds.length === 0;
                    this.checkEvidenceLoad();
                }
            },
            checkInputMultistatements(value, index){
                if(!this.dataBody.evaluator && !this.dataBody.disabled && !this.$root._route.params.view) {
                    this.$refs['RefCheck' + index + value][0].selected = !this.$refs['RefCheck' + index + value][0].selected;

                    let selecteds = [];

                    Object.keys(this.defaultBody.answers[index].reference.reference).forEach((i) => {
                        if (this.$refs['RefCheck' + index + i][0].selected) {
                            selecteds.push(i);
                        }
                    });

                    this.defaultBody.answers[index].reference.selecteds = selecteds;
                }
            },
            checkInputQuestionnaire(id){
                let selecteds = [];

                let disabledFinish = false;

                Object.entries(this.dataBody.questions).forEach((i) => {
                    let selected = '';

                    if(this.dataBody.type_answer === 4){
                        selected = this.$refs['RefCheckQuestionnaireOnly' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireOnly' + id][0].datainput.value : 0;
                    }else{
                        selected = this.$refs['RefCheckQuestionnaireOne' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireOne' + id][0].datainput.value : this.$refs['RefCheckQuestionnaireTwo' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireTwo' + id][0].datainput.value : 0
                    }

                    if(selected === 0) {
                        disabledFinish = true
                    }

                    selecteds['ID' + i[0]] = selected;
                });

                this.selecteds = selecteds;

                this.disabledSave = Object.entries(this.selecteds).length === 0;
                if(disabledFinish){
                    this.disabledFinish = true;
                }else {
                    this.checkEvidenceLoad();
                }
            },
            setTextarea(){
                this.disabledSave = this.$refs['RefTextarea'].$el.lastChild._value === '';
                this.checkEvidenceLoad();
            },
            checkSelectorBox(selected){
                this.disabledSave = selected === '';
                this.checkEvidenceLoad();
            },
            checkSelectorRange(selected){
                this.disabledSave = selected === '';
                this.checkEvidenceLoad();
            },
            checkEvidenceLoad(){
                if(this.dataBody.type !== 7 && this.dataBody.type !== 8) {
                    if (this.dataBody.evidence_required) {
                        if (this.disabledSave) {
                            this.disabledFinish = true;
                        } else {
                            this.disabledFinish = this.disabledSave === false && this.$refs['RefEvidence'].files.length === 0
                        }
                    } else {
                        this.disabledFinish = this.disabledSave;
                    }
                }else if(this.dataBody.type === 8){
                    this.disabledSave = false;
                    if(this.dataBody.evidence_required) {
                        if(this.$refs['RefNumericQuestionnaire'].selected.answer === '' || this.$refs['RefNumericQuestionnaire'].selected.value === ''){
                            this.disabledFinish = true;
                        }else{
                            this.disabledFinish = this.$refs['RefEvidence'].files.length === 0
                        }
                    }else{
                        if(this.$refs['RefNumericQuestionnaire'].selected.answer === '' || this.$refs['RefNumericQuestionnaire'].selected.value === ''){
                            this.disabledFinish = true;
                        }else{
                            this.disabledFinish = false;
                        }

                    }
                }
            },
            loadImage(data, actualIndex){
                this.defaultBody.answers[actualIndex].files = [];

                this.defaultBody.answers[actualIndex].files.push({
                    file_download: this.$refs['RefEvidence' + actualIndex][0].files[0].file_download,
                    file_name: this.$refs['RefEvidence' + actualIndex][0].files[0].file_name,
                    file_path: this.$refs['RefEvidence' + actualIndex][0].files[0].file_path,
                });

                if(this.dataBody.type_attach === 2){
                    this.defaultBody.answers[actualIndex].image = data.image;
                }

                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
            },
            removeImage(data, actualIndex){
                this.defaultBody.answers[actualIndex].files = [];
                if(this.dataBody.type_attach === 2){
                    this.defaultBody.answers[actualIndex].image = '';
                }
                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
            },
            setAssociatedWith(index){
                this.defaultBody.answers[index].associated_with = this.$refs['RefAssociatedWith' + index][0].datasaved;
                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
                this.refreshAssociatedWith++;
            },
            persistanceMultistatement(ref, index){
                switch (ref){
                    case 'RefAnswerName':
                        this.defaultBody.answers[index].name = this.$refs[ref + index][0].datainput.value;
                        break;
                    case 'RefTextarea':
                        Object.keys(this.$refs).forEach((i) => {
                            if(i.includes(ref) && this.$refs[i].length !== 0){
                                var spliter = this.$refs[i][0].datainput.id.split('answer_answer_')[1].split('_answers_');
                                this.defaultBody.answers[spliter[0]].children[spliter[1]].answer = this.$refs[ref + spliter[0] + spliter[1]][0].hiddenValue;
                            }
                        });
                }

                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
            },
            afterPaste(index, keyAnswer, answer){
                var that = this;
                setTimeout(function() {
                    that.countWords(index, keyAnswer, answer);
                },100);
                this.disabledSave = false;
            },
            addElement(){
                this.persistanceMultistatement('RefTextarea');
                var children = [];
                var reference = [];
                if(this.defaultBody.answers[0].children.length !== 0){
                    this.defaultBody.answers[0].children.forEach((i) => {
                        children.push({
                            id: i.id,
                            text: i.text,
                            words: 0,
                            show: true,
                            max_word: i.max_word,
                            tolerance: i.tolerance,
                            answer: ''
                        });
                    });

                    if(this.defaultBody.answers[0].reference !== undefined && Object.keys(this.defaultBody.answers[0].reference.length !== 0)){
                        reference = {
                            show: true,
                            statement: this.defaultBody.answers[0].reference.statement,
                            reference: this.defaultBody.answers[0].reference.reference,
                            selecteds: []
                        };
                    }
                }

                this.defaultBody.answers.push({
                    name: '',
                    show: true,
                    associated_with: [],
                    files: [],
                    image: '',
                    children: children,
                    reference: reference
                });

                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
                this.refreshMultistatement++;
            },
            deleteElement(index){
                this.persistanceMultistatement('RefTextarea');
                delete this.defaultBody.answers[index];
                this.defaultBody.answers = this.defaultBody.answers.filter(n => n);
                this.disabledSave = false;
                this.checkAllowFinishMultiStatement();
                this.refreshMultistatement++
            },
            checkAllowFinishMultiStatement(){
                var allowFinish = false;

                this.defaultBody.answers.forEach((item) => {
                    if(item.files.length === 0 && this.defaultBody.evidence_required){
                        allowFinish = true;
                    }
                });

                this.disabledFinish = allowFinish
            },
            checkAllowFinishNumericQuestionnaire(){
                this.disabledSave = false;
                this.checkEvidenceLoad();
            },
            checkShowTypeIndex(flag){
                this.showTypeIndex = flag;
                localStorage.setItem('showTypeIndex', this.showTypeIndex);
            },
        }
    }
</script>

<style>
    .color-light-blue {
        color: #1B82C5;
    }

    .gh_position-line {
        width: 100%;
        height: 56px;
        background-color: #FFFFFF;
    }

    .gh_position-index {
        width: 60%;
        min-width: 1074px;
        height: auto;
        max-height: 80vh;
        background-color: #FFFFFF;
        z-index: 999;
        position: absolute;
        margin-top: 12px;
        margin-left: -80px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 4px 4px 8px #1b82c570, -4px 4px 8px #1b82c570;
    }

    .gh_position-index::before {
        content: '';
        position: absolute;
        top: 0;
        margin-top: -6px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: #FFFFFF;
        z-index: 1000; /* Ensure it is above the original box-shadow */
    }

    .gh_position-map {
        display: flex;
        right: 0;
        margin-top: -35px;
        width: 80%;
        min-width: 800px;
        margin-right: 60px;
        height: auto;
        max-height: 80vh;
        background-color: #FFFFFF;
        z-index: 999;
        position: absolute;
        border-radius: 8px;
        box-shadow: 4px 4px 8px #1b82c570, -4px 4px 8px #1b82c570;
    }

    .margin-index-questionnaire {
        margin-left: 118px;
        margin-right: 70px;
        margin-bottom: 30px;
    }

    .gh_position-full {
        width: 100%;
        height: 100vh;
        background-color: #FFFFFF;
    }

    .gh-content-global-questionnaire {
        padding: 30px 80px;
        min-width: 944px;
    }

    .gh_margin-arrow, .gh_margin-spaces {
        padding-left: 5px; padding-right: 5px;
    }

    .gh-content-questionnaire {
        padding: 13px 15px;
    }

    .gh-row-content-without-padding {
        display: flex;
    }

    .gh-padding-index-questionnaire {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .gh-pointer {
        padding-right: 10px;
        font-size: 16px;
        margin-left: 3px;
    }

    .bread-crumb {
        display: flex;
        cursor: pointer;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .F18 {
        font-family: IBMPlexSans-Bold !important;
        font-size: 18px !important;
        font-weight: normal !important;
        color: #1B82C5;
    }

    .F15 {
        font-family: IBMPlexSans-Bold !important;
        font-size: 15px !important;
        font-weight: normal !important;
        color: #1B82C5;
    }

    .C12 {
        font-family: IBMPlexSans-Regular !important;
        font-size: 12px !important;
        font-weight: normal !important;
        color: #808080;
    }

    .flex-direction-reverse {
        display: flex;
        flex-direction: row-reverse;
    }

    .flex-direction-reverse > .table-cell_link {
        color: #1B82C5 !important;
    }

    .separator-line {
        height: 1px;
        background-color: #C7C7C7;
        margin-top: 10px;
    }

    .card-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);;
        gap: 30px;
        width: 100% !important;
    }

    .extra-info-statement-container-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);;
        gap: 30px;
        width: 100% !important;
    }

    .extra-info-statement-container-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);;
        gap: 30px;
        width: 100% !important;
    }

    .card-container-evidence {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
    }

    .question-body {
        background-color: #FFFFFF;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 40px;
    }

    .gh-statement-content {
        padding: 10px;
        display: flex;
    }

    .gh-answer-content {
        padding: 20px;
        display: block;
    }

    .input-contorn-selector {
        cursor: pointer;
        display: flex;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
        padding: 5px 10px;
        padding-bottom: 7px;
    }

    .input-contorn-selector > div > div > .checkmark-radio > .inner_radio-checked {
        background-color: #1B82C5 !important;
    }

    .input-contorn-selector > div > div > .checkmark_checked > .inner-checked {
        background-color: #1B82C5 !important;
    }

    .input-contorn-selector > div > div > .checkmark_checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark-radio > .inner_radio-checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark_checked > .inner-checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark_checked {
        background-color: #1B82C5 !important;
    }

    .row-material {
        display: flex;
    }

    .column-material {
        cursor: pointer;
        flex: 1;
        box-sizing: border-box;
        padding: 10px;
    }

    .column-material:hover {
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .double-bootom-border {
        border-bottom: 2px solid #AFAFAF;
    }

    .container-evidences {
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
    }
    .container-evidences-image {
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
        display: flex;
    }

    .container-evidences-image > span > span > span > a {
        padding: 1px;
        margin: unset;
    }

    .container-evidences-image > span > span > span {
        margin-left: 20px;
        display: flex !important;
        flex-direction: row-reverse;
    }

    #form_result_questionnaire > .gh-answer-content > div > textarea.T17 {
        font-family: IBMPlexSans-Regular;
        font-size: 13px;
        line-height: 16px;
        color: black;
    }

    #form_result_questionnaire > .gh-answer-content > div > textarea, .extra-heigth-textarea {
        min-height: 115px !important;
    }

    .shadow-textarea {
        box-shadow: 0px 0px 3px 1px #ff00005c;
    }

    .gh-answer-content2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
    }

    .row-material {
        display: contents;
    }

    .overflow-index {
        height: auto;
        max-height: 77vh;
        overflow-y: auto;
        padding: 10px;
    }

    .overflow-index::-webkit-scrollbar {
        width: 8px;
    }

    .overflow-index::-webkit-scrollbar-track {
        background: rgba(175, 175, 175, 0.2);
    }

    .overflow-index::-webkit-scrollbar-thumb {
        background-color: #1B82C5;
        border-radius: 4px;
    }

    .new-padding-style-input {
        padding: 3px;
        font-family: "PT Sans R" !important;
        font-size: 14px !important;
    }

    [id^="label_answer_answer_"][id$="_associated_with-Summary"] {
        display: none !important;
    }

    .tab-upcoming{
        width:130px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        border-radius: 10px;
        margin-top: unset !important;
    }

    .tab-past{
        width:130px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        border-radius: 10px;
        margin-top: unset !important;
    }

    @-moz-document url-prefix() {
        .overflow-index {
            scrollbar-width: thin;
            scrollbar-color: #1B82C5 rgba(175, 175, 175, 0.2);
            border-radius: 4px;
        }
    }

    @media screen and (max-width: 1450px){
        .card-container-evidence {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }
    }

    @media screen and (max-width: 1150px){
        .card-container-evidence {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
    }
</style>