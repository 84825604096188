var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_assign',
        title: _vm.$t('assing_users_to_program'),
        type: 'add',
        style: 'width: 40%; min-width: 940px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
        text: _vm.$t('assing_users_to_program') + ' (' + _vm.getterAssignItineraryTodo.title + ')'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:'REFRESHER_ASSIGN' + _vm.refresher,attrs:{"id":"form_todo_assign"}},[_c('div',{staticClass:"gh-content-global"},_vm._l((_vm.getterAssignItineraryTodo.data),function(program,keyProgram){return _c('div',{key:'PROGRAM' + keyProgram,staticStyle:{"width":"100%"},style:(keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : '')},[_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-bottom":"unset"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","width":"100%","padding-top":"4px","padding-bottom":"4px","padding-left":"5px"}},[_c('label',{class:Object.values(program.permissions).length !== 0 ? 'T14' : 'T15_b'},[_vm._v(_vm._s(program.name))]),(Object.values(program.permissions).length !== 0)?_c('div',{key:'TOGGLE_' + keyProgram + '_' + _vm.refresh,staticStyle:{"cursor":"pointer","margin-left":"10px"}},[_c('div',{class:_vm.toggleAction[keyProgram] === true ? 'gh_data_selector-more T19' : 'gh_data_selector-less T19',staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.setToggleItem(keyProgram);}}},[_vm._v(_vm._s(_vm.toggleAction[keyProgram] === true ? '[ - ]' : '[ + ]'))])]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","width":"400px !important","padding-top":"3px"}},[_vm._l((_vm.getFeeds.feed_assign_perms),function(data,key){return [_c('div',{key:'CHECKS_PERMS' + key,staticStyle:{"display":"flex","flex-direction":"row-reverse","flex-wrap":"revert"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"10px"}},[_vm._v(_vm._s(data))]),_c('GhChecks',{ref:'refCheck' + program.id + key,refInFor:true,attrs:{"datalabel":{text: '',style:'',class:'',id:''},"datainput":{
                                                selected: program.permissions.includes(key),
                                                type: 'checkbox',
                                                id: 'input_assign_support',
                                                value: key,
                                                name: 'assign[' + program.id + '][assign][]'
                                            }},on:{"click":function($event){return _vm.checkItinerary(program, key)}}})],1)]})],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleAction[keyProgram] === true),expression:"toggleAction[keyProgram] === true"}],staticStyle:{"padding-bottom":"10px"}},[_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-bottom":"unset","display":"unset"}},[_vm._l((program.programs),function(selected,keySelected){return [(selected !== undefined && selected.length !== 0)?[_c('div',{key:'SELECTEDS_' + keySelected + program.id},[_c('div',{staticStyle:{"padding-top":"4px","padding-bottom":"4px","padding-left":"5px","display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"120px !important"}},[_vm._v(" "+_vm._s(_vm.getFeeds.feed_assign_perms[keySelected] + ': ')+" ")]),_c('div',{staticStyle:{"display":"grid","width":"100%"}},[_vm._l((selected),function(item,keyItem){return [_c('label',{key:'ITEM_' + program.id + keyItem,staticClass:"gh_text_field-label T15"},[_vm._v(_vm._s(item.name))]),_c('input',{key:'ITEM_HIDDEN_' + program.id + keyItem,attrs:{"type":"hidden","name":'assign[' + program.id + '][permissions][' + keySelected + '][]'},domProps:{"value":item.id}})]})],2)])])]:_vm._e()]})],2)])])}),0)]),_c('div',{key:'TREE_ITINERARY' + _vm.refreshItinerary},[_c('GhTreeSelectorBlock',{ref:"refTreeItinerary",attrs:{"dataBlock":{
                    type: 'checkbox',
                    id: 'label_user_assign_permission',
                    label: 'aaa',
                    title: _vm.$t('assign') + ' ' + _vm.$t('to_program').toLowerCase(),
                    text: _vm.$t('select_itinerary') + ':',
                    name: 'assign[user_itinerary][]',
                    id_value: 'id',
                    name_label: 'name',
                    child_key: 'children',
                    required: false,
                    checkparentdata: true,
                    checkAll: true,
                    style: '',
                    label_style: 'width: 200px; display:none; text-align: end;',
                    popupStyle: 'min-width: 900px; width: 55%;',
                    allowCheckAutoParent: true
                },"selected_input":[],"items":_vm.getterTreeItinerary !== undefined && _vm.getterTreeItinerary.data !== undefined ? _vm.getterTreeItinerary.data : []},on:{"close":function($event){return _vm.closeSelectionItinerary()},"accept":function($event){return _vm.aceptSelectionItinerary()}}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_assign',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){_vm.toggleAction = []; _vm.setAssign()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_assign',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){_vm.toggleAction = []; _vm.Global.closePopUp('popup_assign');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }