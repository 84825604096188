<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{id:'table_todo'}"
            :data="TabTodo.list_todo"
            :header="header"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <div :style="itemProp.color_end_date !== '' ? 'color:' + itemProp.color_end_date : ''" class="T15_b">
                    {{itemProp[labelProp]}}
                </div>
            </template>
            <template v-slot:actions="{itemProp}">
                <GhAction v-if="itemProp.modify"
                    :dataction="{
                        id: 'modify_todo_' + itemProp.id,
                        text: $t('modify'),
                        icon: require('@/assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="modify(itemProp)"
                />
                <GhAction v-if="itemProp.realize"
                    :dataction="{
                        id: 'realize_todo_' + itemProp.id,
                        text: $t('realize'),
                        icon: require('@/assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="realize(itemProp)"
                />
                <GhAction
                    :dataction="{
                        id: 'view_todo_' + itemProp.id,
                        text: $t('view'),
                        icon: require('@/assets/gh_new_dessign/view.svg'),
                    }"
                    @click="openAssign(itemProp)"
                />
            </template>
        </GhTableCommon>

        <component :is="popup.assigned" :title="title" :data="getterAssignByUser"/>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapGetters, mapState} from "vuex";

    import popup_assigned from '@/view/itinerary/client/template/popup_assigned.vue';
    import router from "@/routes/router";

    export default {
        name: "list_todo",
        components:{
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['TabTodo', 'Login']),
            ...mapGetters(['getterAssignByUser'])
        },
        data(){
            return{
                haveData: false,
                popup: {
                    assigned: popup_assigned
                },
                title: '',
                header: [
                    {text: 'company', field: 'company', sorting: true,typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width:25%;'},
                    {text: 'itinerary', field: 'itinerary', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width:75%;'},
                    {text: '', field: 'actions', style: ''},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListTodo', '', {root: true});
            this.haveData = true;
        },
        methods:{
            realize(itemProp){
                this.$store.dispatch('setQuestionnaireImpartition', {
                    impartition: itemProp.impartition
                }, {root: true});

                router.push({name: 'initial_itinerary_window', params: {type: 'initial', id: itemProp.agrupator_id, extra_id: itemProp.is_eval !== undefined && itemProp.is_eval ? itemProp.id : 0, read_only: false}});
            },
            async modify(item){
                await this.$store.dispatch('getDataModify', {id:item.id,agrupator_id:item.agrupator_id}, {root: true});
                this.Global.openPopUp('popup_calendar');
            },
            async openAssign(item){
                this.title = this.Login.name + ' ' + this.$t('is_assigned_to_the_role').toLowerCase() + ' ' + item.rol + ' ' + this.$t('for_the_program').toLowerCase() + ' ' + item.itinerary + ':';

                await this.$store.dispatch('getAssignedPartProgramByUser', {
                    id: item.agrupator_id,
                    extra_id: item.is_eval !== undefined && item.is_eval ? item.id : 0,
                }, {root: true});

                this.$nextTick(() => {
                    this.Global.openPopUp('popup_assigned');
                });
            }
        }
    }
</script>