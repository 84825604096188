<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'list_config_support_flow', title: $t('flow')}">
            <template v-slot:content>
                <form id="form_support_flow" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right; min-width: 150px !important;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'workflow_name',
                                        name: 'workflow[name]',
                                        style: 'width: 100%',
                                        value: flowName,
                                        type: 'text'
                                    }"
                                    ref="GhFlowName"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{flowName}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'workflow_description',
                                        name: 'workflow[description]',
                                        required: true,
                                        value: flowDescription
                                    }"
                                    ref="GhFlowDescription"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{flowDescription}}</label>
                            </template>
                        </div>
                        <div v-for="(task, indexTask) in getWorkflowTasksGroups" :key="'P' + indexTask">
                            <template v-if="task.name !== undefined">
                                <div :class="indexTask !== 0 ? ((indexTask % 2 + (getWorkflowTasksGroups[indexTask - 1] !== undefined && getWorkflowTasksGroups[indexTask - 1].children !== undefined ? getWorkflowTasksGroups[indexTask - 1].children.length % 2 : 0)) % 2 ? 'striped_tree' : 'no_striped_tree') : (indexTask % 2 ? 'striped_tree' : 'no_striped_tree')" style="padding-left: 5px; padding-top: 3px; padding-bottom: 3px; display: flex;">
                                    <div style="width: 80%; display: flex;">
                                        <div class="T14">{{task.name}}</div>
                                        <div class="gh_data_selector-more T19" style="white-space: nowrap; margin-left: 10px; cursor: pointer" @click="actualIndexTask = indexTask; checkFeedGroupsByTask();">[ + ]</div>
                                    </div>
                                </div>
                                <template v-if="task.groups !== undefined">
                                    <div v-for="(group, indexGroup) in task.groups" :key="'C' + indexGroup">
                                        <div :class="indexTask % 2 ? (indexGroup % 2 ? 'striped_tree' : 'no_striped_tree') : (indexGroup % 2 ? 'no_striped_tree' : 'striped_tree')" style="padding-left: 25px; padding-top: 3px; padding-bottom: 3px; display: flex;">
                                            <div style="width: 80%; display: flex;">
                                                <div class="T14">{{group.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="centered" v-if="!$root._route.params.view" style="padding-top: 10px;">
                            <GhButton
                                :datainput="{
                                    id: 'btn_add_tasks',
                                    text: $t('tasks')
                                }"
                                @click="resetTasks"
                            />
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    class: 'T19 container-md_button_content',
                    text: $t('save')
                }"
                @click="setFlow()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>

        <GhDataSelector ref="selectorDataAssignmentGroup" :key="refreshGroups"
            :datablock="{
                id: 'workflow_assignment_group',
                class: 'T15_b',
                subtitle: $t('select_assignment_group') + ':',
                text: $t('assignment_group'),
                label: $t('assignment_group'),
                type: 'radio',
                columns: 3,
                style: 'margin-left: 93px; display: none;',
                required: false,
            }"
            :datainput="{
                id: 'workflow_assignment_group',
                name: 'workflow[workflow_assignment_group]'
            }"
            :selected_input="selectedGroups"
            :feed="getFeeds.feed_support_asigned"
            @accept="addGroups()"
        />

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'task_workflow_popup',
                title: $t('tasks'),
                type: 'add',
                style: 'width: 35% !important; min-width:450px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: 'subtitle_extra_popup',
                style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;',
                text: $t('select_task') + ':'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <form id="form_popup_tasks">
                    <div style="padding-left: 35px; overflow: auto; max-height: 250px;" :key="refreshTasks">
                        <div class="gh-content-global">
<!--                            <div class="gh-row-content">-->
<!--                                <div>-->
<!--                                    <div class="T15_b" style="margin-right: 15px;">-->
<!--                                        {{$t('order')}}-->
<!--                                    </div>-->
<!--                                    <div class="T15_b" style="width: 100%">-->
<!--                                        {{$t('tasks')}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="gh-row-content" v-for="(item, key) in getFeeds.feed_support_workflow_tasks" :key="key" style="margin-right: 5px;">
                                <div>
<!--                                    <div style="margin-right: 15px;">-->
<!--                                        <GhInputTextField-->
<!--                                            :datalabel="{-->
<!--                                                text: '',-->
<!--                                                style: '',-->
<!--                                                class: ''-->
<!--                                            }"-->
<!--                                            :datainput="{-->
<!--                                                required: requiredTasks[item.id],-->
<!--                                                id: 'workflow_task_order_' + item.id,-->
<!--                                                name: 'workflow[task][order][' + item.id + ']',-->
<!--                                                style: 'width: 33px;',-->
<!--                                                label_required: $t('order') + ' ' + $t('task').toLowerCase() + ' ' + labelRequiredTasks[key],-->
<!--                                                value: orderTasks[item.id],-->
<!--                                                type: 'int'-->
<!--                                            }"-->
<!--                                            @keyup="checkTasks(item.id, 'input')"-->
<!--                                        />-->
<!--                                    </div>-->
                                    <div style="width: 100%">
                                        <GhChecks
                                            :datalabel="{text: item.name, style: '', class: '', id: ''}"
                                            :datainput="{
                                                disabled: getFeeds.feed_mandatory_task[item.id] !== undefined ? true : false,
                                                required: false,
                                                selected: getFeeds.feed_mandatory_task[item.id] !== undefined ? true : selectedTasks[item.id],
                                                type: 'checkbox',
                                                id: 'workflow_task_value_' + item.id,
                                                value: item.id,
                                                name: 'workflow[task][values][]'
                                            }"
                                            ref="selectorDataTask"
                                            @click="checkTasks(item.id, 'check')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_accept_task_workflow_popup',
                        text: $t('accept'),
                    }"
                    @click="addTasks()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_task_workflow_popup',
                        text: $t('close'),
                    }"
                    @click="Global.closePopUp('task_workflow_popup')"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from 'fe-gh-button-lib';
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from 'fe-gh-checks-lib';

    import {mapGetters, mapState} from "vuex";

    export default {
        name: "flow_template",
        components: {
            GhDataSelector,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton,
            GhPopUp,
            GhChecks
        },
        computed:{
            ...mapState(['ConfigFlows', 'getFeeds']),
            ...mapGetters(['getWorkflowTasksGroups'])
        },
        data(){
            return {
                haveData: false,
                flowName: '',
                flowDescription: '',
                refreshTasks: 0,
                requiredTasks: [],
                labelRequiredTasks: [],
                orderTasks: [],
                selectedTasks: [],
                lastCheckTasks: [],
                actualIndexTask: null,
                refreshGroups: 0,
                selectedGroups: [],
                lastCheckGroup: []
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('resetTasksGroups', '', {root: true});
                await this.$store.dispatch('getFeedSupportAsigned', '', {root: true});
                await this.$store.dispatch('getFeedMandatoryTasks', '', {root: true});
                await this.$store.dispatch('getFeedSupportWorkflowTasks', {id: this.$root._route.params.id}, {root: true});
                await this.$store.dispatch('getFlowsData', {id: this.$root._route.params.id}, {root: true});

                this.flowName = this.ConfigFlows.data !== undefined && this.ConfigFlows.data.name !== undefined ? this.ConfigFlows.data.name : '';
                this.flowDescription = this.ConfigFlows.data !== undefined && this.ConfigFlows.data.description !== undefined ? this.ConfigFlows.data.description : '';

                this.getWorkflowTasksGroups.forEach((i) => {
                    this.lastCheckTasks.push(i.id);
                });

                this.haveData = true;
            }
        },
        methods: {
            getValueInputs(){
                this.flowName = this.$refs['GhFlowName'].datainput.value;
                this.flowDescription = this.$refs['GhFlowDescription'].datainput.value;
            },
            resetTasks(){
                this.requiredTasks = [];
                this.labelRequiredTasks = [];
                this.selectedTasks = [];
                this.orderTasks = [];

                Object.entries(this.getFeeds.feed_support_workflow_tasks).forEach((item) => {
                    this.requiredTasks[item[1].id] = false;
                    this.labelRequiredTasks[item[1].id] = item[1].name;
                    this.selectedTasks[item[1].id] = this.getWorkflowTasksGroups.length !== 0 && this.getWorkflowTasksGroups.find((i) => i.id === item[1].id) !== undefined;
                    this.orderTasks[item[1].id] = item[0];
                });

                this.refreshTasks++;

                this.Global.openPopUp('task_workflow_popup');
            },
            checkTasks(id, type){
                switch(type){
                    case 'check':
                        this.requiredTasks[id] = !document.getElementById('workflow_task_value_' + id).checked;
                        this.selectedTasks[id] = this.requiredTasks[id];
                        break;
                    case 'input':
                        this.selectedTasks[id] = document.getElementById('workflow_task_order_' + id).value !== '';
                        this.requiredTasks[id] = document.getElementById('workflow_task_order_' + id).value !== '';
                        break;
                }

                this.refreshTasks++;
            },
            async addTasks(){
                this.getValueInputs();

                if(this.Global.checkRequired('form_popup_tasks') === 'OK'){
                    var newCheck = [];
                    await this.$refs.selectorDataTask.forEach((that) => {
                        if(that.selected){
                            newCheck.push(that.$el.children[0].children[1].value);
                            this.$store.dispatch('setWorkflowTasks', {
                                id: that.$el.children[0].children[1].value,
                                name: that.datalabel.text,
                                order: this.orderTasks[that.$el.children[0].children[1].value]
                            }, {root: true});
                        }
                    });

                    if(this.lastCheckTasks.length !== 0){
                        var difference = this.lastCheckTasks.filter(l => !newCheck.includes(l));
                        if(difference.length !== 0){
                            await this.$store.dispatch('deleteTasksDetailsById', {item: difference}, {root: true});
                        }
                    }

                    this.lastCheckTasks = newCheck;

                    this.Global.closePopUp('task_workflow_popup');
                }
            },
            checkFeedGroupsByTask(){
                this.selectedGroups = [];

                if(this.actualIndexTask !== null && this.getWorkflowTasksGroups[this.actualIndexTask].groups !== undefined){
                    this.getWorkflowTasksGroups[this.actualIndexTask].groups.forEach((item) => {
                        this.selectedGroups[item.id] = item.name;
                    });
                }

                this.refreshGroups++;

                var that = this;
                setTimeout(function(){
                    that.Global.openPopUp('workflow_assignment_group_data_selector_popup');
                },1);
            },
            async addGroups(){
                this.getValueInputs();

                var newCheck = [];
                await Object.keys(this.$refs['selectorDataAssignmentGroup'].checkdata).forEach((that) => {
                    newCheck.push(that);
                    this.$store.dispatch('setWorkflowGroups', {
                        id: that,
                        key_task: this.actualIndexTask,
                        name: this.$refs['selectorDataAssignmentGroup'].checkdata[that],
                    }, {root: true});
                });

                if(this.lastCheckGroup.length !== 0){
                    var difference = this.lastCheckGroup.filter(l => !newCheck.includes(l));
                    if(difference.length !== 0){
                        await this.$store.dispatch('deleteWorkflowGroups', {item: difference, key_task: this.actualIndexTask}, {root: true});
                    }
                }

                this.lastCheckGroup = newCheck;
                this.Global.closePopUp('workflow_assignment_group_data_selector_popup');
            },
            async setFlow(){
                if(this.Global.checkRequired('form_support_flow') === 'OK') {
                    const formData = new FormData(document.getElementById('form_support_flow'));
                    this.$store.dispatch('setFlowData', {formData: [...formData], id: this.$root._route.params.id},{root:true});
                }
            }
        }
    }
</script>

<style>
    #workflow_assignment_group_data_select_SUMMARY{
        display: none !important;
    }
</style>