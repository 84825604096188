<template>
    <div class="gh-content-global">
        <div class="gh-row-content">
            <GhChecks
                :datalabel="{text:'',style:'',class:'',id:''}"
                :datainput="{required:true,selected:false, label_required: $t('politic_privacity') + '*',type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][politic_privacity]'}"
            />
            <GhAction
                style="margin-left: 10px; margin-top: -1px;"
                :dataction="{
                    id: 'action_politic_privacity',
                    text: $t('politic_privacity')
                }"
                @click="downloadPDF('politic_privacity')"
            />
        </div>
        <div class="gh-row-content">
            <GhChecks
                :datalabel="{text: '',style:'',class:'',id:''}"
                :datainput="{required:true,selected:false, label_required: $t('terms_conditions') + '*', type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][terms_conditions]'}"
            />
            <GhAction
                style="margin-left: 10px; margin-top: -1px;"
                :dataction="{
                    id: 'action_terms_conditions',
                    text: $t('terms_conditions')
                }"
                @click="downloadPDF('terms_conditions')"
            />
        </div>
        <div class="gh-row-content">
            <GhChecks
                :datalabel="{text: '', style:'', class:'', id:''}"
                :datainput="{required:true,selected:false, label_required: $t('legal_advise') + '*', type:'checkbox',id:'', value: 1, name:'request_collaboration[data_treatment][legal_advise]'}"
            />
            <GhAction
                style="margin-left: 10px; margin-top: -1px;"
                :dataction="{
                    id: 'action_legal_advise',
                    text: $t('legal_advise')
                }"
                @click="downloadPDF('legal_advise')"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'data_treatment',
        components: {
            GhAction,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds']),
        },
        methods: {
            async downloadPDF(type){
                await this.$store.dispatch('getDataTreatment', {
                    type: type,
                }, {root: true}).then((response) => {
                    if(response.data !== undefined && response.data !== null && response.data !== '') {
                        const link = document.createElement('a');
                        link.id = 'download_report_pdf';

                        var filename = '';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const match = contentDisposition.match(/filename="(.+)"/);
                            if (match && match[1]) {
                                filename = match[1];
                            }
                        }

                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);
                    }

                    this.pdfGenerate = true;
                });
            }
        }
    }
</script>