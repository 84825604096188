import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import { API_URL } from "@/common/config";

import router from "@/routes/router";

Vue.use(vuex);
export default{
    state: {
        index: [],
        refreshIndex: false,
        indexFeed: [],
        refreshIndexFeed: false,
        body: [],
        refreshBodyContent: false,
        information: [],
        refreshInformation: false,
        knowMoreOrLearning: [],
        refreshKnowMoreOrLearning: false,
        material: [],
        isImpartition: false,
        refreshMaterial: false,
        nextQuestionnaire: [],
        refreshNextQuestionnaire: false,
        jointMap: [],
        refreshQuestionnaireJointMap: false,
        consensus: [],
        refreshQuestionnaireConsensus: false,
        globalMap: [],
        refreshQuestionnaireGlobalMap: false,
        rate: [],
        evaluation: [],
        advice: [],
        substatementByMultistatement: [],
        chartResult: [],
        annexes: [],
        allowImpartition: false,
        finishedQuestionnaireInitial: false,
        refreshAdvice: false,
        refreshChartResult: false,
        refreshSubstatementByMultistatement: false,
        refreshQuestionnaireRate: false,
        refreshQuestionnaireEvaluation: false,
        refreshFinishedQuestionnaireInitial: false,
        refreshAnnexes: false,
        refreshIsImpartition: false,
        refreshAllowImpartitionFinish: false
    },
    mutations: {
        loadQuestionnaireIndex(state, payload){
            state.refreshIndex = !state.refreshIndex;
            state.index = payload;
        },
        loadFinishedQuestionnaireInitial(state, payload){
            state.refreshFinishedQuestionnaireInitial = !state.refreshFinishedQuestionnaireInitial;
            state.finishedQuestionnaireInitial = payload;
        },
        loadQuestionnaireIndexFeed(state, payload){
            state.refreshIndexFeed = !state.refreshIndexFeed;
            state.indexFeed = payload;
        },
        loadAllowImpartitionFinish(state, payload){
            state.refreshAllowImpartitionFinish = !state.refreshAllowImpartitionFinish;
            state.allowImpartition = payload;
        },
        loadQuestionnaireBodyContent(state, payload){
            state.refreshBodyContent = !state.refreshBodyContent;
            state.body = payload;
        },
        loadQuestionnaireInformation(state, payload){
            state.refreshInformation = !state.refreshInformation;
            state.information = payload;
        },
        loadQuestionnaireKnowMoreOrLearning(state, payload){
            state.refreshKnowMoreOrLearning = !state.refreshKnowMoreOrLearning;
            state.knowMoreOrLearning = payload;
        },
        loadQuestionnaireMaterial(state, payload){
            state.refreshMaterial = !state.refreshMaterial;
            state.material = payload;
        },
        loadNextQuestionnaire(state, payload){
            state.refreshNextQuestionnaire = !state.refreshNextQuestionnaire;
            state.nextQuestionnaire = payload;
        },
        loadQuestionnaireJointMap(state, payload){
            state.refreshQuestionnaireJointMap = !state.refreshQuestionnaireJointMap;
            state.jointMap = payload;
        },
        loadConsensusJointMap(state, payload){
            state.refreshQuestionnaireConsensus = !state.refreshQuestionnaireConsensus;
            state.consensus = payload;
        },
        loadQuestionnaireJointMapSelection(state, payload){
            state.refreshQuestionnaireJointMap = !state.refreshQuestionnaireJointMap;
            state.jointMap.data[payload.key]['selecteds'] = payload.param;
            state.jointMap.data[payload.key]['hard_points'] = payload.param.hard_points.length;
            state.jointMap.data[payload.key]['improvement_opportunities'] = payload.param.improvement_opportunities.length;
            state.jointMap.data[payload.key]['good_practices'] = payload.param.good_practices.length;
        },
        loadQuestionnaireGlobalMap(state, payload){
            state.refreshQuestionnaireGlobalMap = !state.refreshQuestionnaireGlobalMap;
            state.globalMap = payload;
        },
        loadAdvice(state, payload){
            state.refreshAdvice = !state.refreshAdvice;
            state.advice = payload;
        },
        loadSubstatementByMultistatement(state, payload){
            state.refreshSubstatementByMultistatement = !state.refreshSubstatementByMultistatement;
            state.substatementByMultistatement = payload;
        },
        loadQuestionnaireEvaluation(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation = payload;
            this.commit('refreshValorations');
        },
        loadQuestionnaireRate(state, payload){
            state.refreshQuestionnaireRate = !state.refreshQuestionnaireRate;
            state.rate = payload;
        },
        loadAnnexes(state, payload){
            state.refreshAnnexes = !state.refreshAnnexes;
            state.annexes = payload;
        },
        loadChartResult(state, payload){
            state.refreshChartResult = !state.refreshChartResult;
            state.chartResult = payload;
        },
        pushLineValoration(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation.valorations[payload].push({description: ''});
            this.commit('refreshValorations');
        },
        removeLineValoration(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            delete state.evaluation.valorations[payload.type][payload.key];
            state.evaluation.valorations[payload.type] = state.evaluation.valorations[payload.type].filter(n => n);
            this.commit('refreshValorations');
        },
        updateLineValoration(state, payload){
            state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            state.evaluation.valorations[payload.params.type][payload.key].description = payload.params.description;
            this.commit('refreshValorations');
        },
        refreshValorations(state){
            if(state.evaluation.valorations !== undefined) {
                state.refreshQuestionnaireEvaluation = !state.refreshQuestionnaireEvaluation;
            }
        },
        updateIsImpartition(state, payload){
            state.refreshIsImpartition = !state.refreshIsImpartition;
            state.isImpartition = payload;
        }
    },
    getters: {
        getterFinishedQuestionnaireInitial(state){
            const dummy = state.refreshFinishedQuestionnaireInitial; // eslint-disable-line no-unused-vars
            return state.finishedQuestionnaireInitial;
        },
        getterQuestionnaireIndex(state){
            const dummy = state.refreshIndex; // eslint-disable-line no-unused-vars
            return state.index;
        },
        getterAllowImpartitionFinish(state){
            const dummy = state.refreshAllowImpartitionFinish; // eslint-disable-line no-unused-vars
            return state.allowImpartition;
        },
        getterQuestionnaireIndexFeed(state){
            const dummy = state.refreshIndexFeed; // eslint-disable-line no-unused-vars
            return state.indexFeed;
        },
        getterQuestionnaireBodyContent(state){
            const dummy = state.refreshBodyContent; // eslint-disable-line no-unused-vars
            return state.body;
        },
        getterQuestionnaireInformation(state){
            const dummy = state.refreshInformation; // eslint-disable-line no-unused-vars
            return state.information;
        },
        getterQuestionnaireKnowMoreOrLearning(state){
            const dummy = state.refreshKnowMore; // eslint-disable-line no-unused-vars
            return state.knowMoreOrLearning;
        },
        getterQuestionnaireMaterial(state){
            const dummy = state.refreshMaterial; // eslint-disable-line no-unused-vars
            return state.material;
        },
        getterNextQuestionnaire(state){
            const dummy = state.refreshNextQuestionnaire; // eslint-disable-line no-unused-vars
            return state.nextQuestionnaire;
        },
        getterQuestionnaireJointMap(state){
            const dummy = state.refreshQuestionnaireJointMap; // eslint-disable-line no-unused-vars
            return state.jointMap;
        },
        getterConsensusJointMap(state){
            const dummy = state.refreshQuestionnaireConsensus; // eslint-disable-line no-unused-vars
            return state.consensus;
        },
        getterQuestionnaireGlobalMap(state){
            const dummy = state.refreshQuestionnaireGlobalMap; // eslint-disable-line no-unused-vars
            return state.globalMap;
        },
        getterQuestionnaireEvaluation(state){
            const dummy = state.refreshQuestionnaireEvaluation; // eslint-disable-line no-unused-vars
            return state.evaluation;
        },
        getterQuestionnaireRate(state){
            const dummy = state.refreshQuestionnaireRate; // eslint-disable-line no-unused-vars
            return state.rate;
        },
        getterAdvice(state){
            const dummy = state.refreshAdvice; // eslint-disable-line no-unused-vars
            return state.advice;
        },
        getterSubstatementByMultistatement(state){
            const dummy = state.refreshSubstatementByMultistatement; // eslint-disable-line no-unused-vars
            return state.substatementByMultistatement;
        },
        getterChartResult(state){
            const dummy = state.refreshChartResult; // eslint-disable-line no-unused-vars
            return state.chartResult;
        },
        getterAnnexes(state){
            const dummy = state.refreshAnnexes; // eslint-disable-line no-unused-vars
            return state.annexes;
        },
        getterIsImpartition(state){
            const dummy = state.refreshIsImpartition; // eslint-disable-line no-unused-vars
            return state.isImpartition;
        }
    },
    actions: {
        setQuestionnaireImpartition(state, item){
            state.commit('updateIsImpartition', item.impartition);
        },
        cleanQuestionnaireLoads(state){
            state.commit('loadQuestionnaireIndex', []);
            state.commit('loadQuestionnaireIndexFeed', []);
            state.commit('loadAllowImpartitionFinish', []);
            state.commit('loadQuestionnaireBodyContent', []);
            state.commit('loadQuestionnaireInformation', []);
            state.commit('loadQuestionnaireKnowMoreOrLearning', []);
            state.commit('loadQuestionnaireMaterial', []);
            state.commit('loadNextQuestionnaire', []);
            state.commit('loadQuestionnaireGlobalMap', []);
            state.commit('loadQuestionnaireJointMap', []);
            state.commit('loadConsensusJointMap', []);
            state.commit('loadFinishedQuestionnaireInitial', false);
        },
        async getChartResult(state, item){
            let requestLogin = API_URL + item.type + "/chart/" + item.id

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.commit("loadChartResult", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireIndex(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/index/" + item.id : item.type + "/index/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireIndex', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAllowImpartitionFinish(state, item){
            let requestUrl =  API_URL + "autoevaluate/leader/finished/" + item.grouper_id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadAllowImpartitionFinish', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSubstatementByMultistatement(state, item){
            let requestUrl =  API_URL + "indications/" + item.association_id + "/" + item.substatement_id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadSubstatementByMultistatement', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireIndexFeed(state, item){
            let requestUrl =  API_URL + item.type + "/index/" + item.id + "/feed" + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireIndexFeed', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireBody(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "body/autoevaluate/" + item.id : item.type + "/body/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireBodyContent', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireFromIndex(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/body/" + item.id + (item.actual_id === undefined ? '' : "/" + item.actual_id) + (item.index !== undefined ? "/" + item.index : "") : item.type + "/body/" + item.id + (item.actual_id === undefined ? '' : "/" + item.actual_id) + (item.index !== undefined ? "/" + item.index : "") + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        if(response.data.data.impartition){
                            await state.dispatch('getAllowImpartitionFinish', {
                                grouper_id: item.id
                            });
                        }

                        state.commit('loadQuestionnaireBodyContent', response.data.data);

                        var extra_id = '';
                        if(response.data.data.cards !== undefined){
                            response.data.data.cards.forEach((i) => {
                                extra_id += i.id + '&';
                            });
                        }

                        return {id: response.data.data.id, type: response.data.data.type, extra_id: extra_id}
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMaterialFromIndex(state, item){
            let requestUrl =  API_URL + item.type + "/materials/" + item.id + (item.actual_id !== undefined ? "/" + item.actual_id : '') + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireMaterial', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMaterialBySlug(state, item){
            let requestUrl =  API_URL + item.type + "/materials/" + item.id + "/" + item.actual_id + "/" + item.slug + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireKnowMoreOrLearning', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireInformation(state, item){
            let requestUrl =  API_URL + item.type + "/information/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '') + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireInformation', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireLegend(state, item){
            let requestUrl =  API_URL + item.type + "/legend/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '') + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireInformation', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnnaireKnowMore(state, item){
            let requestUrl =  API_URL + item.type + "/know-more/" + item.id + (item.actual_id !== null ? "/" + item.actual_id : '') + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireKnowMoreOrLearning', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStartEvaluation(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/info/" + item.id + "/" + item.actual_id : item.type + "/info/" + item.id + "/" + item.actual_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireEvaluation', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEvaluationByUser(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/info/" + item.id + "/" + item.actual_id + "/" + item.user_id : item.type + "/info/" + item.id + "/" + item.actual_id + "/" + item.user_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireEvaluation', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStartRate(state, item){
            let requestUrl =  API_URL + item.type + "/rate/" + item.id + "/" + item.actual_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireRate', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getRateByUser(state, item){
            let requestUrl =  API_URL + item.type + "/rate/" + item.id + "/" + item.actual_id + "/" + item.user_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireRate', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAdvice(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate" + (item.selecting !== undefined && item.selecting ? "/advice-global/" : "/advice/") + item.id + "/" + item.actual_id : item.type + (item.selecting !== undefined && item.selecting ? "/advice-global/" : "/advice/") + item.id + "/" + item.actual_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadAdvice', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAdviceByUser(state, item){
            let requestUrl =  API_URL + item.type + "/advice/" + item.id + "/" + item.actual_id + "/" + item.user_id;

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        response.data.data['user'] = item.full_name;
                        state.commit('loadAdvice', response.data.data);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        setLineValoration(state, item){
            state.commit("pushLineValoration", item.type);
        },
        setRemoveLineValoration(state, item){
            state.commit("removeLineValoration", item);
        },
        updateContentValoration(state, item){
            state.commit("updateLineValoration", item);
        },
        async setStartRate(state, item){
            let requestUrl =  API_URL + item.type + "/rate/" + item.id + "/" + item.actual_id + '/' + item.type_action + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');
            let formData = new FormData();

            if(item.formData !== undefined) {
                item.formData.forEach((i) => {
                    formData.append(i[0], i[1]);
                });
            }

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setStartEvaluation(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/info/" + item.id + "/" + item.actual_id + '/' + item.type_action : item.type + "/info/" + item.id + "/" + item.actual_id + '/' + item.type_action + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));
            let formData = new FormData();

            if(item.formData !== undefined) {
                item.formData.forEach((i) => {
                    formData.append(i[0], i[1]);
                });
            }

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setQuestionnaireSave(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/body/" + item.id + "/" + item.actual_id + "/save" : item.type + "/body/" + item.id + "/" + item.actual_id + "/save" + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        await state.dispatch('getQuestionnaireIndex', {
                            type: item.type,
                            id: item.id,
                            extra_id: item.extra_id
                        }, {root: true});

                        this._vm.$toast(item.keyTranslate['changes_have_been_saved_correctly'], {
                            position: "bottom-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setQuestionnaireFinish(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/body/" + item.id + "/" + item.actual_id + "/finish" : item.type + "/body/" + item.id + "/" + item.actual_id + (item.only_next ? "/next" : "/finish") + (item.only_next !== undefined && !item.only_next && item.validator ? "/validation" : "") + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadNextQuestionnaire', response.data.data);

                        await state.dispatch('getQuestionnaireIndex', {
                            type: item.type,
                            id: item.id,
                            extra_id: item.extra_id
                        }, {root: true});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setCalcConsensusPunctuation(state, item){
            let requestUrl =  API_URL + item.type + "/consensus/punctuation/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        var consensus = state.state.consensus;
                        response.data.data.forEach((i) => {
                            item.formData.forEach((x) => {
                                consensus.content.find((e, k) => {
                                    if(e.id === parseInt(x[0].split('data[punctuation][')[1].split(']')[0])){
                                        consensus.content[k].final = x[1];
                                    }
                                });
                            })

                            if(i.id !== 'total' && i.id !== 'level'){
                                consensus.content.find((e, k) => {
                                    if(e.id === i.id){
                                        consensus.content[k].final = i.punctuation
                                    }
                                });
                            }else if(i.id !== 'level'){
                                consensus.footer.footer[0].final = i.punctuation;
                            }
                        });

                        state.commit('loadConsensusJointMap', consensus);
                    }

                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setConsensus(state, item){
            let requestUrl =  API_URL + item.type + "/consensus/" + item.type_save + "/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: requestUrl,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async resetNextQuestionnaire(state){
            state.commit('loadNextQuestionnaire', []);
        },
        async changeSelectionMap(state, params){
            state.commit('loadQuestionnaireJointMapSelection', params);
        },
        async getUserColumns(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/joint-map/columns/" + item.id : item.type + "/joint-map/columns/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        return response.data.data
                        // state.commit('loadQuestionnaireJointMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConsensusHeader(state, item){
            let requestUrl =  API_URL + item.type + "/consensus/header/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireJointMap(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/joint-map/" + item.id : item.type + "/joint-map/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireJointMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConsensusJointMap(state, item){
            let requestUrl =  API_URL + item.type + "/consensus/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadConsensusJointMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuestionnaireGlobalMap(state, item){
            let requestUrl =  API_URL + (state.state.isImpartition ? "autoevaluate/global/" + item.id + "/map" : item.type + "/global/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        state.commit('loadQuestionnaireGlobalMap', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setFinishAllQuestionnaire(state, item){
            let requestUrl =  API_URL + (state.state.allowImpartition === true || state.state.allowImpartition === 1 ? "autoevaluate/leader/finished/" + item.id : "questionnaire/" + item.id + "/finish" + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));
            let requestUrl2 =  API_URL + "company/finish_inicia";

            await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => { // eslint-disable-line no-unused-vars
                    if(response.status === 200) {
                        if (item.type === 'initial' && item.extra !== 'realize') {
                            await axios({
                                method: "post",
                                url: requestUrl2,
                                headers: {"Content-Type": "multipart/form-data"}
                            }).then(
                                async response2 => {
                                    if (response2.status === 200) {
                                        state.commit('loadFinishedQuestionnaireInitial', true);
                                        // router.push({name: 'result_quizz_initial', params: {id: item.id}});
                                    }
                                },
                                error => {
                                    console.log(error);
                                }
                            )
                        }
                        if (item.extra === 'realize') {
                            if(state.state.body.screens !== undefined && state.state.body.screens.second){
                                state.commit('loadFinishedQuestionnaireInitial', true);
                            }else {
                                router.push({name: 'todo'});
                            }
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setElementSelected(state, item){
            let requestUrl =  API_URL + item.type + "/rate/answers/" + item.actual_id + "/" + item.id + "/" + item.parent + "/" + item.element + '/' + item.item + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit('loadQuestionnaireRate', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setResetRate(state, item){
            let requestUrl =  API_URL + item.type + "/rate/reset/" + item.actual_id + "/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setValidateRate(state, item){
            let requestUrl =  API_URL + item.type + "/rate/validate/" + item.id + "/" + item.actual_id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "post",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPDFGlobalMap(state, item){
            let requestUrl =  API_URL + item.type + "/map-pdf/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                responseType: 'blob',
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async getExcelGlobalMap(state, item){
            let requestUrl =  API_URL + item.type + "/map-excel/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                responseType: 'blob',
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async getPDFWithCard(state, item){
            let requestUrl =  API_URL + 'grouper/finish-pdf/' + item.id;

            return await axios({
                method: "get",
                url: requestUrl,
                responseType: 'blob',
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async getPDFMemory(state, item){
            let requestUrl =  API_URL + item.type + "/memory-pdf/" + item.id + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 && item.extra_id !== 0 ? '?id=' + item.extra_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                responseType: 'blob',
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async getAnnexes(state, item){
            let requestUrl =  API_URL + "annexes/" + item.agrupator_id + (item.company_id !== undefined && item.company_id !== null && item.company_id !== 0 && item.company_id !== 0 ? '?id=' + item.company_id : '');

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit('loadAnnexes', response.data.data);
                    }
                },
                error => {
                    return error;
                }
            )
        },
        async getAnnexesZIP(state, item){
            let requestUrl =  API_URL + "annexes/download/" + item.agrupator_id + (item.company_id !== undefined && item.company_id !== null && item.company_id !== 0 && item.company_id !== 0 ? '?id=' + item.company_id : '');

            return await axios({
                method: "get",
                url: requestUrl,
                responseType: 'blob',
                headers: {
                    "Accept": "application/zip"
                }
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        },
        async setLiderMap(state, item){
            let request = API_URL + (state.state.isImpartition ? "autoevaluate/joint-map/" + item.id + "/" + item.type_set : item.type + "/map/" + item.id + "/" + item.type_set + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 && item.extra_id !== 0 ? '?id=' + item.extra_id : ''));

            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            state.state.jointMap.data.forEach((i) => {
                if(i.selecteds !== undefined) {
                    i.selecteds.hard_points.forEach((hard_points) => {
                        formData.append('joint_evaluations[' + i.id + '][hard_points][]', hard_points);
                    });

                    i.selecteds.improvement_opportunities.forEach((improvement_opportunities) => {
                        formData.append('joint_evaluations[' + i.id + '][improvement_opportunities][]', improvement_opportunities);
                    });

                    i.selecteds.good_practices.forEach((good_practices) => {
                        formData.append('joint_evaluations[' + i.id + '][good_practices][]', good_practices);
                    });
                }
            });

            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response
                },
                error => {
                    return error;
                }
            )
        }
    }
};
