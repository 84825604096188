<template>
    <div>
        <template v-if="haveData">
            <div style="width: 100%; display: flex; justify-content: center;">
                <div class="card-container" style="padding: 20px; max-width: 1500px;">
                    <template v-for="(itemCard, keyCard) in getterRecomendedPrograms">
                        <div :key="'KEY'+ keyCard">
                            <component :is="components.card" :keyCard="keyCard" :itemCard="itemCard" :showButton="false"/>
                        </div>
                    </template>
                </div>
            </div>
            <div class="centered" style="padding-top: 8px; padding-bottom: 10px;" v-if="getterRecomendedPrograms.length !== 0">
                <GhButton
                    :datainput="{
                        id: 'btn_contract_programs',
                        text: $t('contract_programs')
                    }"
                    @click="openContractElement"
                />
            </div>
        </template>
        <template v-else>
            <div class="centered" style="padding-bottom: 10px; margin-top: 15px;">
                <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhButton from 'fe-gh-button-lib';

    import {mapGetters} from "vuex";

    import card from '../../template/card.vue';

    export default {
        name: 'recomended_programs',
        computed: {
            ...mapGetters(['getterRecomendedPrograms'])
        },
        components: {
            GhButton
        },
        data(){
            return{
                haveData: false,
                components: {
                    card: card
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getRecomendedPrograms', '', {root: true});

                this.haveData = true;
            }
        },
        methods: {
            async openContractElement(){
                await this.$store.dispatch('CHECK_TO_PAY', '', {root: true}).then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === 467) {
                            this.Global.windowOpen(this.Global.openSecondWindow('edit_business', {
                                windowed: true
                            }), 'edit_business');
                        } else {
                            this.Global.windowOpen(
                                this.Global.openSecondWindow('platform_pay', {
                                    type: 'recomended-programs'
                                }), 'preview_exercise'
                            );

                            let that = this;
                            window.UpdatedSelect = async function (e) {
                                if (e.response === true) {
                                    await that.$store.dispatch('getRecomendedPrograms', '', {root: true});
                                }
                            }
                        }
                    }
                });
            }
        }
    }
</script>