<template>
    <div v-if="haveData">
        <GhTableCommon
            style="margin-top:10px;"
            :extratable="{
                id: 'assigned_users'
            }"
            :header="header"
            :data="getterAssignedUsers"
        >
            <template v-slot:actions="{itemProp}">
                <GhAction
                    :dataction="{
                        id: 'view_assign_' + itemProp.id,
                        text: $t('view'),
                        icon: require('@/assets/gh_new_dessign/view.svg'),
                    }"
                    @click="openAssign(itemProp)"
                />
            </template>
        </GhTableCommon>

        <component :is="popup.assigned" :title="title" :data="getterAssignByUser"/>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    import popup_assigned from '@/view/itinerary/client/template/popup_assigned.vue';

    export default {
        name: "list_assigned_users",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterAssignedUsers', 'getterAssignByUser'])
        },
        data(){
            return {
                haveData: false,
                popup: {
                    assigned: popup_assigned
                },
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'rol', field: 'rol', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'program', field: 'program', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: '', field: 'actions', style: ''}
                ],
                title: ''
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getAssignedUsers', '', {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async openAssign(item){
                this.title = item.name + ' ' + this.$t('is_assigned_to_the_role').toLowerCase() + ' ' + item.rol + ' ' + this.$t('for_the_program').toLowerCase() + ' ' + item.program + ':';

                await this.$store.dispatch('getAssignedPartProgramByUser', {
                    id: item.id,
                    uuid: item.uuid,
                    rol_id: item.rol_id
                }, {root: true});

                this.$nextTick(() => {
                    this.Global.openPopUp('popup_assigned');
                });
            }
        }
    }
</script>