import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        popup_data: '',
        assigneds: [],
        refreshAssigneds: false
    },
    mutations: {
        loadAssignData(state, payload){
            state.popup_data = payload;
        },
        loadAssignByUser(state, payload){
            state.refreshAssigneds = !state.refreshAssigneds;
            state.assigneds = payload;
        },
        loadListAssignData(state, payload){
            state.records_data = payload;
        }
    },
    getters: {
        getterAssignByUser(state) {
            const refreshAssigneds = state.refreshAssigneds; // eslint-disable-line no-unused-vars
            return state.assigneds;
        },
    },
    actions: {
        async getListAssignment(state, item){
            await axios.get(
                API_URL + "list_assignment/" + item.id
            ).then(
                response => {
                    state.commit('loadListAssignData', response.data.data);
                }
            )
        },
        async getAssign(state, item){
            await axios.get(
                API_URL + "assign/" + item.itinerary_id  + '/' + item.id
            ).then(
                response => {
                    state.commit('loadAssignData', response.data.data);
                }
            )
        },
        async getAssignedPartProgramByUser(state, item){
            await axios.get(
                API_URL + "assign/by-user/" + item.id + (item.uuid !== undefined ? "/" + item.uuid : "") + (item.rol_id !== undefined ? "/" + item.rol_id : "") + (item.extra_id !== undefined && item.extra_id !== null && item.extra_id !== 0 ? '?id=' + item.extra_id : '')
            ).then(
                response => {
                    state.commit('loadAssignByUser', response.data.data);
                }
            )
        },
        async setUserAssign(state, item){
            let requestLogin = API_URL + "assign_save/" + item.itinerary_id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response.statusText;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};