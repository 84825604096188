<template>
    <div :key="'REF' + refreshView">
        <GhCEQuestionnaireConstructor v-if="haveData"
            :block="{
                svg: {
                    add: require('@/assets/gh_new_dessign/add_plus.svg'),
                    noImg: require('@/assets/gh_new_dessign/no-image2.svg'),
                    arrowRight: require('@/assets/questionnaire/arrow-right.svg'),
                    arrowDown: require('@/assets/questionnaire/arrow-down.svg'),
                    arrowUp: require('@/assets/questionnaire/arrow-up.svg'),
                    close: '',
                    levels: {
                        '1': require('@/assets/questionnaire/level1.svg'),
                        '2': require('@/assets/questionnaire/level2.svg'),
                        '3': require('@/assets/questionnaire/level3.svg'),
                        '4': require('@/assets/questionnaire/level4.svg'),
                        '5': require('@/assets/questionnaire/level5.svg')
                    },
                    material: require('@/assets/questionnaire/book.svg'),
                    download: require('@/assets/gh_new_dessign/descargar.svg'),
                    delete: require('@/assets/gh_new_dessign/trash.svg')
                },
                pointer: {
                    colors: [
                        '#C7C7C7',
                        '#F58449',
                        '#005392',
                        '#3DA106'
                    ],
                    title: [
                        'for_start',
                        'in_progress',
                        'finish'
                    ]
                },
                evidence: {
                    dispatch: 'easyUpload',
                    accept: '.pdf, .xls, .xlsx, .doc, .docx, .odt',
                    remove: true,
                    removeText: '',
                    removeSvg: require('@/assets/questionnaire/trash.svg'),
                    lupaSvg: require('@/assets/gh_new_dessign/lupa.svg'),
                }
            }"
            :actualIndex="actualIndex"
            :firstQuest="getterFirstQuestionary"
            :dataIndex="getterQuestionnaireIndex"
            :dataBody="getterQuestionnaireBodyContent"
            :dataMaterials="getterQuestionnaireMaterial"
            :nextQuestionnary="getterNextQuestionnaire"
            :feedIndex="getterQuestionnaireIndexFeed"
            :finishedQuestionnaireInitial="getterFinishedQuestionnaireInitial"
            :allowImpartitionFinish="getterAllowImpartitionFinish"
            @setQuestion="handleSetQuestion"
            @getMaterial="handeGetMaterial"
            @setSave="handeSetSave"
            @setFinish="handeSetFinish"
            @watcherFinish="handleSetQuestion"
            @resetNextQuestionnaire="resetNextQuestion"
            @checkParent="handleCheckParent"
            ref="RefQuestionnaire"
        >
            <template v-slot:slotFinishedQuestionnaireInitial>
                <div style="display: flex;">
                    <div style="min-width: 400px; max-width: 600px; width: 50%;">
                        <div style="display: flex; margin-top:5px;">
                            <div v-for="(i, k) in getterChartResult.legend" :key="'LEGEND_' + k">
                                <div style="cursor: pointer; display: flex; margin-left: 20px;" @click="showHideChart(k, i.show)" :style="i.show ? '' : 'opacity: 0.4;'">
                                    <div style="width: 25px; height: 5px; margin-top: 7px; margin-right: 10px; " :style="'background-color:' + i.color"></div>
                                    <div class="T-subtitle-second">{{i.name}}</div>
                                </div>
                            </div>
                        </div>
                        <div :key="'CHART_' + refreshChart">
                            <GhChartRadar
                                :dataBlock="{
                                    id: '',
                                    class: '',
                                    style: ''
                                }"
                                :chartConfig="{
                                    margin: {
                                        top: 110,
                                        right: 100,
                                        bottom: 10,
                                        left: 100
                                    },
                                    color: getterChartResult.colors,
                                    maxValue: getterChartResult.max_punctuation,
                                    levels: getterChartResult.max_punctua,
                                    roundStrokes: false,
                                    labelFactor: 1.3,
                                    wrapWidth: 120,
                                    opacityArea: 0,
                                    dotRadius: 4,
                                    opacityCircles: 0,
                                    strokeWidth: 3,
                                    blur: 5
                                }"
                                :chartData="chartRadar"
                            />
                        </div>
                    </div>
                    <div style="width: 50%">
                        <div class="gh-row-content">
                            <label class="T-subtitle color-light-blue" style="margin-right: 5px; line-height: 1.4;">{{$t('this_is_yours_position_chart')}}</label>
                        </div>
                        <div class="gh-row-content" style="padding-top: 15px;">
                            <label class="T-subtitle-second">{{$t('chart_text_send_mail')}}</label>
                        </div>
                    </div>
                </div>
                <div class="centered">
                    <router-link :to="{name: 'to_pay', params: {
                        id: $root._route.params.id
                    }}">
                        <GhButton
                            class="button-questionnaire-primary"
                            :datainput="{
                                id: 'btn_start',
                                text: $t('want_start_excelence'),
                                class: '',
                                style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'
                            }"
                        />
                    </router-link>
                </div>
            </template>
            <template v-slot:finishQuestionnaire>
                <template v-if="!readOnly || readOnly === 'false'">
                    <div class="centered" style="padding-top: 20px;">
                        <GhButton
                            class="button-questionnaire-primary"
                            :datainput="{
                                id: 'btn_finish',
                                text: $t('finish')
                            }"
                            @click="setFinishAllQuestionnaire()"
                        />
                    </div>
                </template>
            </template>
            <template v-slot:contentInsideMenu>
                <template v-if="!readOnly || readOnly === 'false'">
                    <template v-if="getterQuestionnaireBodyContent.impartition === 1 || getterQuestionnaireBodyContent.impartition === true || getterQuestionnaireBodyContent.impartition === false ?
                    getterAllowImpartitionFinish && !getterFirstQuestionary && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.finish !== undefined && getterQuestionnaireIndex.finish && getterQuestionnaireBodyContent !== undefined && getterQuestionnaireBodyContent.screens !== undefined && getterQuestionnaireBodyContent.screens['first'] :
                    !getterFirstQuestionary && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.finish !== undefined && getterQuestionnaireIndex.finish">
                        <GhButton
                            class="button-questionnaire-primary"
                            :datainput="{
                                id: 'btn_finish',
                                text: $t('finish')
                            }"
                            @click="setFinishAllQuestionnaire()"
                        />
                    </template>
                    <template v-if="getterQuestionnaireIndex.show_ponderation">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_show_report',
                                text: $t('view_ponderation')
                            }"
                            @click="getPonderation()"
                        />
                    </template>
                    <template v-if="getterQuestionnaireIndex.show_report">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_show_report',
                                text: pdfGenerate ? $t('view_report') : $t('generating'),
                                icon: pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            }"
                            :disabled="!pdfGenerate"
                            @click="generatePDF()"
                        />
                    </template>
                    <template v-if="getterQuestionnaireIndex.show_memory">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_view_annexes',
                                text: pdfMemory ? $t('company_memory') : $t('generating'),
                                icon: pdfMemory ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            }"
                            :disabled="!pdfMemory"
                            @click="generateMemory"
                        />
                    </template>
                    <template v-if="$root._route.params.type !== 'grouper' && $root._route.params.type !== 'exercise' && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.show_annexes !== undefined && getterQuestionnaireIndex.show_annexes">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_view_annexes',
                                text: $t('view_annexes')
                            }"
                            @click="showAnnexes"
                        />
                    </template>
                    <template v-if="$root._route.params.type !== 'grouper' && $root._route.params.type !== 'exercise' && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.joint_map !== undefined && getterQuestionnaireIndex.joint_map">
                        <router-link :to="{name: 'joint_map', params: {
                            id: $root._route.params.id,
                            type: $root._route.params.type,
                            extra_id: $root._route.params.extra_id
                        }}">
                            <GhButton
                                class="button-questionnaire-secondary"
                                :datainput="{
                                    id: 'btn_view_joint_map',
                                    text: $t('view_joint_map')
                                }"
                            />
                        </router-link>
                    </template>
                </template>
                <GhButton
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_view_global_map',
                        text: $t('view_global_map')
                    }"
                    :disabled="$root._route.params.type === 'grouper' || $root._route.params.type === 'exercise'"
                    @click="openMap()"
                />
                <template v-if="$route.name === 'preview_grouper' || $route.name === 'initial_itinerary_window'">
                    <GhAction
                        style="margin-left: 10px;"
                        class="color-light-blue T-subtitle-second underline-light-blue"
                        :dataction="{
                            id: 'close_eval',
                            text: '',
                            class: 'T-subtitle-colored',
                            icon: require('@/assets/gh_new_dessign/out.svg'),
                        }"
                        @click="$route.name === 'preview_grouper' ? Global.windowClose() : $route.name === 'initial_itinerary_window' ? router.push({name: activeItem === 'todo' ? 'todo' : 'my_itineraries'}) : ''"
                    />
                </template>
                <template v-else>
                    <div style="width: 50px"></div>
                </template>
            </template>
            <template v-slot:extraContent>
                <GhChartTable
                    style="margin-top: -15px;"
                    :extratable="{
                        id: 'global_map',
                        more: require('@/assets/questionnaire/arrow-down.svg'),
                        less: require('@/assets/questionnaire/arrow-up.svg'),
                        pointer: {
                            colors: [
                                '#C7C7C7',
                                '#F58449',
                                '#005392',
                                '#3DA106'
                            ],
                            title: [
                                'for_start',
                                'in_progress',
                                'finish'
                            ]
                        },
                    }"
                    :header="headerChart"
                    :data="getterQuestionnaireGlobalMap.data"
                    :footer="[]"
                    :type="getterQuestionnaireGlobalMap.type"
                    :evaluator="getterQuestionnaireGlobalMap.is_evaluator"
                    :states="statesChart"
                    @advice="''"
                    @columnAdvice="columnAdvice"
                    @columnPunctuation="columnPunctuation"
                    ref="RefGlobalMap"
                >
                    <template v-slot:advice_actions="{itemProp}">
                        <template v-if="getterQuestionnaireGlobalMap.type !== 3 && ((itemProp.hard_points !== undefined && itemProp.hard_points !== '') || (itemProp.improvement_opportunities !== undefined && itemProp.improvement_opportunities !== '') || (itemProp.good_practices !== undefined && itemProp.good_practices !== ''))">
                            <GhAction
                                style="margin-left: 10px;"
                                class="color-light-blue T-subtitle-second underline-light-blue"
                                :dataction="{
                                    id: 'see_advice_' + itemProp.id,
                                    text: $t('see'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/view2.svg'),
                                }"
                                @click="showAdvice(itemProp)"
                            />
                        </template>
                    </template>
                    <template v-slot:punctuation_actions="{itemProp}">
                        <template v-if="itemProp.detail">
                            <GhAction
                                style="margin-left: 10px; margin-top: 1px;"
                                class="color-light-blue T-subtitle-second underline-light-blue"
                                :dataction="{
                                    id: 'detail_' + itemProp.id,
                                    text: $t('detail'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/doc.svg'),
                                }"
                                @click="showDetail(itemProp)"
                            />
                        </template>
                    </template>
                    <template v-slot:header_actions>
<!--                        <div style="width: 100%;">-->
<!--                            <div style="margin-left: 10px; margin-right: 10px; float: right; margin-top: 10px; margin-bottom: 5px;">-->
<!--                                <GhButton-->
<!--                                    class="button-questionnaire-secondary"-->
<!--                                    :datainput="{-->
<!--                                        id: 'btn_generate_pdf',-->
<!--                                        text: pdfGenerate ? $t('generate_pdf') : $t('generating'),-->
<!--                                        icon: pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')-->
<!--                                    }"-->
<!--                                    :disabled="!pdfGenerate"-->
<!--                                    @click="generatePDF()"-->
<!--                                />-->
<!--                            </div>-->
<!--                        </div>-->
                    </template>
                </GhChartTable>
            </template>
            <template v-slot:bodyHeaderActions>
                <template v-if="!getterQuestionnaireBodyContent.evaluator">
                    <template v-if="getterQuestionnaireBodyContent.actions.know_more">
                        <GhAction
                            class="color-light-blue T-subtitle-second underline-light-blue"
                            :dataction="{
                                id: 'action_know_more',
                                text: $t('know_more'),
                                icon: require('@/assets/questionnaire/hat.svg')
                            }"
                            @click="getKnowMore()"
                        />
                    </template>
                    <template v-if="getterQuestionnaireBodyContent.actions.information">
                        <div @click="getInformation()" >
                            <GhCECustomTitle :content="getterQuestionnaireInformation.information !== undefined ? getterQuestionnaireInformation.information : ''">
                                <GhAction
                                    class="color-light-blue T-subtitle-second underline-light-blue"
                                    :dataction="{
                                        id: 'action_information',
                                        text: $t('information'),
                                        icon: require('@/assets/questionnaire/information.svg'),
                                    }"
                                />
                            </GhCECustomTitle>
                        </div>
                    </template>
                </template>
            </template>
            <template v-slot:answerLegend>
                <div @click="getLegend()" style="display: flex; flex-direction: row-reverse;">
                    <GhCECustomTitle :content="getterQuestionnaireInformation.legend !== undefined ? getterQuestionnaireInformation.legend : ''">
                        <GhAction
                            class="color-light-blue T-subtitle-second underline-light-blue"
                            style="padding-right: 5px; margin-right: auto;"
                            :dataction="{
                                id: 'action_legend',
                                text: $t('legend'),
                                icon: require('@/assets/questionnaire/information.svg')
                            }"
                        />
                    </GhCECustomTitle>
                </div>
            </template>
            <template v-slot:buttonEvaluator>
                <template v-if="!readOnly || readOnly === 'false'">
                    <template v-if="getterQuestionnaireBodyContent !== undefined && getterQuestionnaireBodyContent.actions !== undefined && getterQuestionnaireBodyContent.actions.rate">
                        <div class="no-apply-pallete">
                            <GhButton
                                :datainput="{
                                    id: 'btn_rate',
                                    text: $t('rate'),
                                    class: 'T19 container-md_button_content button-questionnaire-primary',
                                }"
                                @click="setRate()"
                            />
                        </div>
                    </template>
                    <template v-if="getterQuestionnaireBodyContent !== undefined && getterQuestionnaireBodyContent.actions !== undefined && getterQuestionnaireBodyContent.actions.evaluate">
                        <div class="no-apply-pallete">
                            <GhButton
                                :datainput="{
                                    id: 'btn_evaluate',
                                    text: $t('evaluate'),
                                    class: 'T19 container-md_button_content button-questionnaire-primary',
                                }"
                                @click="setEvaluate()"
                            />
                        </div>
                    </template>
                </template>
            </template>
        </GhCEQuestionnaireConstructor>

        <component :is="popups.learning" :title="titlePopupLearning"/>
        <component :is="popups.evaluate"/>
        <component :is="popups.advice"/>
        <component v-bind:is="popups.popup_weighting" :refresher="refreshWeighting" :dataWeighting="dataWeighting" :maxlength="maxlength" :show="true"/>
    </div>
</template>

<script>
    import GhCEQuestionnaireConstructor from "@/components/GhCEQuestionnaireConstructor.vue";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhCECustomTitle from "@/components/GhCETooltip.vue";
    import GhChartTable from "fe-gh-chart-table-lib";
    import GhChartRadar from "fe-gh-chart-radar-lib";

    import {mapGetters} from "vuex";

    import popup_learning from "@/view/shared/popup/learning.vue";
    import popup_evaluate from "@/view/shared/popup/evaluate.vue";
    import popup_advice from "@/view/shared/popup/advice.vue";
    import popup_weighting from '@/view/shared/popup/popup_weighting.vue';


    export default {
        name: 'body_questionnaire',
        components: {
            GhCEQuestionnaireConstructor,
            GhButton,
            GhAction,
            GhCECustomTitle,
            GhChartTable,
            GhChartRadar
        },
        computed: {
            ...mapGetters([
                'getterQuestionnaireIndex',
                'getterQuestionnaireBodyContent',
                'getterQuestionnaireInformation',
                'getterQuestionnaireMaterial',
                'getterNextQuestionnaire',
                'getterQuestionnaireGlobalMap',
                'getterQuestionnaireIndexFeed',
                'getterFirstQuestionary',
                'getterFinishedQuestionnaireInitial',
                'getterChartResult',
                'getterWeightingElementAgrupator',
                'getterAllowImpartitionFinish'
            ])
        },
        data(){
            return {
                activeItem: localStorage.getItem('TabItem'),
                haveData: false,
                information: '',
                titlePopupLearning: '',
                pdfGenerate: true,
                pdfMemory: true,
                headerChart: [
                    {text: '', field: 'title', dotted: false, style: 'max-width: 50px; width: 25%;', show_drag: true},
                    {text: '', field: 'chart', chart: true, ruler: true, value_ruler: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5}, style: 'max-width: 50px; width: 30%;'},
                    {text: '', field: 'advice', advice: true, style: 'min-width: 50px; width: 1%;'}
                ],
                statesChart: {
                    0: 'to_do',
                    1: 'unfinished',
                    2: 'completed',
                },
                actualIndex: undefined,
                popups: {
                    learning: popup_learning,
                    evaluate: popup_evaluate,
                    advice: popup_advice,
                    popup_weighting: popup_weighting
                },
                readOnly: false,
                chartRadar: [],
                refreshChart: 0,
                refreshView: 0,
                refreshWeighting: 0,
                dataWeighting: [],
                maxlength: 0
            }
        },
        async beforeDestroy(){
            this.$parent.actualLocation = '';
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.$store.dispatch('cleanQuestionnaireLoads', '', {root: true});

                await this.$store.dispatch('getQuestionnaireIndex', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                await this.$store.dispatch('getQuestionnaireBody', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                if(this.getterQuestionnaireBodyContent.impartition){
                    await this.$store.dispatch('getAllowImpartitionFinish', {
                        grouper_id: this.$root._route.params.id
                    }, {root: true});
                }

                if(this.$root._route.params.type === 'exercise') {
                    await this.$store.dispatch('getMaterialFromIndex', {
                        id: this.$root._route.params.id,
                        type: this.$root._route.params.type,
                        extra_id: this.$root._route.params.extra_id
                    }, {root: true});
                }

                if(this.$root._route.params.read_only !== undefined && this.$root._route.params.read_only !== null){
                    this.readOnly = this.$root._route.params.read_only;
                }

                if(this.getterQuestionnaireIndex.evaluator){
                    this.$parent.actualLocation = (this.getterQuestionnaireIndex.company !== undefined && this.getterQuestionnaireIndex.company !== '' ? this.getterQuestionnaireIndex.company.toUpperCase() + ' | ' : '') + this.getterQuestionnaireIndex.parent.name
                }

                this.haveData = true;
            }
        },
        watch: {
            'getterQuestionnaireBodyContent'(data){
                if(this.$refs.RefQuestionnaire !== undefined) {
                    this.$refs.RefQuestionnaire.defaultBody = data;
                }
            },
            'getterQuestionnaireGlobalMap'(data){
                if(data.puntuation !== undefined){
                    this.headerChart[1].value_ruler = data.puntuation;
                }

                if(data.type === 2 && !data.is_evaluator){
                    delete this.headerChart[2];
                    delete this.headerChart[1];
                    this.headerChart = this.headerChart.filter(n => n);
                }

                if(data.type === 2 && data.is_evaluator){
                    this.headerChart[2].field = 'punctuation'
                    // delete this.headerChart[2].advice;
                }

                if(data.type === 3){
                    this.headerChart[0].style = 'max-width: 50px; width: 80%;';
                    this.headerChart[1].style = 'max-width: 50px; width: 12%;';
                    this.headerChart[1].ruler = false;
                    this.headerChart[2] = {text: '', field: 'punctuation_final', click_punctuation: true, style: 'max-width: 50px; width: 50px; min-width: 50px;'};
                    this.headerChart[3] = {text: '', field: 'punctuation_max', style: 'max-width: 50px; width: 50px; min-width: 50px;'};
                    this.headerChart[4] = {text: '', field: 'punctuation_granted', style: 'max-width: 50px; width: 50px; min-width: 50px;'};
                    this.headerChart[5] = {text: '', field: 'advice', slot: true, advice: true, style: 'min-width: 250px; width: 1%;'};
                }
            },
            async 'getterFinishedQuestionnaireInitial'(){
                await this.$store.dispatch('getChartResult', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                }, {root: true});
            },
            'getterQuestionnaireIndex.parent.name'(data){
                this.headerChart[0].text = data;
            },
            'getterChartResult'(){
                this.chartRadar = this.getterChartResult.data;
                this.refreshChart++;
            }
        },
        methods: {
            async columnAdvice(item){
                this.Global.windowOpen(this.Global.openSecondWindow('evaluation_questionnaire_map',{
                    type: this.$root._route.params.type,
                    user_id: this.getterQuestionnaireGlobalMap.user_id,
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    complete: true,
                    extra_id: this.$root._route.params.extra_id
                }),'evaluation_questionnaire_' + this.$root._route.params.id + '_' + item.id + '_' + this.getterQuestionnaireGlobalMap.user_id);
            },
            columnPunctuation(item){
                this.Global.windowOpen(this.Global.openSecondWindow('rate_questionnaire_map',{
                    type: this.$root._route.params.type,
                    user_id: this.getterQuestionnaireGlobalMap.user_id,
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    complete: true,
                    extra_id: this.$root._route.params.extra_id
                }),'evaluation_questionnaire_' + this.$root._route.params.id + '_' + item.id + '_' + this.getterQuestionnaireGlobalMap.user_id);
            },
            async generateMemory(){
                this.pdfMemory = false;

                await this.$store.dispatch('getPDFMemory', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then((response) => {
                    if(response.data !== undefined && response.data !== null && response.data !== '') {
                        const link = document.createElement('a');
                        link.id = 'download_map_pdf';

                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        link.href = URL.createObjectURL(blob);
                        link.download = 'memoria.pdf'

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);
                    }
                    this.pdfMemory = true;
                });
            },
            async generatePDF(){
                this.pdfGenerate = false;

                await this.$store.dispatch('getPDFGlobalMap', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then((response) => {
                    if(response.data !== undefined && response.data !== null && response.data !== '') {
                        const link = document.createElement('a');
                        link.id = 'download_report_pdf';

                        var filename = '';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const match = contentDisposition.match(/filename="(.+)"/);
                            if (match && match[1]) {
                                filename = match[1];
                            }
                        }

                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);
                    }

                    this.pdfGenerate = true;
                });
            },
            async openMap(){
                await this.$store.dispatch('getQuestionnaireGlobalMap', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.$refs['RefQuestionnaire'].refreshMap = true;
                this.$refs['RefQuestionnaire'].showMap = true;
            },
            async handleCheckParent(){
                this.$store.dispatch('cleanQuestionnaireLoads','', {root: true});

                await this.$store.dispatch('getQuestionnaireIndex', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.handleSetQuestion();
            },
            resetNextQuestion(){
                this.$store.dispatch('resetNextQuestionnaire', '', {root: true});
            },
            async handleSetQuestion(itemIndex, keyIndex, flag) {
                let id = '';
                this.actualIndex = itemIndex;
                if(itemIndex === undefined){
                    itemIndex = {id: undefined};
                }

                await this.$store.dispatch('getQuestionnaireFromIndex', {
                    id: this.$root._route.params.id,
                    actual_id: itemIndex.id,
                    type: this.$root._route.params.type,
                    index: flag,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then(async (response) => {
                    id = response.id;

                    if(response.id === null){
                        id = response.extra_id;
                        id = id.substring(0, id.length - 1);
                    }

                    if(response.type === 7){
                        await this.$store.dispatch('getQuestionnaireIndexFeed', {
                            id: this.$root._route.params.id,
                            type: this.$root._route.params.type,
                            extra_id: this.$root._route.params.extra_id
                        }, {root: true});
                    }

                    this.getterQuestionnaireIndex.childrens.forEach((i, k) => {
                        if(response.id === i.id){
                            i['current'] = true;
                            this.$refs['RefQuestionnaire'].current.item = i;
                            this.$refs['RefQuestionnaire'].current.key = k;
                        }
                    });

                    this.$refs['RefQuestionnaire'].showInfoAgrupator = false;
                });

                if(!this.getterQuestionnaireBodyContent.evaluator) {
                    await this.$store.dispatch('getMaterialFromIndex', {
                        id: this.$root._route.params.id,
                        actual_id: id,
                        type: this.$root._route.params.type,
                        extra_id: this.$root._route.params.extra_id
                    }, {root: true});
                }
            },
            async handeGetMaterial(id, slug){
                this.titlePopupLearning = slug.replace('-', '_');

                await this.$store.dispatch('getMaterialBySlug', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    slug: slug,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.Global.openPopUp('popup_learning');
            },
            async handeSetSave(id){
                const formData = new FormData(document.getElementById('form_result_questionnaire'));

                await this.$store.dispatch('setQuestionnaireSave', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    formData: [...formData],
                    extra_id: this.$root._route.params.extra_id,
                    keyTranslate: {
                        'changes_have_been_saved_correctly': this.$t('changes_have_been_saved_correctly')
                    }
                }, {root: true});
            },
            async handeSetFinish(id, is_autoevaluation, is_proposed, validator){
                const formData = new FormData(document.getElementById('form_result_questionnaire'));
                await this.$store.dispatch('setQuestionnaireFinish', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    formData: [...formData],
                    validator: validator,
                    extra_id: this.$root._route.params.extra_id,
                    only_next: this.getterQuestionnaireIndex.evaluator || this.getterQuestionnaireIndex.validator && this.getterQuestionnaireBodyContent.disabled
                }, {root: true});
            },
            async getInformation(){
                await this.$store.dispatch('getQuestionnnaireInformation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});
            },
            async getLegend(){
                await this.$store.dispatch('getQuestionnnaireLegend', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});
            },
            async getKnowMore(){
                this.titlePopupLearning = 'know_more';

                await this.$store.dispatch('getQuestionnnaireKnowMore', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.Global.openPopUp('popup_learning');
            },
            async setFinishAllQuestionnaire(){
                await this.$store.dispatch('setFinishAllQuestionnaire', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra: this.$route.path.includes('realize') ? 'realize' : '',
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});
            },
            async setEvaluate(){
                this.Global.windowOpen(this.Global.openSecondWindow('evaluation_questionnaire',{
                    id: this.$root._route.params.id,
                    actual_id: this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }),'evaluation_questionnaire_' + this.$root._route.params.id + '_' + this.getterQuestionnaireBodyContent.id);
            },
            async setRate(){
                this.Global.windowOpen(this.Global.openSecondWindow('rate_questionnaire',{
                    id: this.$root._route.params.id,
                    actual_id: this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }),'rate_questionnaire_' + this.$root._route.params.id + '_' + this.getterQuestionnaireBodyContent.id);
            },
            async showAdvice(item){
                await this.$store.dispatch('getAdvice', {
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.Global.openPopUp('popup_advice');
            },
            async showDetail(item){
                await this.$store.dispatch('getStartRate', {
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.Global.openPopUp('popup_evaluate');
            },
            showHideChart(k, show){
                this.chartRadar = [];
                this.getterChartResult.data.forEach((i, key) => {
                    if(key !== k && this.getterChartResult.legend[key].show || key === k && !show){
                        this.chartRadar.push(i);
                    }else{
                        var param = new Array();
                        i.forEach((item) => {
                            param.push({'axis': item.axis, 'value': ''})
                        })
                        this.chartRadar.push(param);
                    }

                    if(key === k){
                        this.getterChartResult.legend[k].show = !this.getterChartResult.legend[k].show;
                    }
                });
                this.refreshChart++;
            },
            async showAnnexes(){
                this.Global.windowOpen(this.Global.openSecondWindow('evaluation_annexes',{
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }),'evaluation_annexes_' + this.$root._route.params.id);
            },
            async getPonderation(){
                var id_company = '';

                if(this.$root._route.params.extra_id !== null && this.$root._route.params.extra_id !== 0) {
                    id_company = this.$root._route.params.extra_id;
                }else{
                    await this.$store.dispatch('getIdCompany', '', {root: true}).then(reponse => {
                        id_company = reponse;
                    });
                }

                await this.$store.dispatch('getWeightingByAgrupatorAndCompany', {
                    id: id_company,
                    agrupator_id: this.$root._route.params.id,
                    from: 'program',
                    title: this.getterQuestionnaireIndex.parent.name
                }, {root: true});

                this.refreshWeighting++;
                this.dataWeighting = this.getterWeightingElementAgrupator;
                var maxlength = 0;
                Object.keys(this.getterWeightingElementAgrupator).forEach((i) => {
                    if(this.getterWeightingElementAgrupator[i].value !== undefined) {
                        maxlength += this.getterWeightingElementAgrupator[i].value;
                    }
                });
                this.maxlength = maxlength;

                this.Global.openPopUp('popup_weighting');
            }
        }
    }
</script>

<style>
    [id^="see_advice_"] > img{
        margin-top: 1px !important;
    }

    #close_eval > img {
        margin-top: 4px;
        width: 20px;
    }

    #close_eval {
        margin-right: unset;
    }
</style>