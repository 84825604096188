/**
 * Author: Sunil Shrestha
 * Date: July 26, 2023
 * Version: 2
 */
import { API_URL } from "@/common/config";
import axios from "axios";
import store from "@/store/index";
import router from "@/routes/router";

// Default values for token name
const defaultTokenName = 'token';
// const defaultUserTab  = '/todo';
export const AuthService = {

        checkLocalStorageAndCookie(token_name = defaultTokenName, cookie_name = '', callback_url = '') {
            const _token = this.getToken(token_name)
            const _cookie = this.getCookie(cookie_name);

            if ((!_token || !_cookie) && callback_url) {
                window.location.href = callback_url;
            }
        },
        getCookie(name) {
            const value = "; " + document.cookie;
            const parts = value.split("; " + name + "=");
            if (parts.length === 2) {
                return parts.pop().split(";").shift();
            }
            return null;
        },
        getToken(name) {
            const token = localStorage.getItem(name);
            return token ? token : null;
        },
        getAuthType: async function () {

            localStorage.clear();
            let endpoint = API_URL + 'auth/parameters' ;
            const response = await axios.get(endpoint);
            const authType = response.data; // Modify this based on your API response
            return authType;

        },
        getLogoutUrl: async function () {
            let endpoint = API_URL + 'auth/sso-config' ;
            const response = await axios.get(endpoint);
            const authType = response.data; // Modify this based on your API response
            return authType;

        },
        doAuthSSO:  async function() {
            const apiEndpoint = API_URL + "auth/login"; // Replace this with the actual API endpoint URL
            let data = {}; // Add your request data here
            const response = await axios.post(apiEndpoint, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            localStorage.clear();
            store.commit("LOAD_ERROR", '');
            await store.dispatch('setUserAuthData', response.data.data) ;
            await store.dispatch('setTokenExpiration', response.data.data) ;
            await store.dispatch('getTabsWithPerm', response.data.data) ;
            await store.dispatch('getAuthRedirect');
            await store.dispatch('resetAppTabs');
            await store.dispatch('getEnvironmentStyles');
            await this.redirectUserAfterAuth(response.data.data);
        },
        redirectUserAfterAuth: async function(data){
            if(data.questionary_done === 1){
                store.commit('loadNameCompany', '');
                store.commit('loadFirstQuestionary', false);

                var id_redirect = '';
                if(store.state.Login.auth_redirect === 'recommended_programs'){
                    id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id']

                    localStorage.setItem('TabItem','my_itineraries');
                    localStorage.setItem('item','my_itineraries');
                    localStorage.setItem('RedirectPerm','programs');
                    localStorage.setItem('id','C-' + id_redirect);
                    localStorage.setItem('my_itinerary_tab_','2my_itinerary_tab_');
                    localStorage.setItem('my_itinerary_tab__selected','{"id":2,"value":"recomended_programs","call":"recomended_programs"}');
                    store.state.Tabs.TabActive = 'programs';
                    router.push({path: '/programs'});
                }else if(store.state.Login.auth_redirect === 'business'){
                    id_redirect = router.options.GhTabs.find(n => n.value === 'company')['id'];

                    localStorage.setItem('TabItem','company');
                    localStorage.setItem('item','company');
                    localStorage.setItem('RedirectPerm','business');
                    localStorage.setItem('id','C-' + id_redirect);
                    store.state.Tabs.TabActive = 'company';
                    router.push({path: '/business'});
                }else {
                    router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config'});
                }
            }else{
                store.dispatch('setQuestionnaireImpartition', {
                    impartition: false
                });
                await axios.get(
                    API_URL + "initial/id"
                ).then(
                    response_2 => {
                        store.commit('loadNameCompany', response_2.data.data.company);
                        store.commit('loadFirstQuestionary', true);
                        router.push({name: 'initial_itinerary',params:{id: response_2.data.data.id, type: 'initial'}});
                        store.dispatch('viewInitial',true)
                    }
                )
            }

            location.reload();
        },
        isAuthenticated: function () {
            return !!localStorage.getItem(defaultTokenName);
        },
        getAccessToken: function () {
            return localStorage.getItem(defaultTokenName);
        },
        setAuthType: function (auth_type) {
            store.commit("setAppAuthType", auth_type);
        },
        verifyToken: async function () {
            const token = this.getAccessToken();
            if (!token) {
                throw new Error("Token not found in local storage.");
            }
            const apiEndpoint = API_URL + "auth/verify";
            try {
                const response = await axios.post(apiEndpoint, { token });
                return response.status === 200;
            } catch (error) {
                console.error("Error verifying token:", error);
                return false;
            }
        },
};

export default AuthService;
