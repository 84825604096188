var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
            id: 'assigned_users'
        },"header":_vm.header,"data":_vm.getterAssignedUsers},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'view_assign_' + itemProp.id,
                    text: _vm.$t('view'),
                    icon: require('@/assets/gh_new_dessign/view.svg'),
                }},on:{"click":function($event){return _vm.openAssign(itemProp)}}})]}}],null,false,3523792131)}),_c(_vm.popup.assigned,{tag:"component",attrs:{"title":_vm.title,"data":_vm.getterAssignByUser}})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }