import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: [],
        flagForGetter: false,
        list_workflow_tasks: []
    },
    mutations: {
        loadFlows(state,payload){
            state.records_data = payload
        },
        loadFlowsData(state,payload){
            state.data = payload.workflow !== undefined ? payload.workflow : [];
            state.list_workflow_tasks = payload.tasks !== undefined ? payload.tasks : [];
            state.flagForGetter = !state.flagForGetter;
        },
        async loadResetTasksGroups(state){
            state.flagForGetter = !state.flagForGetter;
            state.list_workflow_tasks = [];
        },
        async loadWorkflowTasks(state, payload){
            state.flagForGetter  = !state.flagForGetter;

            if(state.list_workflow_tasks.length !== 0){
                if(state.list_workflow_tasks.find(i => i.id === payload.id) === undefined){
                    await state.list_workflow_tasks.push(payload);
                }else{
                    await state.list_workflow_tasks.forEach((item, key) => {
                        if(item.id === payload.id){
                            if(state.list_workflow_tasks[key].groups !== undefined){
                                payload['groups'] = state.list_workflow_tasks[key].groups;
                            }
                            state.list_workflow_tasks[key] = payload;
                        }
                    });
                }
            }else{
                await state.list_workflow_tasks.push(payload);
            }

            await state.list_workflow_tasks.sort(function(a, b) {
                return parseInt(a.order) - parseInt(b.order);
            });

            console.log(state.list_workflow_tasks);

        },
        async loadDeleteTasks(state, payload){
            await state.list_workflow_tasks.forEach((item, key) => {
                if(payload.find(i => i === item.id)){
                    delete state.list_workflow_tasks[key];
                }
            });

            state.list_workflow_tasks = state.list_workflow_tasks.filter(n => n);

            console.log(state.list_workflow_tasks);
        },
        async loadWorkflowGroups(state, payload){
            state.flagForGetter = !state.flagForGetter;
            if(state.list_workflow_tasks[payload.key_task].groups === undefined){
                state.list_workflow_tasks[payload.key_task]['groups'] = [];
            }
            var param = {
                id: payload.id,
                name: payload.name
            }

            if(state.list_workflow_tasks[payload.key_task].groups.length !== 0){
                if(state.list_workflow_tasks[payload.key_task].groups.find(i => i.id === payload.id) === undefined){
                    state.list_workflow_tasks[payload.key_task].groups = [];
                    state.list_workflow_tasks[payload.key_task].groups.push(param);
                }else{
                    await state.list_workflow_tasks[payload.key_task].groups.forEach((item, key) => {
                        if(item.id === payload.id){
                            state.list_workflow_tasks[payload.key_task].groups[key] = param;
                        }
                    });
                }
            }else{
                state.list_workflow_tasks[payload.key_task].groups = [];
                state.list_workflow_tasks[payload.key_task].groups.push(param);
            }
        },
        async loadDeleteGroups(state, payload){
            await state.list_workflow_tasks[payload.key_task].groups.forEach((item, key) => {
                if(payload.item.find(i => i === item.id)){
                    delete state.list_workflow_tasks[payload.key_task].groups[key];
                }
            });

            state.list_workflow_tasks[payload.key_task].groups = state.list_workflow_tasks[payload.key_task].groups.filter(n => n);

            state.flagForGetter = !state.flagForGetter;
        },
    },
    getters: {
        getWorkflowTasksGroups(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return state.list_workflow_tasks;
        }
    },
    actions: {
        async getFlows(state){
            await axios.get(
                API_URL + "support/workflow"
            ).then(
                response => {
                    state.commit('loadFlows', response.data.data);
                }
            )
        },
        async setWorkflowTasks(state, item){
            await state.commit('loadWorkflowTasks', item);
        },
        async deleteTasksDetailsById(state, item){
            await state.commit('loadDeleteTasks', item.item);
        },
        async setWorkflowGroups(state, item){
            await state.commit('loadWorkflowGroups', item);
        },
        async deleteWorkflowGroups(state, item){
            await state.commit('loadDeleteGroups', item);
        },
        async resetTasksGroups(state){
            await state.commit('loadResetTasksGroups');
        },
        async refreshTasksGroups(state){
            await state.commit('loadRefreshWorkflowTasksCounter');
        },
        async setFlowData(state, item){
            let requestLogin = item.id !== undefined ? API_URL + "support/workflow/" + item.id : API_URL + "support/workflow";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            if(state.state.list_workflow_tasks.length !== 0){
                state.state.list_workflow_tasks.forEach((task, keyTask) => {
                    formData.append('workflow[task][' + keyTask + '][task_id]', task.id);
                    formData.append('workflow[task][' + keyTask + '][order]', task.order);
                    if(state.state.list_workflow_tasks[keyTask].groups !== undefined && state.state.list_workflow_tasks[keyTask].groups.length !== 0){
                        state.state.list_workflow_tasks[keyTask].groups.forEach((group) => {
                            formData.append('workflow[task][' + keyTask + '][groups][]', group.id);
                        });
                    }
                });
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status === 200){
                        router.push({name: 'configuration'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFlowsData(state, items){
            if(items.id === undefined){
                state.commit("loadFlowsData", '');
            }else {
                let requestLogin = API_URL + "support/workflow/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadFlowsData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async removeFlow(state, item){
            let requestLogin = API_URL + "support/workflow/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getFlows');
                        await this.dispatch('getConfigSupportCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}
