var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhLocation',{staticStyle:{"margin-top":"-3px"},attrs:{"dataLocation":{showButton: false, secondWindow: true, showLoader:'OK', text: _vm.$t('company'), img: require('@/assets/gh_new_dessign/floating.svg')},"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'register',
                title: _vm.$t('company'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('div',[_c(_vm.edit,{tag:"component"})],1):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","margin-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                },"type":"submit"},on:{"click":_vm.modifyBusiness}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$t('back')
                }},on:{"click":_vm.changeState}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }