var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_format_impartation',
        title: _vm.$t('enable_grouper'),
        type: 'warning',
        style: 'width: 28%; min-width: 1000px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px","padding-bottom":"15px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('preparing_to_enable') + ' ' + _vm.info.code + ' ' + _vm.info.name)+" ")]),_c('div',{staticClass:"T15",staticStyle:{"padding-left":"20px"},domProps:{"innerHTML":_vm._s(_vm.info.description)}})]),_c('div',{staticClass:"gh-content-global"},[_c('form',{attrs:{"id":"form_popup_format_impartation"}},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label",class:'T15',staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('item') + ':'))]),_c('GhChecks',{ref:"RefItemFull",attrs:{"datalabel":{text:_vm.$t('full'),style:'',class:'',id:''},"datainput":{
                            required: true,
                            selected: false,
                            type: 'radio',
                            id:'',
                            value: 'full',
                            name: 'grouper[item]'
                        }},on:{"click":function($event){_vm.formatItem('full'); _vm.checkImpartation('full')}}}),_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('dependent'), style: '', class: '', id: ''},"datainput":{
                            required: true,
                            selected: true,
                            type: 'radio',
                            id: '',
                            value: 'dependent',
                            name: 'grouper[item]'
                        }},on:{"click":function($event){_vm.formatItem('dependent'); _vm.checkImpartation('dependent')}}})],1),(_vm.showImpartation)?_c('div',{staticClass:"gh-row-content no-min-width"},[_c('GhDataSelector',{ref:"RefImpartation",attrs:{"datablock":{
                            id: 'label_grouper_impartation',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_impartation') + ':',
                            text: _vm.$t('impartation') + '*',
                            label: _vm.$t('impartation'),
                            type: 'radio',
                            columns: 3,
                            style: '',
                            required: true
                        },"datainput":{
                            id: 'grouper_impartation',
                            name: 'grouper[impartation][]'
                        },"selected_input":[],"feed":_vm.getFeeds.feed_impartation},on:{"accept":function($event){return _vm.checkImpartation('RefImpartation')}}})],1):_vm._e(),(_vm.show.checks !== undefined && _vm.show.checks.length !== 0)?_c('div',{key:'IMPARTITIONS' + _vm.refreshImpartitions,staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_vm._l((_vm.show.checks),function(checks,keyChecks){return [_c('GhChecks',{key:'CHECKS' + keyChecks,attrs:{"datalabel":{text: _vm.$t(checks),style:'',class:'',id:''},"datainput":{
                                required: false,
                                selected: _vm.show.checked.includes(keyChecks),
                                type: 'checkbox',
                                id: '',
                                value: keyChecks,
                                name: 'grouper[checks][]'
                            }}})]})],2):_vm._e(),(_vm.show.template_report)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type_template') + '*'))]),_c('GhChecks',{ref:"RefDefaultTemplate",attrs:{"datalabel":{text: '', style:'', class:'', id:''},"datainput":{
                            required: true,
                            selected: false,
                            type: 'checkbox',
                            id:'',
                            value: 1,
                            name: 'grouper[type_template][]',
                            label_required: _vm.$t('type_template') + '*'
                        }},on:{"click":function($event){return _vm.setDefaultTemplate(1)}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('default_template')))]),_c('GhChecks',{ref:"RefMemoryTemplate",attrs:{"datalabel":{text: '', style:'', class:'', id:''},"datainput":{
                            required: true,
                            selected: false,
                            type: 'checkbox',
                            id:'',
                            value: 2,
                            name: 'grouper[type_template][]',
                            label_required: _vm.$t('type_template') + '*'
                        }},on:{"click":function($event){return _vm.setDefaultTemplate(2)}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('memory')))]),_c('GhChecks',{ref:"RefReportTemplate",attrs:{"datalabel":{text: '', style:'', class:'', id:''},"datainput":{
                            required: true,
                            selected: false,
                            type: 'checkbox',
                            id:'',
                            value: 3,
                            name: 'grouper[type_template][]',
                            label_required: _vm.$t('type_template') + '*'
                        }},on:{"click":function($event){return _vm.setDefaultTemplate(3)}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('report')))])],1):_vm._e(),(_vm.show.template_report && !_vm.defaultTemplate && _vm.memoryTemplate)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('template_memory') + '*'))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                            id: 'grouper_easy_upload_template_memory',
                            class: '',
                            style: 'flex-direction: row !important;',
                            name_hidden: 'grouper[attach][template_memory]',
                            id_hidden: 'grouper_attach_template_memory',
                            icon_attach: require('../../../../assets/gh_new_dessign/attach.svg'),
                            icon_trash: require('../../../../assets/gh_new_dessign/trash.svg'),
                        },"datalabel":{
                            id: 'label_grouper_easy_upload_final_template_memory',
                            style: ''
                        },"datainput":{
                            text: _vm.$t('attach_template_memory'),
                            label_required: _vm.$t('template_memory') + '*',
                            accept: '.tex',
                            show_image: false,
                            delete: true,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: '',
                            file_path: '',
                            file_download: '',
                            required: true
                        }}})],1):_vm._e(),(_vm.show.template_report && !_vm.defaultTemplate && _vm.reportTemplate)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('template_report') + '*'))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                            id: 'grouper_easy_upload_template_report',
                            class: '',
                            style: 'flex-direction: row !important;',
                            name_hidden: 'grouper[attach][template_report]',
                            id_hidden: 'grouper_attach_template_report',
                            icon_attach: require('../../../../assets/gh_new_dessign/attach.svg'),
                            icon_trash: require('../../../../assets/gh_new_dessign/trash.svg'),
                        },"datalabel":{
                            id: 'label_grouper_easy_upload_final_template_report',
                            style: ''
                        },"datainput":{
                            text: _vm.$t('attach_template_report'),
                            label_required: _vm.$t('template_report') + '*',
                            accept: '.tex',
                            show_image: false,
                            delete: true,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: '',
                            file_path: '',
                            file_download: '',
                            required: true
                        }}})],1):_vm._e(),(_vm.show.model_report)?_c('div',{staticClass:"gh-row-content"},[_c('GhAction',{staticStyle:{"margin-right":"12px"},attrs:{"dataction":{
                            id: 'download_model_report',
                            text: _vm.$t('download_model_report'),
                            icon: require('@/assets/gh_new_dessign/descargar.svg'),
                        }},on:{"click":function($event){return _vm.$store.dispatch('easyDownload', {url: _vm.url}, {root: true})}}}),_c('GhEasyUpload',{attrs:{"extrablock":{
                            id: 'grouper_easy_upload_report',
                            class: '',
                            style: 'flex-direction: row !important;',
                            name_hidden: 'grouper[attach][report]',
                            id_hidden: 'grouper_attach_report',
                            icon_attach: require('../../../../assets/gh_new_dessign/attach.svg'),
                            icon_trash: require('../../../../assets/gh_new_dessign/trash.svg'),
                        },"datalabel":{
                            id: 'label_grouper_easy_upload_report',
                            style: ''
                        },"datainput":{
                            text: _vm.$t('attach_model_report') + '*',
                            show_image: false,
                            delete: true,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: '',
                            file_path: '',
                            file_download: '',
                            required: true
                        }}})],1):_vm._e(),(_vm.show.value)?_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('price') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'grouper_price',
                            name: 'grouper[price]',
                            style: 'width: 80px',
                            value: '',
                            limitNumMax: 99999,
                            type: 'int'
                        }}}),_c('label',{staticClass:"T15_b",staticStyle:{"margin-left":"10px","margin-top":"2px"}},[_vm._v("€")])],1):_vm._e(),(_vm.show.basic_data)?_c('div',{staticClass:"gh-row-content no-min-width"},[_c('GhDataSelector',{ref:"RefActivity",attrs:{"datablock":{
                            id: 'label_grouper_activity',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_activity_sector') + ':',
                            text: _vm.$t('activity_sector') + '*',
                            label: _vm.$t('activity_sector'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            label_required: _vm.$t('activity_sector'),
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_num_employee',
                            name: 'grouper[extra_info][activity][]'
                        },"feed":_vm.getFeeds.feed_activity,"selected_input":[]}})],1):_vm._e(),(_vm.show.basic_data)?_c('div',{staticClass:"gh-row-content no-min-width"},[_c('GhDataSelector',{ref:"RefEmployee",attrs:{"datablock":{
                            id: 'num_employee',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_number_employees') + ':',
                            text: _vm.$t('number_employees') + '*',
                            label: _vm.$t('number_employees'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            label_required: _vm.$t('number_employees'),
                            style: 'margin-left: -5px;',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_num_employee',
                            name: 'grouper[extra_info][num_employee][]'
                        },"feed":_vm.getFeeds.feed_num_employers,"selected_input":[]}})],1):_vm._e(),(_vm.show.basic_data)?_c('div',{staticClass:"gh-row-content no-min-width"},[_c('GhDataSelector',{ref:"RefCountry",attrs:{"datablock":{
                            id: 'label_grouper_country',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_country') + ':',
                            text: _vm.$t('country') + '*',
                            label: _vm.$t('country'),
                            type: 'radio',
                            columns: 3,
                            style: '',
                            required: true
                        },"datainput":{
                            id: 'grouper_country',
                            name: 'grouper[extra_info][country][]'
                        },"selected_input":[],"feed":_vm.getFeeds.feed_country}})],1):_vm._e(),(_vm.show.map)?_c('div',{staticClass:"gh-row-content no-min-width",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text:_vm.$t('numeric_map'),style:'',class:'',id:''},"datainput":{
                            required: false,
                            selected: false,
                            type: 'checkbox',
                            id:'',
                            value: 1,
                            name: 'grouper[extra_info][map_numeric]'
                        }}})],1):_vm._e()])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_format_impartation_accept',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.saveGrouper('enable');}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_format_impartation_close',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_format_impartation');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }