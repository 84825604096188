var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text:'',style:'',class:'',id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('politic_privacity') + '*',type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][politic_privacity]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                id: 'action_politic_privacity',
                text: _vm.$t('politic_privacity')
            }},on:{"click":function($event){return _vm.downloadPDF('politic_privacity')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text: '',style:'',class:'',id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('terms_conditions') + '*', type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][terms_conditions]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                id: 'action_terms_conditions',
                text: _vm.$t('terms_conditions')
            }},on:{"click":function($event){return _vm.downloadPDF('terms_conditions')}}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text: '', style:'', class:'', id:''},"datainput":{required:true,selected:false, label_required: _vm.$t('legal_advise') + '*', type:'checkbox',id:'', value: 1, name:'request_collaboration[data_treatment][legal_advise]'}}}),_c('GhAction',{staticStyle:{"margin-left":"10px","margin-top":"-1px"},attrs:{"dataction":{
                id: 'action_legal_advise',
                text: _vm.$t('legal_advise')
            }},on:{"click":function($event){return _vm.downloadPDF('legal_advise')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }