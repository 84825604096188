<template>
    <GhCECard
        :key="'CARD' + keyCard"
        :block="{
            image: '',
            progressBar: true,
            colors: [
                '#0099CC',
                '#005392'
            ],
            colorsHover: [
                '#B7C0C5',
                '#B7C0C5'
            ]
        }"
        :data="itemCard"
    >
        <template v-slot:extraContent>
            <template v-if="showButton">
                <div  class="centered" v-if="itemCard.finished" style="margin-top: -10px;">
                    <label class="gh_text_field-label T15_b" style="font-size: 10px !important; margin-right: unset; color: #858585;">{{$t('finished_the') + ' ' + itemCard.finished_date}}</label>
                </div>
                <div class="centered" style="padding-top: 15px; padding-bottom: 15px" :style="itemCard.finished ? 'margin-top: -12px;' : ''">
                    <GhButton
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_assign_' + itemCard.id,
                            text: itemCard.finished ? $t('view_' + itemCard.document) : itemCard.assigned ? $t('assigned') : $t('assign'),
                            style: 'margin-right: 0px!important'
                        }"
                        @click="itemCard.finished ? downloadPDF(itemCard) : assign(itemCard, $event)"
                    />
                    <template v-if="itemCard.weighting && !itemCard.finished">
                        <GhButton
                            style="margin-left: 10px;"
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_weighting_' + itemCard.id,
                                text: $t('weighting'),
                                style: 'margin-right: 0px!important'
                            }"
                            @click="weighting(itemCard, $event)"
                        />
                    </template>
                    <template v-if="itemCard.btn !== undefined && itemCard.btn.import">
                        <GhButton
                            style="margin-left: 10px;"
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_import_' + itemCard.id,
                                text: $t('import'),
                                style: 'margin-right: 0px!important'
                            }"
                            @click="importMethod(itemCard, $event)"
                        />
                    </template>
                </div>
            </template>
        </template>
    </GhCECard>
</template>

<script>
    import GhCECard from "@/components/GhCECard.vue";
    import GhButton from "fe-gh-button-lib";
    import {mapGetters} from "vuex";

    export default {
        name: 'program_card',
        props: ['keyCard', 'itemCard', 'showButton'],
        components:{
            GhCECard,
            GhButton
        },
        computed: {
            ...mapGetters(['getterWeightingElementAgrupator'])
        },
        methods: {
            async assign(item, event){
                event.preventDefault();
                event.stopPropagation();

                var id_company = '';

                await this.$store.dispatch('getIdCompany', '', {root: true}).then(reponse => {
                    id_company = reponse;
                });

                await this.$store.dispatch('getTreeItinerary', {
                    id: item.id
                }, {root: true});

                await this.$store.dispatch('getPermissionsAssign', {
                    agrupator_id: item.id
                }, {root: true});

                await this.$store.dispatch('getAssignItineraryTodo', {
                    id: id_company,
                    from: 'program',
                    agrupator_id: item.id,
                    title: item.name
                }, {root: true});

                this.$parent.$parent.$parent.refreshAssign++;

                this.Global.openPopUp('popup_assign');
            },
            async weighting(item, event){
                event.preventDefault();
                event.stopPropagation();

                var id_company = '';

                await this.$store.dispatch('getIdCompany', '', {root: true}).then(reponse => {
                    id_company = reponse;
                });

                await this.$store.dispatch('getWeightingByAgrupatorAndCompany', {
                    id: id_company,
                    agrupator_id: item.id,
                    from: 'program',
                    title: item.name
                }, {root: true});

                this.$parent.$parent.$parent.refreshWeighting = Math.random();
                this.$parent.$parent.$parent.dataWeighting = this.getterWeightingElementAgrupator;
                var maxlength = 0;
                Object.keys(this.getterWeightingElementAgrupator).forEach((i) => {
                    if(this.getterWeightingElementAgrupator[i].value !== undefined) {
                        maxlength += this.getterWeightingElementAgrupator[i].value;
                    }
                });
                this.$parent.$parent.$parent.maxlength = maxlength;

                this.Global.openPopUp('popup_weighting');
            },
            async importMethod(item, event){
                event.preventDefault();
                event.stopPropagation();

                await this.$store.dispatch('getImportAgrupators', {
                    id: item.id
                }, {root: true});

                this.$parent.$parent.$parent.actualCard = item.id;
                this.$parent.$parent.$parent.refreshImport++;

                this.$nextTick(() => {
                    this.Global.openPopUp('agrupator_import_data_selector_popup');
                });
            },
            async downloadPDF(item){
                await this.$store.dispatch(item.document === 'memory' ? 'getPDFMemory' : 'getPDFWithCard', {
                    id: item.id,
                    type: item.document === 'memory' ? 'initial' : undefined
                }, {root: true}).then((response) => {
                    if(response.data !== undefined && response.data !== null && response.data !== '') {
                        const link = document.createElement('a');
                        link.id = 'download_report_pdf';

                        var filename = '';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const match = contentDisposition.match(/filename="(.+)"/);
                            if (match && match[1]) {
                                filename = match[1];
                            }
                        }

                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);
                    }
                });
            }
        }
    }
</script>